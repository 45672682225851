define('sportly/routes/profile/followers', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			var profile = this.modelFor("profile");

			this._super(transition);

			return profile.get("user").then(function (user) {
				return user.get("followers");
			}).then(function (followers) {
				return Ember['default'].RSVP.all(followers.mapBy("user"));
			}).then(function (followerUsers) {
				return Ember['default'].RSVP.all(followerUsers.mapBy("profile"));
			}).then(function () {
				return profile.get("user.following");
			}).then(function (following) {
				return Ember['default'].RSVP.all(following.mapBy("followed"));
			}).then(function (followedUsers) {
				return Ember['default'].RSVP.all(followedUsers.mapBy("profile"));
			});
		},

		model: function model() {
			var profile = this.modelFor("profile"),
			    followerUsers = profile.get("user.followers").mapBy("user"),
			    followingUsers = profile.get("user.following").mapBy("followed");

			return followerUsers.filter(function (followerUser) {
				return !followingUsers.anyBy("id", followerUser.get("id"));
			});
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Followers");
				return true;
			}
		}

	});

});