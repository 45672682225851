define('sportly/controllers/profile-image', ['exports', 'ember', 'sportly/config/environment'], function (exports, Ember, Config) {

	'use strict';

	exports['default'] = Ember['default'].ObjectController.extend({

		api: {
			host: Config['default'].API.host,
			namespace: Config['default'].API.namespace
		},
		type: "photo",

		//Doesnt work. Em doesnt seem to update the button (and removed disabled attr) when this value changes
		saveDisabled: (function () {
			return this.get("content") === this.get("session.user.profile.photo");
		}).property("content", "session.user.profile.photo")
	});

});