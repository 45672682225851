define('sportly/routes/notifications', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.get("session.user.notifications");
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Notifications");
				return true;
			},
			willTransition: function willTransition() {
				var notifications = this.get("currentModel");
				notifications.invoke("set", "unread", false);
				Ember['default'].RSVP.all(notifications.invoke("save").toArray());
				return true;
			}
		}

	});

});