define('sportly/mixins/google-pageview', ['exports', 'ember', 'sportly/config/environment'], function (exports, Ember, ENV) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    pageviewToGA: (function () {
      if (Ember['default'].get(ENV['default'], "googleAnalytics.webPropertyId") != null) {
        var trackerType = Ember['default'].getWithDefault(ENV['default'], "googleAnalytics.tracker", "analytics.js");

        if (trackerType === "analytics.js") {
          var globalVariable = Ember['default'].getWithDefault(ENV['default'], "googleAnalytics.globalVariable", "ga");

          window[globalVariable]("send", "pageview", {
            page: this.get("url"),
            title: this.get("url")
          });
        } else if (trackerType === "ga.js") {
          window._gaq.push(["_trackPageview"]);
        }
      }
    }).on("didTransition")
  });

});