define('sportly/routes/football-game/member', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, AuthenticatedRouteMixin, Ember, RSVP) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		actions: {

			unban: function unban(gameMember) {
				gameMember.set("rsvpId", RSVP['default'].PENDING);
				gameMember.save();
			}
		}
	});

});