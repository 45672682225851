define('sportly/tests/acceptance/football-game/pending-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Football game - pending", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Pending member shown", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/1/pending");

        andThen(function () {
            assert.equal(find(".pending ul > li").length, 1, "1 pending member shown");
            assert.equal(find(".pending ul > li:first-child .name").text().trim(), "Zinedine Zidane", "Pending member's name shown");
        });
    });

});