define('sportly/components/x-profiles/header', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-profiles--header"],
        profile: null,

        isSessionUser: (function () {
            return this.get("session.user.id") === this.get("profile.user.id");
        }).property("session.user.id", "profile.user.id"),

        profileName: (function () {
            return this.get("profile.firstName") + " " + this.get("profile.lastName");
        }).property("profile.firstName", "profile.lastName"),

        isFriend: (function () {
            return this.get("friends").anyBy("id", this.get("session.user.id"));
        }).property("friends.@each.id", "session.user.id"),

        isFollowee: (function () {
            return this.get("profile.user.followers").anyBy("user.id", this.get("session.user.id"));
        }).property("profile.user.followers.@each.followed.id", "session.user.id"),

        isFollower: (function () {
            return this.get("profile.user.following").anyBy("followed.id", this.get("session.user.id"));
        }).property("profile.user.following.@each.followed.id", "session.user.id"),

        friends: (function () {
            var followerUsers = this.get("profile.user.followers").mapBy("user"),
                followingUsers = this.get("profile.user.following").mapBy("followed");
            return followingUsers.filter(function (followingUser) {
                return followerUsers.findBy("id", followingUser.get("id"));
            });
        }).property("profile.user.followers.@each.user.id", "profile.user.following.@each.followed.id"),

        canFollow: (function () {
            return !this.get("isFollowee") && !this.get("isFriend") && !this.get("isSessionUser");
        }).property("isFollowee", "isFriend", "isSessionUser"),

        actions: {
            follow: function follow() {
                this.sendAction("follow");
            }
        }

    });

});