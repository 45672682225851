define('sportly/controllers/group/invite-friends', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({
		needs: ["group"],
		invited: [],

		group: Em['default'].computed.alias("controllers.group.model"),
		isNoob: Em['default'].computed.alias("controllers.group.isNoob"),

		invitedMultiple: (function () {
			return this.get("invited.length") > 1;
		}).property("invited.length"),

		selected: []
	});

});