define('sportly/adapters/application', ['exports', 'ember-data', 'sportly/config/environment'], function (exports, DS, config) {

	'use strict';

	var ApplicationAdapter = DS['default'].RESTAdapter.extend({
		coalesceFindRequests: true,
		host: config['default'].API.host,
		namespace: config['default'].API.namespace
	});

	exports['default'] = ApplicationAdapter;

});