define('sportly/tests/acceptance/group/leave-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - leave", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can't access 'leave' if group is deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group/leave");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".leave-group").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Member can leave group with no fixtures", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-1/leave");

        andThen(function () {
            assert.notEqual(find(".leave-group form .btn-leave").length, 0, "Leave button is shown");
            click(".leave-group form .btn-leave");
        });

        andThen(function () {
            assert.notEqual(find(".x-groups--header .btn-join").length, 0, "Join button is shown");
            assert.equal(find(".x-groups--header .is-member").length, 0, "'Member' label is not shown");
        });
    });

    qunit.test("Member can leave group with fixtures", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-with-fixtures/leave");

        andThen(function () {
            assert.notEqual(find(".leave-group form .btn-leave").length, 0, "Leave button is shown");
            click(".leave-group form .btn-leave");
        });

        andThen(function () {
            assert.notEqual(find(".x-groups--header .btn-join").length, 0, "Join button is shown");
            assert.equal(find(".x-groups--header .is-member").length, 0, "'Member' label is not shown");
            click(".x-football-games--fixture-summary:first-of-type a");
        });

        andThen(function () {
            assert.equal(find(".football-game .rsvp-summary").length, 0, "RSVP summary should not be shown");
        });
    });

    qunit.test("Member can leave group with results", function (assert) {
        assert.expect(5);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-with-results/leave");

        andThen(function () {
            assert.notEqual(find(".leave-group form .btn-leave").length, 0, "Leave button is shown");
            click(".leave-group form .btn-leave");
        });

        andThen(function () {
            assert.notEqual(find(".x-groups--header .btn-join").length, 0, "Join button is shown");
            assert.equal(find(".x-groups--header .is-member").length, 0, "'Member' label is not shown");
            visit("/football/game/7/pending");
        });

        andThen(function () {
            assert.notEqual(find(".page.football-game").length, 0, "Football page container is shown");
            assert.equal(find(".header .title").text().trim(), "2 Pending", "Result still has 2 members");
        });
    });

    qunit.test("Can't leave group if last member", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-with-one-member/leave");

        andThen(function () {
            assert.equal(find(".leave-group form .btn-leave").length, 0, "Leave button is not shown");
        });
    });

});