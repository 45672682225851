define('sportly/routes/loading', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        activate: function activate() {
            this.controllerFor("application").set("isLoading", true);
        },

        deactivate: function deactivate() {
            this.controllerFor("application").set("isLoading", false);
        } });

});