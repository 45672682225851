define('sportly/routes/football-game/create', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/member-role', 'sportly/enums/football-game/rsvp'], function (exports, AuthenticatedRouteMixin, Ember, GameMemberRole, RSVP) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {

			return this.store.createRecord("footballGame", {
				homeTeam: this.store.createRecord("footballTeam"),
				awayTeam: this.store.createRecord("footballTeam"),
				venue: null,
				group: null,
				gameTypeId: 4,
				url: "",
				timestamp: 0,
				localTime: "18:00",
				localDate: moment().add(1, "d").format("YYYY-MM-DD"),
				cancellationMessage: "",
				gameLength: 3600,
				playerLimit: 0,
				isCancelled: false,
				isFinished: false
			});
		},

		actions: {
			create: function create() {

				var footballGame = this.get("currentModel"),
				    gameMember = null,
				    route = this;

				//As venue doesnt seem to clear by itself
				footballGame.get("errors").clear();

				if (Ember['default'].isEmpty(footballGame.get("localDate"))) {
					footballGame.get("errors").add("localDate", "Date is required");
				}
				if (Ember['default'].isEmpty(footballGame.get("venue"))) {
					footballGame.get("errors").add("venue", "Venue is required");
				}
				if (Ember['default'].isEmpty(footballGame.get("homeTeam.name"))) {
					footballGame.get("homeTeam.errors").add("name", "Home team name is required");
				}
				if (Ember['default'].isEmpty(footballGame.get("awayTeam.name"))) {
					footballGame.get("awayTeam.errors").add("name", "Away team name is required");
				}

				if (!footballGame.get("isValid") || !footballGame.get("homeTeam.isValid") || !footballGame.get("awayTeam.isValid")) {
					return;
				}

				Ember['default'].RSVP.all([footballGame.get("homeTeam").save(), footballGame.get("awayTeam").save()]).then(function () {
					return footballGame.save();
				}, function (e) {
					var errors = e.responseJSON.errors;
					for (var attribute in e.responseJSON.errors) {
						if (errors.hasOwnProperty(attribute)) {
							footballGame.get("errors").add(attribute, errors[attribute]);
						}
					}
				}).then(function () {
					return Ember['default'].RSVP.all([route.get("session.user.gameMembers"), footballGame.get("members")]);
				}).then(function (gameMemberLists) {
					gameMember = route.store.createRecord("gameMember", {
						user: route.get("session.user"),
						footballGame: footballGame,
						roleId: GameMemberRole['default'].ADMIN,
						rsvpId: RSVP['default'].PENDING
					});
					gameMemberLists[0].pushObject(gameMember);
					gameMemberLists[1].pushObject(gameMember);
					return gameMember.save();
				}).then(function () {
					route.transitionTo("footballGame.index", footballGame);
				});
			},

			cancel: function cancel() {
				this.transitionTo("dashboard");
			}
		},

		deactivate: function deactivate() {

			if (this.get("currentModel").get("isNew")) {
				this.get("currentModel").deleteRecord();
			}
		}
	});

});