define('sportly/controllers/group/create-football-game/add-venue', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		needs: ["map"],

		marker: null

	});

});