define('sportly/routes/football-game/about', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
        actions: {
            didTransition: function didTransition() {
                this.controllerFor("application").set("pageTitle", "About");
                return true;
            }

        }
    });

});