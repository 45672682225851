define('sportly/routes/football-game/invite-friends', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			this._super(transition);

			var user = this.get("session.user"),
			    footballGame = this.modelFor("footballGame");

			return user.get("followers").then(function (followers) {
				return Ember['default'].RSVP.all(followers.mapBy("user"));
			}).then(function () {
				return user.get("following");
			}).then(function (following) {
				return Ember['default'].RSVP.all(following.mapBy("followed"));
			}).then(function () {
				return footballGame.get("members");
			}).then(function (members) {
				return Ember['default'].RSVP.all(members.mapBy("user"));
			}).then(function () {
				return footballGame.get("pendingInvites");
			}).then(function (invites) {
				return Ember['default'].RSVP.all(invites.mapBy("receiver"));
			});
		},

		model: function model() {
			var user = this.get("session.user"),
			    followers = user.get("followers").mapBy("user"),
			    following = user.get("following").mapBy("followed"),
			    footballGameMembers = this.modelFor("footballGame").get("members"),
			    pendingInvites = this.modelFor("footballGame").get("pendingInvites");

			return following.filter(function (followingUser) {
				return followers.findBy("id", followingUser.get("id"));
			}).filter(function (friend) {
				if (footballGameMembers.findBy("user.id", friend.get("id"))) {
					return false;
				}
				if (pendingInvites.findBy("receiver.id", friend.get("id"))) {
					return false;
				}
				return true;
			});
		},

		setupController: function setupController(controller, friends) {
			controller.set("content", friends);
			controller.set("invited", []);

			if (!this.controllerFor("footballGame").get("iAmAMember") || !this.controllerFor("footballGame").get("isActive") || this.controllerFor("footballGame").get("isGroupGame")) {
				this.transitionTo("footballGame");
			}
		},

		actions: {

			selectUser: function selectUser(user) {
				this.get("controller.selected").pushObject(user);
			},

			deselectUser: function deselectUser(user) {
				this.get("controller.selected").removeObject(user);
			},

			inviteFriends: function inviteFriends() {

				var _this = this,
				    footballGame = this.modelFor("footballGame"),
				    user = this.get("session.user"),
				    friends = this.get("controller.selected");

				this.set("controller.invited", []);

				if (friends.get("length")) {

					Ember['default'].RSVP.all(friends.map(function (friend) {
						return _this.store.createRecord("footballGameInvite", {
							footballGame: footballGame,
							sender: user,
							receiver: friend
						});
					}).invoke("save")).then(function (footballGameInvites) {
						return Ember['default'].RSVP.all(footballGameInvites.map(function (footballGameInvite) {
							return _this.store.createRecord("notifications/footballGameInvite", {
								footballGameInvite: footballGameInvite
							});
						}).invoke("save"));
					}).then(function () {
						_this.get("controller").removeObjects(friends);
						_this.set("controller.invited", friends);
					});
				}
			},

			cancel: function cancel() {
				this.transitionTo("footballGame.index");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Invite friends");
				return true;
			}
		}
	});

});