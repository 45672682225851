define('sportly/tests/unit/controllers/football-game/reserves-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleFor("controller:football-game/reserves", {
    needs: ["controller:football-game"]
  });

  // Replace this with your real tests.
  ember_qunit.test("it exists", function (assert) {
    var controller = this.subject();
    assert.ok(controller);
  });

});