define('sportly/views/notification', ['exports', 'ember', 'sportly/models/notifications/group-invite', 'sportly/models/notifications/football-game-invite'], function (exports, Em, GroupInvite, FootballGameInvite) {

	'use strict';

	exports['default'] = Em['default'].View.extend({

		templateName: (function () {
			var model = this.get("content");
			if (model instanceof GroupInvite['default']) {
				return "notifications/groupInvite";
			}
			if (model instanceof FootballGameInvite['default']) {
				return "notifications/footballGameInvite";
			}
		}).property("content"),

		_templateChanged: (function () {
			this.rerender();
		}).observes("templateName")
	});

});