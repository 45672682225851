define('sportly/routes/find/groups/results', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model(params) {
			this.controllerFor("map").setProperties({
				latitude: params.latitude,
				longitude: params.longitude,
				zoom: params.zoom
			});
		},

		setupController: function setupController(controller) {
			if (Ember['default'].isEmpty(this.controllerFor("map").get("map"))) {
				return;
			}
			return this.store.find("group", {
				latitudeStart: this.controllerFor("map").get("latitudeStart"),
				latitudeFinish: this.controllerFor("map").get("latitudeFinish"),
				longitudeStart: this.controllerFor("map").get("longitudeStart"),
				longitudeFinish: this.controllerFor("map").get("longitudeFinish")
			}).then(function (groups) {
				controller.set("content", groups);
			});
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Find groups");
				return true;
			}

		}
	});

});