define('sportly/controllers/guest/sign-in', ['exports', 'simple-auth/mixins/login-controller-mixin', 'ember', 'ember-data'], function (exports, LoginControllerMixin, Ember, DS) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend(LoginControllerMixin['default'], {
		authenticator: "simple-auth-authenticator:token",
		errors: new DS['default'].Errors(),

		actions: {
			authenticate: function authenticate() {
				var _this = this;

				this.get("errors").clear();

				if (Ember['default'].isEmpty(this.get("identification")) || this.get("identification.length") < 5) {
					this.get("errors").add("email", "Email is required");
				}
				if (Ember['default'].isEmpty(this.get("password"))) {
					this.get("errors").add("password", "Password is required");
				}

				if (!this.get("errors.isEmpty")) {
					return;
				}

				this._super().then(null, function (e) {
					var errors = e.errors;
					for (var attribute in e.errors) {
						if (errors.hasOwnProperty(attribute)) {
							_this.get("errors").add(attribute, errors[attribute]);
						}
					}
				});
			}
		}
	});

});