define('sportly/router', ['exports', 'ember', 'sportly/config/environment', 'sportly/mixin/tracking_mixin', 'sportly/mixins/google-pageview'], function (exports, Ember, config, TrackingMixin, GooglePageview) {

  'use strict';

  var Router = Ember['default'].Router.extend(TrackingMixin['default'], GooglePageview['default'], {
    location: config['default'].locationType
  });

  Router.map(function () {
    this.resource("guest", {
      path: "/"
    }, function () {
      this.route("signIn", {
        path: "sign-in"
      });

      this.resource("guest.join", {
        path: "join"
      }, function () {
        this.route("invitation", {
          path: ":appInvite_id"
        });
      });

      this.route("terms");

      this.route("privacyPolicy", {
        path: "privacy-policy"
      });
    });

    this.resource("dashboard", {
      path: "dashboard"
    });

    this.resource("notifications");
    this.resource("help");

    this.resource("welcome", {
      path: "welcome"
    }, function () {
      this.route("groups");

      this.route("footballGames", {
        path: "football-games"
      });

      this.route("maps");
      this.route("finished");
      this.route("profile");
    });

    this.resource("accountSettings", {
      path: "account-settings"
    }, function () {
      this.route("general");

      this.route("editGeneral", {
        path: "general/edit"
      });

      this.route("emailAddress", {
        path: "email-address"
      });

      this.route("password");
    });

    this.resource("inviteFriends", {
      path: "invite-friends"
    });

    this.resource("profile", {
      path: ":profileUrl"
    }, function () {
      this.route("groups");
      this.route("fixtures");
      this.route("friends");
      this.route("following");
      this.route("followers");
    });

    this.resource("profileImage", {
      path: "profile-image"
    });

    this.resource("group", {
      path: "group/:groupSegment"
    }, function () {
      this.route("about");

      this.route("editAdmins", {
        path: "edit-admins"
      });

      this.route("leave");
      this.route("delete");
      this.route("fixtures");
      this.route("settings");

      this.route("inviteFriends", {
        path: "invite-friends"
      });

      this.route("inviteFriendsByEmail", {
        path: "invite-friends-by-email"
      });

      this.resource("group.createFootballGame", {
        path: "add-game"
      }, function () {
        this.route("chooseVenue", {
          path: "choose-venue"
        });

        this.route("addVenue", {
          path: "add-venue"
        });

        this.route("chooseDate", {
          path: "when"
        });
      });

      this.resource("group.edit", {
        path: "edit"
      }, function () {
        this.route("chooseVenue", {
          path: "choose-venue"
        });

        this.route("addVenue", {
          path: "add-venue"
        });
      });

      this.route("members");
      this.route("admins");
    });

    this.resource("group.create", {
      path: "group/create"
    }, function () {
      this.route("chooseVenue", {
        path: "choose-venue"
      });

      this.route("addVenue", {
        path: "add-venue"
      });
    });

    this.resource("footballGame", {
      path: "football/game/:footballGame_id"
    }, function () {
      this.route("inviteFriends", {
        path: "invite-friends"
      });

      this.route("about");
      this.route("cancel");
      this.route("playing");
      this.route("reserves");
      this.route("pending");
      this.route("declined");
      this.route("settings");

      this.resource("footballGame.edit", {
        path: "edit"
      }, function () {
        this.route("chooseVenue", {
          path: "choose-venue"
        });

        this.route("addVenue", {
          path: "add-venue"
        });
      });

      this.resource("footballGame.member", {
        path: "member/:gameMember_id"
      }, function () {
        this.route("ban", {
          path: "ban"
        });
      });
    });

    this.resource("footballGame.create", {
      path: "football/game/create"
    }, function () {
      this.route("chooseVenue", {
        path: "choose-venue"
      });

      this.route("addVenue", {
        path: "add-venue"
      });
    });

    this.resource("find.groups", {
      path: "find/groups"
    }, function () {
      this.route("results", {
        path: ":latitude/:longitude/:zoom"
      });
    });

    this.resource("find.footballGames", {
      path: "find/football-games"
    }, function () {
      this.route("results", {
        path: ":latitude/:longitude/:zoom"
      });
    });

    this.resource("search", function () {
      this.resource("searchPeople", {
        path: "people"
      }, function () {
        this.route("query", {
          path: ":query"
        });
      });
    });

    this.route("loading");
  });

  exports['default'] = Router;

});