define('sportly/components/x-groups/upcoming-fixtures', ['exports', 'ember', 'sportly/enums/group/member-role', 'sportly/enums/group/status'], function (exports, Ember, GroupMemberRole, GroupStatus) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-groups--upcoming-fixtures"],
        group: null,

        hasFixtures: Ember['default'].computed.notEmpty("fixtures"),
        isActive: Ember['default'].computed.equal("group.status", GroupStatus['default'].ACTIVE),
        showCreateGame: Ember['default'].computed.and("isAdmin", "isActive"),

        fixtures: (function () {
            var now = moment().format("X");
            return this.get("group.games").filter(function (game) {
                if (Ember['default'].isEmpty(game) || game.get("isCancelled")) {
                    return false;
                }
                return game.get("timestamp") > now;
            }).sort(function (a, b) {
                return a.get("timestamp") - b.get("timestamp");
            }).slice(0, 3);
        }).property("group.games.@each.timestamp", "group.games.@each.isCancelled"),

        isAdmin: (function () {
            return this.get("group.members").filterBy("roleId", GroupMemberRole['default'].ADMIN).any(function (member) {
                return member.get("user.id") === this.get("session.user.id");
            }, this);
        }).property("group.members.@each.user.id", "group.members.@each.roleId", "session.user.id") });

});