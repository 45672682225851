define('sportly/tests/acceptance/group/edit-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - edit", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can't access 'edit group' if group is deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group/edit");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".edit").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Member can't access 'edit group' page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-1/edit");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index page");
            assert.equal(find(".edit").length, 0, "Edit group container is not shown");
        });
    });

    qunit.test("Admin can access 'edit group' page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/edit");

        andThen(function () {
            assert.equal(currentPath(), "group.edit.index", "Should be on edit group page");
            assert.notEqual(find(".edit").length, 0, "Edit group container is shown");
        });
    });

    qunit.test("Admin can edit group", function (assert) {
        assert.expect(8);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/edit");

        andThen(function () {
            assert.equal(currentPath(), "group.edit.index", "Shows edit group page");

            fillIn("#name-input", "New name");
            fillIn("#description-input", "New description");
            click(".form-group.venue");
        });

        andThen(function () {
            assert.equal(currentPath(), "group.edit.chooseVenue", "Shows choose venue page");
            assert.notEqual(find(".choose-venue .results ul li.venue").length, 0, "Venue results shown");

            click(".choose-venue .results ul li.venue");
        });

        andThen(function () {
            assert.equal(currentPath(), "group.edit.index", "Back on edit group page");

            click(".edit .actions .btn-submit");
        });

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Back on group page");
            assert.equal(find(".x-groups--header .group-name").text().trim(), "New name", "Group name is now 'New name'");
            visit("/group/test-group-1/about");
        });

        andThen(function () {
            assert.equal(find(".about .venue .name").text().trim(), "Local venue", "Group venue is now 'Local venue'");
            assert.equal(find(".about .description").text().trim(), "New description", "Group description is now 'New description'");
        });
    });

});