define('sportly/routes/group/create', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.store.createRecord("group", {
				status: GroupStatus['default'].NOOB
			});
		},

		deactivate: function deactivate() {

			if (this.get("currentModel").get("isNew")) {
				this.get("currentModel").deleteRecord();
				//this.controllerFor('infoboxVenue').set('marker', null);
			}
		}
	});

});