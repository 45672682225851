define('sportly/helpers/profile-name', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.profileName = profileName;

    function profileName(profile) {
        if (Ember['default'].isEmpty(profile)) {
            return "";
        }
        return profile.get("firstName") + " " + profile.get("lastName");
    }

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(profileName, "firstName", "lastName");

});