define('sportly/tests/acceptance/football-game/declined-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Football game - declined", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Declined member shown", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/2/declined");

        andThen(function () {
            assert.equal(find(".declined ul > li").length, 1, "1 declined member shown");
            assert.equal(find(".declined ul > li:first-child .name").text().trim(), "Lionel Messi", "Declined member's name shown");
        });
    });

});