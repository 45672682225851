define('sportly/routes/football-game/settings', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			this._super(transition);

			var footballGame = this.modelFor("footballGame");

			return footballGame.get("creator").then(function () {
				if (!Ember['default'].isEmpty(footballGame.get("group.content"))) {
					return footballGame.get("group.members").then(function (groupMembers) {
						return Ember['default'].RSVP.all(groupMembers.mapBy("user"));
					});
				}
			});
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Settings");
				return true;
			}
		}

	});

});