define('sportly/controllers/group/leave', ['exports', 'sportly/controllers/group'], function (exports, Group) {

	'use strict';

	exports['default'] = Group['default'].extend({

		isLastMember: (function () {
			return this.get("members.length") === 1;
		}).property("members.length"),

		isLastAdmin: (function () {
			return this.get("isAdmin") && this.get("admins.length") === 1;
		}).property("isAdmin", "admins.length")
	});

});