define('sportly/routes/football-game/declined', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			this._super(transition);
			return this.modelFor("footballGame").get("members");
		},

		model: function model() {
			return this.modelFor("footballGame").get("members");
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Declined");
				return true;
			}

		}
	});

});