define('sportly/components/x-profiles/upcoming-fixtures', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-profiles--upcoming-fixtures"],
        profile: null,

        hasFixtures: Ember['default'].computed.notEmpty("fixtures"),
        games: Ember['default'].computed.mapBy("profile.user.gameMembers", "footballGame"),

        isSessionUser: (function () {
            return this.get("profile.user.id") === this.get("session.user.id");
        }).property("profile.user.id", "session.user.id"),

        fixtures: (function () {
            var now = moment().format("X");
            return this.get("games").filter(function (game) {
                if (Ember['default'].isEmpty(game) || game.get("isCancelled")) {
                    return false;
                }
                return game.get("timestamp") > now;
            }).sort(function (a, b) {
                return a.get("timestamp") - b.get("timestamp");
            }).slice(0, 3);
        }).property("games.@each.timestamp", "games.@each.isCancelled")
    });

});