define('sportly/models/game', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		url: DS['default'].attr("string"),
		timestamp: DS['default'].attr("number"),
		time: DS['default'].attr("string"),
		date: DS['default'].attr("string"),
		cancellationMessage: DS['default'].attr("string"),
		sportId: DS['default'].attr("number"),
		venue: DS['default'].belongsTo("venue"),
		//group: 					DS.belongsTo('group'),
		gameLength: DS['default'].attr("number"),
		playerLimit: DS['default'].attr("number"),
		isCancelled: DS['default'].attr("number"),
		isFinished: DS['default'].attr("number"),
		members: DS['default'].hasMany("game-member")
	});

});