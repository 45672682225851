define('sportly/components/x-football-games/header', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-football-games--header"],
        footballGame: null,

        canJoin: Ember['default'].computed.and("hasNoGroup", "isActive", "sessionIsNotMember"),

        hasNoGroup: Ember['default'].computed.empty("footballGame.group.id"),

        notCancelled: Ember['default'].computed.not("footballGame.isCancelled"),
        notFinished: Ember['default'].computed.not("footballGame.isFinished"),
        isActive: Ember['default'].computed.and("notCancelled", "notFinished"),
        sessionIsNotMember: Ember['default'].computed.not("sessionIsMember"),

        hasLimitedSpaces: Ember['default'].computed.gte("footballGame.playerLimit", 1),

        sessionIsMember: (function () {
            var userId = this.get("session.user.id");
            return this.get("footballGame.members").any(function (member) {
                return member.get("user.id") === userId;
            });
        }).property("footballGame.members.@each.user.id", "session.user.id"),

        actions: {

            attend: function attend() {
                this.sendAction("attend");
            },

            decline: function decline() {
                this.sendAction("decline");
            },

            changeRSVP: function changeRSVP() {
                this.sendAction("changeRSVP");
            },

            join: function join() {
                this.sendAction("join");
            }
        }
    });

});