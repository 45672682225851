define('sportly/tests/unit/helpers/format-time-test', ['sportly/helpers/format-time', 'qunit'], function (format_time, qunit) {

    'use strict';

    qunit.module("FormatTimeHelper");

    qunit.test("Parse and format a unix timestamp", function (assert) {
        var result = format_time.formatTime(2011971600, {
            hash: {
                parse: "X",
                format: "HH:mm dddd, Do MMMM"
            }
        });
        assert.ok(result === "18:00 Monday, 3rd October");
    });

});