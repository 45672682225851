define('sportly/controllers/dashboard', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		games: Em['default'].computed.mapBy("user.gameMembers", "footballGame"),

		fixtures: (function () {
			var now = moment().format("X");
			return this.get("games").filter(function (game) {
				if (Em['default'].isEmpty(game) || game.get("isCancelled")) {
					return false;
				}
				return game.get("timestamp") > now;
			}).sort(function (a, b) {
				return a.get("timestamp") - b.get("timestamp");
			}).slice(0, 3);
		}).property("games.@each.timestamp", "games.@each.isCancelled") });

});