define('sportly/tests/acceptance/guest-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Guest", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Ensures guest can sign in", function (assert) {
        assert.expect(1);
        visit("/sign-in");
        fillIn("#email-field", "zinedine.zidane@wearelion.com");
        fillIn("#password-field", "password");
        click(".x-guest--sign-in-form .btn-submit");

        andThen(function () {
            assert.notEqual(find(".x-global--nav").length, 0, "Global nav exists");
        });
    });

    qunit.test("Ensures that a signed in new user is onboarded", function (assert) {
        assert.expect(1);
        visit("/sign-in");
        fillIn("#email-field", "steven.gerrard@wearelion.com");
        fillIn("#password-field", "password");
        click(".x-guest--sign-in-form .btn-submit");

        andThen(function () {
            assert.notEqual(find(".x-welcome--step.introduction").length, 0, "Onboarding introduction step is shown");
        });
    });

    qunit.test("Ensures guest can join with invite", function (assert) {
        assert.expect(3);
        visit("/join/abcd1234");
        andThen(function () {
            assert.equal(find("#email-field").val(), "fernando.torres@wearelion.com", "Check that email is pre-populated");
            assert.equal(find(".sender .name").text(), "Zinedine Zidane", "Check that invite sender name is shown");

            fillIn("#first-name-field", "Fernando");
            fillIn("#last-name-field", "Torres");
            fillIn("#password-field", "password");
            click(".x-guest--join-form .btn-join");
        });

        andThen(function () {
            assert.notEqual(find(".x-global--nav").length, 0, "Global nav exists");
        });
    });

    qunit.test("Ensures that after joining a user is onboarded", function (assert) {
        assert.expect(3);
        visit("/join/abcd1234");
        andThen(function () {
            assert.equal(find("#email-field").val(), "fernando.torres@wearelion.com", "Check that email is pre-populated");
            assert.equal(find(".sender .name").text(), "Zinedine Zidane", "Check that invite sender name is shown");

            fillIn("#first-name-field", "Fernando");
            fillIn("#last-name-field", "Torres");
            fillIn("#password-field", "password");
            click(".x-guest--join-form .btn-join");
        });

        andThen(function () {
            assert.notEqual(find(".x-welcome--step.introduction").length, 0, "Onboarding introduction step is shown");
        });
    });

    qunit.test("Ensures that after joining a user can complete onboarding", function (assert) {
        assert.expect(2);
        visit("/join/abcd1234");
        andThen(function () {
            fillIn("#first-name-field", "Fernando");
            fillIn("#last-name-field", "Torres");
            fillIn("#password-field", "password");
            click(".x-guest--join-form .btn-join");
        });

        andThen(function () {
            assert.notEqual(find(".x-welcome--step.introduction").length, 0, "Onboarding introduction step is shown");
            visit("/welcome/groups");
        });

        andThen(function () {
            click(".btn-skip-wrapper a");
        });

        andThen(function () {
            assert.equal(find(".x-welcome--step.introduction").length, 0, "Onboarding introduction step is no longer shown");
        });
    });

});