define('sportly/tests/unit/components/x-groups/group-summary-test', ['ember-qunit', 'ember'], function (ember_qunit, Ember) {

    'use strict';

    ember_qunit.moduleForComponent("x-groups/group-summary", {
        needs: ["helper:str-t"]
    });

    ember_qunit.test("it renders", function (assert) {
        assert.expect(2);

        // creates the component instance
        var component = this.subject();
        assert.equal(component._state, "preRender");

        Ember['default'].I18n.translations = {
            en: {
                "fixture-count": {
                    zero: "No fixtures",
                    one: "1 fixture",
                    other: "{{count}} fixtures"
                }
            }
        };

        Ember['default'].I18n.locale = "en";

        component.set("group", Ember['default'].Object.create({
            id: 1,
            name: "Test group",
            games: [Ember['default'].Object.create({
                id: 1,
                isCancelled: false,
                timestamp: 0
            })]
        }));

        // appends the component to the page
        this.render();
        assert.equal(component._state, "inDOM");
    });

});