define('sportly/tests/unit/components/x-stories/status-update-story-test', ['ember-qunit'], function (ember_qunit) {

    'use strict';

    ember_qunit.moduleForComponent("x-stories/status-update-story", {
        needs: ["helper:time-ago", "helper:str-nl2br", "helper:profile-name"]
    });

    ember_qunit.test("it renders", function (assert) {
        assert.expect(2);

        // creates the component instance
        var component = this.subject();
        assert.equal(component._state, "preRender");

        // appends the component to the page
        this.render();
        assert.equal(component._state, "inDOM");
    });

});