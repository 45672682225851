define('sportly/models/football-team', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		name: DS['default'].attr("string"),
		members: DS['default'].hasMany("team-member", { async: true }),
		timestamp: DS['default'].attr("number")
		//games:		DS.hasMany('footballGame', {async: true})
	});

});