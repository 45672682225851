define('sportly/controllers/group/create-football-game', ['exports', 'sportly/controllers/game', 'sportly/enums/football-game/frequency'], function (exports, Game, FootballGameFrequency) {

	'use strict';

	exports['default'] = Game['default'].extend({
		startDate: "",
		finishDate: "",
		frequency: FootballGameFrequency['default'].SINGLE,

		multipleGames: (function () {
			return this.get("frequency") === FootballGameFrequency['default'].WEEKLY;
		}).property("frequency")

	});

});