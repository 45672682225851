define('sportly/routes/group/create-football-game', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/frequency', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, FootballGameFrequency, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.store.createRecord("footballGame", {
				homeTeam: this.store.createRecord("footballTeam"),
				awayTeam: this.store.createRecord("footballTeam"),
				venue: this.modelFor("group").get("venue"),
				group: this.modelFor("group"),
				gameTypeId: 4,
				url: "",
				timestamp: 0,
				localTime: "18:00",
				localDate: moment().add(1, "d").format("YYYY-MM-DD"),
				cancellationMessage: "",
				gameLength: 3600,
				playerLimit: 0,
				isCancelled: false,
				isFinished: false
			});
		},

		afterModel: function afterModel() {
			if (this.modelFor("group").get("status") === GroupStatus['default'].DELETED) {
				return this.transitionTo("group.index");
			}

			return this.modelFor("group").get("members");
		},

		setupController: function setupController(controller, model) {
			controller.set("model", model);
			controller.set("startDate", "");
			controller.set("finishDate", "");
			controller.set("frequency", FootballGameFrequency['default'].SINGLE);

			if (!this.controllerFor("group").get("isAdmin")) {
				this.transitionTo("group.index");
			}
		},

		deactivate: function deactivate() {
			if (this.get("currentModel.isNew")) {
				this.get("currentModel").deleteRecord();
			}
		}

	});

});