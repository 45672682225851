define('sportly/tests/unit/helpers/str-t-test', ['sportly/helpers/str-t', 'qunit'], function (str_t, qunit) {

  'use strict';

  qunit.module("StrTHelper");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    //var result = strT(42);
    //ok(result);
    assert.expect(0);
  });

});