define('sportly/models/story/profile-post', ['exports', 'ember-data', 'sportly/models/story'], function (exports, DS, Story) {

	'use strict';

	exports['default'] = Story['default'].extend({
		user: DS['default'].belongsTo("user", { async: true }),
		targetUser: DS['default'].belongsTo("user", { async: true }),
		post: DS['default'].belongsTo("post/profile", { async: true })
	});

});