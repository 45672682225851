define('sportly/controllers/football-game/member/header', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({
		needs: ["footballGame/member"],

		profile: Em['default'].computed.alias("controllers.footballGame/member.user.profile"),

		isPlaying: Em['default'].computed.alias("controllers.footballGame/member.isPlaying"),
		isReserve: Em['default'].computed.alias("controllers.footballGame/member.isReserve"),
		hasDeclined: Em['default'].computed.alias("controllers.footballGame/member.hasDeclined"),
		isBanned: Em['default'].computed.alias("controllers.footballGame/member.isBanned"),
		isPending: Em['default'].computed.alias("controllers.footballGame/member.isPending"),

		iCanUnBan: Em['default'].computed.alias("controllers.footballGame/member.iCanUnBan")

	});

});