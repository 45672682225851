define('sportly/controllers/group/create-football-game/choose-date', ['exports', 'ember', 'sportly/enums/football-game/frequency'], function (exports, Em, FootballGameFrequency) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({
		needs: ["group/create-football-game"],

		createFootballGame: Em['default'].computed.alias("controllers.group/create-football-game"),

		frequencyOptions: [{ value: FootballGameFrequency['default'].SINGLE, name: "Single game" }, { value: FootballGameFrequency['default'].WEEKLY, name: "Weekly game" }],

		errors: Em['default'].computed.alias("controllers.group/create-football-game.errors"),
		frequency: Em['default'].computed.alias("controllers.group/create-football-game.frequency"),
		multipleGames: Em['default'].computed.alias("controllers.group/create-football-game.multipleGames"),
		startDate: Em['default'].computed.alias("controllers.group/create-football-game.startDate"),
		finishDate: Em['default'].computed.alias("controllers.group/create-football-game.finishDate")

	});

});