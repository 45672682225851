define('sportly/routes/group', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/member-role', 'sportly/enums/group/member-role', 'sportly/enums/football-game/rsvp'], function (exports, AuthenticatedRouteMixin, Ember, GameMemberRole, GroupMemberRole, RSVP) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model(params) {
			return this.store.find("group", {
				url: params.groupSegment
			}).then(function (groups) {
				return groups.get("firstObject");
			});
		},

		afterModel: function afterModel(group) {
			return group.get("members").then(function (members) {
				return Ember['default'].RSVP.all(members.mapBy("user"));
			}).then(function () {
				return group.get("games");
			}).then(function () {
				return group.get("venue");
			}).then(function () {
				return group.get("pendingInvites");
			}).then(function (pendingInvites) {
				return Ember['default'].RSVP.all(pendingInvites.mapBy("receiver"));
			});
		},

		setupController: function setupController(controller, group) {
			controller.set("model", group);
			if (controller.get("isNoob") && controller.get("isAdmin")) {
				if (controller.get("games.length")) {
					this.transitionTo("group.inviteFriends", group);
				} else {
					this.transitionTo("group.createFootballGame", group);
				}
			}
			this.controllerFor("application").set("globalNavTheme", "theme-groups");
		},

		serialize: function serialize(group) {
			return { groupSegment: Ember['default'].get(group, "url") };
		},

		deactivate: function deactivate() {
			this._super();
			this.controllerFor("application").set("globalNavTheme", null);
		},

		actions: {

			join: function join() {

				var _this = this,
				    group = this.get("currentModel"),
				    user = this.get("session.user");

				this.get("controller.myPendingInvites").forEach(function (invite) {
					invite.set("status", 3);
					invite.save();
				});

				this.store.createRecord("groupMember", {
					user: user,
					group: group,
					roleId: GroupMemberRole['default'].MEMBER
				}).save().then(function () {
					return group.get("games").forEach(function (game) {
						_this.store.createRecord("gameMember", {
							user: user,
							footballGame: game,
							rsvpId: RSVP['default'].PENDING,
							roleId: GameMemberRole['default'].MEMBER,
							playing: false
						}).save();
					});
				}).then(function () {
					return _this.store.createRecord("story/group-join", {
						user: user,
						group: group
					}).save();
				}).then(function (story) {
					group.get("stories").pushObject(story);
				});
			},

			ignoreInvites: function ignoreInvites() {
				this.get("controller.myPendingInvites").forEach(function (invite) {
					invite.set("status", 2);
					invite.save();
				});
			}
		}

	});

});