define('sportly/components/x-stories/story', ['exports', 'ember', 'sportly/models/story/status-update', 'sportly/models/story/app-join', 'sportly/models/story/football-game-cancel', 'sportly/models/story/football-game-post', 'sportly/models/story/group-create', 'sportly/models/story/group-join', 'sportly/models/story/group-leave', 'sportly/models/story/group-post', 'sportly/models/story/user-follow', 'sportly/models/story/profile-image-change', 'sportly/models/story/profile-post'], function (exports, Ember, StatusUpdateStory, AppJoinStory, FootballGameCancelStory, FootballGamePostStory, GroupCreateStory, GroupJoinStory, GroupLeaveStory, GroupPostStory, UserFollowStory, ProfileImageChangeStory, ProfilePostStory) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-stories--story"],
        story: null,
        source: null,

        componentType: (function () {
            var story = this.get("story");
            if (story instanceof StatusUpdateStory['default']) {
                return "x-stories/status-update-story";
            }
            if (story instanceof AppJoinStory['default']) {
                return "x-stories/app-join-story";
            }
            if (story instanceof FootballGameCancelStory['default']) {
                return "x-stories/football-game-cancel-story";
            }
            if (story instanceof FootballGamePostStory['default']) {
                return "x-stories/football-game-post-story";
            }
            if (story instanceof GroupCreateStory['default']) {
                return "x-stories/group-create-story";
            }
            if (story instanceof GroupJoinStory['default']) {
                return "x-stories/group-join-story";
            }
            if (story instanceof GroupLeaveStory['default']) {
                return "x-stories/group-leave-story";
            }
            if (story instanceof GroupPostStory['default']) {
                return "x-stories/group-post-story";
            }
            if (story instanceof UserFollowStory['default']) {
                return "x-stories/user-follow-story";
            }
            if (story instanceof ProfileImageChangeStory['default']) {
                return "x-stories/profile-image-change-story";
            }
            if (story instanceof ProfilePostStory['default']) {
                return "x-stories/profile-post-story";
            }
        }).property("story")

    });

});