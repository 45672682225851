define('sportly/controllers/football-game/member', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, Em, RSVP) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({
		needs: ["footballGame"],

		isMe: (function () {
			return this.get("profile.user.id") === this.get("session.user.id");
		}).property("profile.user.id", "session.user.id"),
		isNotMe: Em['default'].computed.not("isMe"),
		isAdmin: Em['default'].computed.equal("roleId", RSVP['default'].ADMIN),

		canRSVP: Em['default'].computed.and("controllers.footballGame.isActive", "isNotBanned"),
		isPlaying: Em['default'].computed.equal("rsvpId", RSVP['default'].PLAYING),
		isReserve: Em['default'].computed.equal("rsvpId", RSVP['default'].RESERVE),
		isPending: Em['default'].computed.equal("rsvpId", RSVP['default'].PENDING),
		hasDeclined: Em['default'].computed.equal("rsvpId", RSVP['default'].DECLINED),
		isBanned: Em['default'].computed.equal("rsvpId", RSVP['default'].BANNED),
		isNotBanned: Em['default'].computed.not("isBanned"),
		isAttending: Em['default'].computed.or("isPlaying", "isReserve"),
		isNotAttending: Em['default'].computed.or("isPending", "hasDeclined"),

		iCanUnBan: Em['default'].computed.and("controllers.footballGame.iAmAdmin", "isBanned"),
		iCanBan: Em['default'].computed.and("controllers.footballGame.iAmAdmin", "isNotMe", "isNotBanned")

	});

});