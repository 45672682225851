define('sportly/routes/football-game', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/member-role', 'sportly/enums/football-game/rsvp'], function (exports, AuthenticatedRouteMixin, Ember, GameMemberRole, RSVP) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		afterModel: function afterModel(footballGame) {
			return Ember['default'].RSVP.all([footballGame.get("homeTeam"), footballGame.get("awayTeam"), footballGame.get("venue"), footballGame.get("creator")]).then(function () {
				return footballGame.get("members");
			}).then(function (gameMembers) {
				return Ember['default'].RSVP.all(gameMembers.mapBy("user"));
			}).then(function () {
				return footballGame.get("venue");
			}).then(function () {
				return footballGame.get("pendingInvites");
			}).then(function (pendingInvites) {
				return Ember['default'].RSVP.all(pendingInvites.mapBy("receiver"));
			}).then(function () {
				if (!Ember['default'].isEmpty(footballGame.get("group.content"))) {
					return footballGame.get("group").then(function (group) {
						return group.get("members");
					}).then(function (groupMembers) {
						return Ember['default'].RSVP.all(groupMembers.mapBy("user"));
					});
				}
			});
		},

		actions: {

			join: function join() {
				var footballGame = this.get("currentModel"),
				    user = this.get("session.user"),
				    controller = this.get("controller"),
				    route = this;

				Ember['default'].RSVP.all(controller.get("myPendingInvites").map(function (invite) {
					invite.set("status", 3);
				}).invoke("save")).then(function () {
					return controller.store.createRecord("gameMember", {
						footballGame: footballGame,
						user: user,
						rsvpId: RSVP['default'].PENDING,
						roleId: GameMemberRole['default'].MEMBER
					}).save();
				}).then(function (gameMember) {
					user.get("gameMembers").pushObject(gameMember);
					footballGame.get("members").pushObject(gameMember);
					route.controllerFor("footballGame.member").set("model", gameMember);
				});
			},

			ignoreInvites: function ignoreInvites() {
				this.get("controller.myPendingInvites").then(function (invites) {
					return Ember['default'].RSVP.all(invites.map(function (invite) {
						invite.set("status", 2);
					}).invoke("save"));
				});
			},

			attend: function attend() {
				var member = this.get("controller.sessionMember");

				if (this.get("controller.isFull")) {
					member.set("rsvpId", RSVP['default'].RESERVE);
				} else {
					member.set("rsvpId", RSVP['default'].PLAYING);
				}
				return member.save();
			},

			decline: function decline() {
				this.set("controller.sessionMember.rsvpId", RSVP['default'].DECLINED);
				return this.get("controller.sessionMember").save();
			},

			changeRSVP: function changeRSVP() {
				this.set("controller.sessionMember.rsvpId", RSVP['default'].PENDING);
			}
		}
	});

});