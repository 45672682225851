define('sportly/components/x-groups/header', ['exports', 'ember', 'sportly/enums/group/member-role', 'sportly/enums/group/status'], function (exports, Ember, GroupMemberRole, GroupStatus) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-groups--header", "isDeleted"],
        group: null,

        isNotMember: Ember['default'].computed.not("isMember"),
        isActive: Ember['default'].computed.equal("group.status", GroupStatus['default'].ACTIVE),
        isDeleted: Ember['default'].computed.equal("group.status", GroupStatus['default'].DELETED),
        showJoin: Ember['default'].computed.and("isNotMember", "isActive"),
        showSettings: Ember['default'].computed.and("isMember", "isActive"),

        isAdmin: (function () {
            return this.get("group.members").filterBy("roleId", GroupMemberRole['default'].ADMIN).any(function (member) {
                return member.get("user.id") === this.get("session.user.id");
            }, this);
        }).property("group.members.@each.user.id", "group.members.@each.roleId", "session.user.id"),

        isMember: (function () {
            return this.get("group.members").any(function (member) {
                return member.get("user.id") === this.get("session.user.id");
            }, this);
        }).property("group.members.@each.user.id", "session.user.id"),

        actions: {
            join: function join() {
                this.sendAction("join");
            }
        }

    });

});