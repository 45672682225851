define('sportly/initializers/session', ['exports', 'sportly/sessions/custom'], function (exports, CustomSession) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container) {
    container.register("session:custom", CustomSession['default']);
  }

  exports['default'] = {
    name: "session",
    initialize: initialize
  };

});