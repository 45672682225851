define('sportly/tests/unit/initializers/session-test', ['ember', 'sportly/initializers/session', 'qunit'], function (Ember, session, qunit) {

  'use strict';

  var container, application;

  qunit.module("SessionInitializer", {
    beforeEach: function beforeEach() {
      Ember['default'].run(function () {
        application = Ember['default'].Application.create();
        container = application.__container__;
        application.deferReadiness();
      });
    }
  });

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    session.initialize(container, application);

    // you would normally confirm the results of the initializer here
    assert.ok(true);
  });

});