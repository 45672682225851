define('sportly/tests/acceptance/group/add-game-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - add game", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can't access 'create football game' if group is deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group/add-game");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".edit-admins article").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Member can't access 'create football game' page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-1/add-game");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find("article.create-football-game").length, 0, "Create game container is not shown");
        });
    });

    qunit.test("Admin can access 'create football game' page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/add-game");

        andThen(function () {
            assert.equal(currentPath(), "group.createFootballGame.index", "Should be on create football game page");
            assert.notEqual(find(".create-football-game").length, 0, "Create game container is shown");
        });
    });

    qunit.test("Admin can create a one-off football game", function (assert) {
        var defaultDate = null;

        assert.expect(10);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/add-game");

        andThen(function () {
            fillIn("#home-team-input", "Barcelona");
            fillIn("#away-team-input", "Real Madrid");
            fillIn("#player-limit-input", 0);

            click(".form-group.when");
        });

        andThen(function () {
            defaultDate = find("#start-date-input").val();
            click("#start-date-input");
        });

        andThen(function () {
            assert.notEqual(find(".picker--opened").length, 0, "Calendar is shown when date field is clicked");
            click(".picker__nav--next");
        });
        andThen(function () {
            click(".picker__day:not(.picker__day--disabled):last");
        });
        andThen(function () {
            assert.notEqual(find("#start-date-input").val(), "", "The selected date should not be empty");
            assert.notEqual(find("#start-date-input").val(), defaultDate, "The selected date should be different to the default date");
            click(".actions .btn-submit");
        });

        andThen(function () {
            click(".actions .btn-submit");
        });

        andThen(function () {
            assert.equal(currentPath(), "footballGame.index", "Shows created football game page");
            assert.equal(find(".x-football-games--header .home-team").text().trim(), "Barcelona", "Home team name is shown");
            assert.equal(find(".x-football-games--header .away-team").text().trim(), "Real Madrid", "Away team name is shown");
            assert.equal(find(".x-football-games--properties .location .value").text().trim(), "Windsor Boys", "Venue name is shown");
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
            assert.notEqual(find(".x-football-games--settings .settings").length, 0, "Settings is shown");
            click(".x-football-games--properties .group");
        });

        andThen(function () {
            click(".x-utils--menu a:nth-child(2)");
        });

        andThen(function () {
            assert.equal(find(".fixtures > ul > li").length, 1, "There should be one fixture listed on the group fixtures page");
        });
    });

    qunit.test("Admin can create weekly football games", function (assert) {
        var defaultDate = null;

        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/add-game");

        andThen(function () {
            fillIn("#home-team-input", "Barcelona");
            fillIn("#away-team-input", "Real Madrid");
            fillIn("#player-limit-input", 0);
            click(".form-group.when");
        });

        andThen(function () {
            fillIn("#frequency-input", 2);
            fillIn("#start-date-input", "01/01/2033");
            fillIn("#finish-date-input", "01/02/2033");
            click(".actions .btn-submit");
        });

        andThen(function () {
            assert.equal(find(".form-group.when .chosen").text().trim(), "Weekly 01/01/2033 to 01/02/...", "Weekly dates are shown");
            click(".actions .btn-submit");
        });

        andThen(function () {
            assert.equal(find(".fixtures > ul > li").length, 5, "There should be five fixtures listed on the group fixtures page");
        });
    });

});