define('sportly/routes/account-settings/edit-general', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.get("session.user.profile");
		},

		actions: {
			save: function save() {

				var _this = this,
				    profile = this.get("currentModel");

				if (Ember['default'].isEmpty(profile.get("firstName"))) {
					profile.get("errors").add("firstName", "First name is required");
				}
				if (Ember['default'].isEmpty(profile.get("lastName"))) {
					profile.get("errors").add("lastName", "Last name is required");
				}
				if (Ember['default'].isEmpty(profile.get("gender"))) {
					profile.get("errors").add("gender", "Gender is required");
				}
				if (Ember['default'].isEmpty(profile.get("dobMonth")) || Ember['default'].isEmpty(profile.get("dobYear"))) {
					profile.get("errors").add("dob", "Date of birth is required");
				}
				if (Ember['default'].isEmpty(profile.get("location"))) {
					profile.get("errors").add("location", "Location is required");
				}

				if (!profile.get("isValid")) {
					return;
				}

				this.geolocationService.geolocate(profile.get("location")).then(function (latlng) {
					profile.set("latitude", latlng[0]);
					profile.set("longitude", latlng[1]);
					return profile.save();
				}).then(function () {
					_this.transitionTo("accountSettings.general");
				}, function (e) {
					var errors = e.responseJSON.errors;
					for (var attribute in e.responseJSON.errors) {
						if (errors.hasOwnProperty(attribute)) {
							profile.get("errors").add(attribute, errors[attribute]);
						}
					}
				});
			},

			cancel: function cancel() {
				this.transitionTo("accountSettings.general");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Edit general");
				return true;
			}

		},

		deactivate: function deactivate() {
			if (this.get("currentModel.isDirty")) {
				this.get("currentModel").rollback();
			}
		}

	});

});