define('sportly/routes/group/edit/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.modelFor("group.edit");
		},

		actions: {
			saveChanges: function saveChanges() {

				var group = this.get("currentModel"),
				    route = this;

				if (!group.get("isDirty")) {
					return;
				}

				if (Ember['default'].isEmpty(group.get("name"))) {
					group.get("errors").add("name", "Group name is required");
				}
				if (Ember['default'].isEmpty(group.get("description"))) {
					group.get("errors").add("description", "Group description is required");
				}

				if (group.get("isValid")) {
					group.save().then(function () {
						route.transitionTo("group");
					}, function (e) {
						var errors = e.responseJSON.errors;
						for (var attribute in e.responseJSON.errors) {
							if (errors.hasOwnProperty(attribute)) {
								group.get("errors").add(attribute, errors[attribute]);
							}
						}
					});
				}
			},

			cancel: function cancel() {
				this.transitionTo("group.index");
			},

			chooseVenue: function chooseVenue() {
				this.transitionTo("group.edit.chooseVenue");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Edit group");
				return true;
			}

		}

	});

});