define('sportly/tests/acceptance/group/deleted-group-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - deleted group", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Deleted group shown as deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group");

        andThen(function () {
            assert.notEqual(find(".x-groups--header.is-deleted").length, 0, "Group header classed as is-deleted");
            assert.notEqual(find(".x-groups--header .is-deleted").length, 0, "Is deleted header message shown");
        });
    });

    qunit.test("Settings nav option not shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group");

        andThen(function () {
            assert.equal(find(".x-utils--menu .settings").length, 0, "Settings menu option not shown");
        });
    });

});