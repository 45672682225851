define('sportly/components/x-dropdown-menu', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-dropdown-menu", "isOpen"],

        isOpen: false,

        didInsertElement: function didInsertElement() {
            var self = this;

            this.$("> .trigger").click(function () {
                Ember['default'].run.scheduleOnce("afterRender", function () {
                    self.set("isOpen", true);
                });
            });

            Ember['default'].$(".ember-application .app").click(function () {
                self.set("isOpen", false);
            });
        }
    });

});