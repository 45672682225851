define('sportly/components/x-football-games/rsvps', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, Ember, RSVP) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-football-games--rsvps"],
        footballGame: null,

        hasUnlimitedSpaces: Ember['default'].computed.equal("footballGame.playerLimit", 0),

        players: (function () {
            return this.get("footballGame.members").filterBy("rsvpId", RSVP['default'].PLAYING);
        }).property("footballGame.members.@each.rsvpId"),

        spaces: (function () {
            var spaces = [],
                players = this.get("players");
            if (this.get("footballGame.playerLimit") === 0) {
                return spaces;
            }
            for (var i = 0; i < this.get("footballGame.playerLimit"); i++) {
                if (!Ember['default'].isEmpty(players[i])) {
                    spaces.pushObject({
                        index: i + 1,
                        player: players[i]
                    });
                } else {
                    spaces.pushObject({
                        index: i + 1,
                        player: null
                    });
                }
            }
            return spaces;
        }).property("footballGame.playerLimit", "players.@each")
    });

});