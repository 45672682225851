define('sportly/components/x-welcome/profile-form', ['exports', 'ember', 'sportly/config/environment'], function (exports, Ember, Config) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-welcome--profile-form"],
        profile: null,

        api: {
            host: Config['default'].API.host,
            namespace: Config['default'].API.namespace
        },
        uploadType: "photo",

        genders: (function () {
            return [{ label: "Male", value: "m" }, { label: "Female", value: "f" }];
        }).property(),

        dobDays: (function () {
            var daysInMonth = null,
                days = [];

            daysInMonth = moment("YYYY-01").daysInMonth();
            if (!Ember['default'].isEmpty(this.get("profile.dobYear")) && !Ember['default'].isEmpty(this.get("profile.dobMonth"))) {
                daysInMonth = moment(this.get("profile.dobYear") + "-" + this.get("profile.dobMonth")).daysInMonth();
            }

            for (var day = 1; day <= daysInMonth; day++) {
                days.pushObject(day);
            }
            return days;
        }).property("profile.dobMonth", "profile.dobYear"),

        dobMonths: (function () {
            return [{ label: "January", value: 1 }, { label: "February", value: 2 }, { label: "March", value: 3 }, { label: "April", value: 4 }, { label: "May", value: 5 }, { label: "June", value: 6 }, { label: "July", value: 7 }, { label: "August", value: 8 }, { label: "September", value: 9 }, { label: "October", value: 10 }, { label: "November", value: 11 }, { label: "December", value: 12 }];
        }).property(),

        dobYears: (function () {
            var years = [],
                lastYear = moment().format("YYYY") - 1;
            for (var year = lastYear; year > 1900; year--) {
                years.pushObject({
                    label: year, value: year
                });
            }
            return years;
        }).property(),

        hasDobError: (function () {
            return !Ember['default'].isEmpty(this.get("profile.errors.dobDay")) || !Ember['default'].isEmpty(this.get("profile.errors.dobMonth")) || !Ember['default'].isEmpty(this.get("profile.errors.dobyear"));
        }).property("profile.errors.dobDay", "profile.errors.dobMonth", "profile.errors.dobYear"),

        actions: {
            "continue": function _continue() {
                var profile = this.get("profile");

                if (Ember['default'].isEmpty(profile.get("gender"))) {
                    profile.get("errors").add("gender", "Gender is required");
                }
                if (Ember['default'].isEmpty(profile.get("dobDay"))) {
                    profile.get("errors").add("dobDay", "Day is required");
                }
                if (Ember['default'].isEmpty(profile.get("dobMonth"))) {
                    profile.get("errors").add("dobMonth", "Month is required");
                }
                if (Ember['default'].isEmpty(profile.get("dobYear"))) {
                    profile.get("errors").add("dobYear", "Year is required");
                }
                if (Ember['default'].isEmpty(profile.get("location"))) {
                    profile.get("errors").add("location", "Location is required");
                }

                if (!profile.get("isValid")) {
                    return;
                }

                this.sendAction("continue");
            },

            didUploadPhoto: function didUploadPhoto(filename) {
                this.sendAction("didUploadPhoto", filename);
            }
        }
    });

});