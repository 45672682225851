define('sportly/views/infobox/infobox', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].View.extend({

		markerSelected: (function () {
			if (!Em['default'].isEmpty(this.get("controller.marker"))) {

				Em['default'].run.scheduleOnce("afterRender", this, function () {
					var html = this.$().clone().find("script,noscript,style").remove().end().html();
					this.get("controller.marker").setPopupContent(html);
				});
			}
		}).observes("controller.marker")

	});

});