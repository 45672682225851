define('sportly/controllers/football-game/invite', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({
		needs: ["footballGame"],
		primarySender: Em['default'].computed.alias("controllers.footballGame.myPendingInvites.lastObject.sender"),
		canShowInvites: Em['default'].computed.alias("controllers.footballGame.canShowInvites"),
		hasMultipleInvites: Em['default'].computed.gt("controllers.footballGame.myPendingInvites.length", 1),
		othersCount: (function () {
			return this.get("controllers.footballGame.myPendingInvites.length") - 1;
		}).property("controllers.footballGame.myPendingInvites.length")
	});

});