define('sportly/controllers/group/create-football-game/index', ['exports', 'ember', 'sportly/enums/football-game/frequency'], function (exports, Em, FootballGameFrequency) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({
		needs: ["group", "group/create-football-game"],

		playerLimitMax: 40,

		createFootballGame: Em['default'].computed.alias("controllers.group/create-football-game"),

		isNoob: Em['default'].computed.alias("controllers.group.isNoob"),
		frequency: Em['default'].computed.alias("createFootballGame.frequency"),
		startDate: Em['default'].computed.alias("createFootballGame.startDate"),
		finishDate: Em['default'].computed.alias("createFootballGame.finishDate"),

		isDateChosen: (function () {
			if (this.get("isSingle") && !Em['default'].isEmpty(this.get("startDate"))) {
				return true;
			}
			if (this.get("isWeekly") && !Em['default'].isEmpty(this.get("startDate")) && !Em['default'].isEmpty(this.get("finishDate"))) {
				return true;
			}
			return false;
		}).property("isSingle", "isWeekly", "startDate", "finishDate"),

		chosenDateString: (function () {
			if (this.get("isSingle")) {
				return this.get("startDate");
			}
			if (this.get("isWeekly")) {
				return "Weekly " + this.get("startDate") + " to " + this.get("finishDate");
			}
			return "";
		}).property("isSingle", "isWeekly", "startDate", "finishDate"),

		isSingle: (function () {
			return this.get("frequency") === FootballGameFrequency['default'].SINGLE;
		}).property("frequency"),

		isWeekly: (function () {
			return this.get("frequency") === FootballGameFrequency['default'].WEEKLY;
		}).property("frequency"),

		timeOptions: (function () {
			var options = [];
			for (var idx = 0; idx <= 85500; idx += 900) {
				options[idx / 900] = {
					name: ("0" + Math.floor(idx / 3600)).slice(-2) + ":" + ("0" + idx % 3600 / 60).slice(-2),
					value: ("0" + Math.floor(idx / 3600)).slice(-2) + ":" + ("0" + idx % 3600 / 60).slice(-2)
				};
			}
			return options;
		}).property(),

		gameLengthOptions: (function () {
			var gameLengthOptions = [];
			for (var i = 2; i <= 90; i++) {
				gameLengthOptions.pushObject({
					name: i + " minutes",
					value: i * 60
				});
			}
			gameLengthOptions.pushObjects([{ name: "2 hours", value: 7200 }, { name: "3 hours", value: 10800 }, { name: "4 hours", value: 14400 }]);
			return gameLengthOptions;
		}).property(),

		gameLengthMinutes: (function (key, value) {

			if (arguments.length === 2) {
				this.set("gameLength", value * 60);
				return value;
			} else {
				return this.get("gameLength") / 60;
			}
		}).property("gameLength"),

		gameTypeOptions: (function () {
			var gameTypeOptions = [];
			for (var i = 1; i < 11; i++) {
				gameTypeOptions.pushObject({
					name: i + 1 + " a-side",
					value: i
				});
			}
			return gameTypeOptions;
		}).property(),

		playerLimitMin: (function () {
			return (this.get("gameTypeId") + 1) * 2;
		}).property("gameTypeId"),

		playerLimitOptions: (function () {
			var playerLimits = [{ name: "Unlimited", value: 0 }];
			for (var i = this.get("playerLimitMin"); i <= this.get("playerLimitMax"); i++) {
				playerLimits.pushObject({
					name: i + " players",
					value: i
				});
			}
			return playerLimits;
		}).property("playerLimitMin", "playerLimitMax"),

		multipleGames: (function () {
			return this.get("frequency") === FootballGameFrequency['default'].WEEKLY;
		}).property("frequency")

	});

});