define('sportly/components/x-global/side-menu', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-global--side-menu", "isShowing"],
        isShowing: false,
        user: null,
        toggleSideMenu: null,

        unreadNotificationsCount: Ember['default'].computed.alias("unreadNotifications.length"),
        hasUnreadNotifications: Ember['default'].computed.gt("unreadNotifications.length", 0),
        unreadNotifications: Ember['default'].computed.filterBy("user.notifications", "unread", 1),

        click: function click() {
            if (this.get("isShowing")) {
                this.sendAction("toggleSideMenu");
            }
        },

        actions: {
            invalidateSession: function invalidateSession() {
                this.sendAction("invalidateSession");
            }
        }

    });

});