define('sportly/controllers/profile/groups', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({

		needs: ["profile"],

		sortProperties: ["name"],
		sortAscending: true,

		hasGroups: Ember['default'].computed.notEmpty("content"),
		profile: Ember['default'].computed.alias("controllers.profile.model")
	});

});