define('sportly/enums/football-game/rsvp', ['exports'], function (exports) {

    'use strict';

    exports['default'] = Object({
        PENDING: 1,
        PLAYING: 2,
        RESERVE: 3,
        DECLINED: 4,
        BANNED: 5
    });

});