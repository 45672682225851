define('sportly/initializers/i18n', ['exports', 'ember'], function (exports, Em) {

    'use strict';

    exports['default'] = {
        name: "i18n",

        initialize: function initialize() {

            Em['default'].I18n.translations = {
                en: {
                    "fixture-count": {
                        zero: "No fixtures",
                        one: "1 fixture",
                        other: "{{count}} fixtures"
                    },
                    "member-count": {
                        zero: "No members",
                        one: "1 member",
                        other: "{{count}} members"
                    },
                    errors: {
                        "failed-to-gelocate": "Sorry, we couldn't find that location. You could try a larger place (e.g a town instead of street name)"
                    }
                }
            };

            Em['default'].I18n.locale = "en";
        }
    };

});