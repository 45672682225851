define('sportly/models/group', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({

		url: DS['default'].attr("string"),
		name: DS['default'].attr("string"),
		description: DS['default'].attr("string"),
		venue: DS['default'].belongsTo("venue", { async: true, inverse: "groups" }),
		games: DS['default'].hasMany("football-game", { async: true, inverse: "group" }),
		members: DS['default'].hasMany("group-member", { async: true, inverse: "group" }),
		pendingInvites: DS['default'].hasMany("group-invite", { async: true }),
		status: DS['default'].attr("number"),
		stories: DS['default'].hasMany("story", { async: true, polymorphic: true })
	});

});