define('sportly/routes/dashboard', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			var user = this.get("session.user"),
			    profile = null;

			this._super(transition);

			return user.then(function (user) {
				return user.get("profile");
			}).then(function (fulfilled) {
				profile = fulfilled;
				return user.get("gameMembers");
			}).then(function (gameMembers) {
				return Ember['default'].RSVP.all(gameMembers.mapBy("footballGame"));
			}).then(function () {
				return user.get("groupMembers");
			}).then(function (groupMembers) {
				return Ember['default'].RSVP.all(groupMembers.mapBy("group"));
			}).then(function () {
				return user.get("stories");
			}).then(function () {
				return Ember['default'].RSVP.all([profile.get("photo"), user.get("followers"), user.get("following")]);
			});
		},

		model: function model() {
			return this.get("session.user.profile");
		},

		afterModel: function afterModel(profile) {
			if (profile.get("onboarding") === 1) {
				this.transitionTo("welcome.index");
			}
		},

		actions: {

			didPost: function didPost(body) {

				var _this = this;

				return this.store.createRecord("post/status-update", {
					user: this.get("session.user"),
					body: body
				}).save().then(function (post) {
					return _this.store.createRecord("story/status-update", {
						user: _this.get("session.user"),
						post: post
					}).save();
				}).then(function (story) {
					_this.get("session.user.stories").pushObject(story);
				});
			},

			dismissIntro: function dismissIntro() {
				this.get("session.user.profile").set("isNoob", false);
				this.get("session.user.profile").save();
			}

		}
	});

});