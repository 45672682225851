define('sportly/models/group-member', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		user: DS['default'].belongsTo("user", { async: true }),
		group: DS['default'].belongsTo("group", { async: true }),
		roleId: DS['default'].attr("number")
	});

});