define('sportly/controllers/football-game/playing', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, Ember, RSVP) {

    'use strict';

    exports['default'] = Ember['default'].ArrayController.extend({

        needs: ["football-game"],

        footballGame: Ember['default'].computed.alias("controllers.football-game.model"),
        notCancelled: Ember['default'].computed.not("footballGame.isCancelled"),
        notFinished: Ember['default'].computed.not("footballGame.isFinished"),
        isActive: Ember['default'].computed.and("notCancelled", "notFinished"),
        hasGroup: Ember['default'].computed.notEmpty("footballGame.group.content.id"),
        isOneOffGame: Ember['default'].computed.not("hasGroup"),

        canInviteFriends: Ember['default'].computed.and("sessionIsMember", "isOneOffGame", "isActive"),

        playing: Ember['default'].computed.filterBy("model", "rsvpId", RSVP['default'].PLAYING),

        sessionIsMember: (function () {
            var userId = this.get("session.user.id");
            return this.get("footballGame.members").any(function (member) {
                return member.get("user.id") === userId;
            });
        }).property("footballGame.members.@each.user.id", "session.user.id") });

});