define('sportly/routes/football-game/edit/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		actions: {

			chooseVenue: function chooseVenue() {
				this.transitionTo("footballGame.edit.chooseVenue");
			},

			chooseDate: function chooseDate() {
				this.transitionTo("footballGame.edit.chooseDate");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Edit game");
				return true;
			}

		}
	});

});