define('sportly/components/x-guest/nav', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-guest--nav", "isHidden"],
        isLoading: false,
        title: null,
        isHidden: false,

        hasPageTitle: Ember['default'].computed.notEmpty("title")
    });

});