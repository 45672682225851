define('sportly/controllers/guest/join', ['exports', 'ember', 'ember-data', 'sportly/enums/app-invite/status'], function (exports, Ember, DS, AppInviteStatus) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        appInvite: null,
        errors: new DS['default'].Errors(),

        pending: AppInviteStatus['default'].PENDING,

        hasAppInvite: Ember['default'].computed.notEmpty("appInvite"),
        appInviteIsPending: Ember['default'].computed.equal("appInvite.status", AppInviteStatus['default'].PENDING),
        appInviteIsNotPending: Ember['default'].computed.not("appInviteIsPending"),
        appInviteIsUsed: Ember['default'].computed.and("hasAppInvite", "appInviteIsNotPending")
    });

});