define('sportly/routes/account-settings/password', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		actions: {
			changePassword: function changePassword() {
				var _this = this,
				    user = this.get("currentModel");

				if (Ember['default'].isEmpty(user.get("newPassword"))) {
					user.get("errors").add("newPassword", "New password is required");
				}
				if (Ember['default'].isEmpty(user.get("password"))) {
					user.get("errors").add("password", "Password is required");
				}

				if (user.get("errors.length")) {
					return;
				}

				user.save().then(function () {
					_this.transitionTo("accountSettings.index");
				}, function (e) {
					var errors = e.responseJSON.errors;
					for (var attribute in e.responseJSON.errors) {
						if (errors.hasOwnProperty(attribute)) {
							user.get("errors").add(attribute, errors[attribute]);
						}
					}
				});
			},

			cancel: function cancel() {
				this.transitionTo("accountSettings.index");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Change password");
				return true;
			}

		},

		deactivate: function deactivate() {
			if (this.get("currentModel.isDirty")) {
				this.get("currentModel").rollback();
			}
		}
	});

});