define('sportly/tests/acceptance/group/edit-admins-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - edit admins", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can't access 'edit admins' if group is deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group/edit-admins");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".edit-admins").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Member can't access 'edit admins' page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-1/edit-admins");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".edit-admins").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Admin can access 'edit admins' page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/edit-admins");

        andThen(function () {
            assert.equal(currentPath(), "group.editAdmins", "Should be on edit admins page");
            assert.notEqual(find(".edit-admins").length, 0, "Edit admins container is shown");
        });
    });

    qunit.test("Selectable admins' names are shown", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1");

        andThen(function () {
            visit("/group/test-group-1/edit-admins");
        });

        andThen(function () {
            assert.equal(find(".edit-admins .content li:first-child .name").text().trim(), "Zinedine Zidane", "First members' name should be shown");
            assert.equal(find(".edit-admins .content li:last-child .name").text().trim(), "Lionel Messi", "Second members' name should be shown");
        });
    });

    qunit.test("Admin can add admin to group", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/admins");

        andThen(function () {
            assert.equal(find(".admins li").length, 1, "There should be one admin");
            visit("/group/test-group-1/edit-admins");
        });

        andThen(function () {
            click(".edit-admins ul li:nth-of-type(2) > div");
            assert.notEqual(find(".edit-admins ul li:nth-of-type(2) > div.is-selected").length, 0, "Clicked member should be selected");
            click(".edit-admins .actions button");
        });

        andThen(function () {
            visit("/group/test-group-1/admins");
        });

        andThen(function () {
            assert.equal(find(".admins li").length, 2, "There should be two admins");
        });
    });

    qunit.test("Admin can remove admin from group", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-with-two-admins/admins");

        andThen(function () {
            assert.equal(find(".admins li").length, 2, "There should be two admins");
            visit("/group/test-group-with-two-admins/edit-admins");
        });

        andThen(function () {
            click(".edit-admins ul li:nth-of-type(2) > div");
            assert.equal(find(".edit-admins ul li:nth-of-type(2) > div.is-selected").length, 0, "Clicked member should not be selected");
            click(".edit-admins .actions button");
        });

        andThen(function () {
            visit("/group/test-group-with-two-admins/admins");
        });

        andThen(function () {
            assert.equal(find(".admins li").length, 1, "There should be one admin");
        });
    });

    qunit.test("Admin can't remove admin if last admin", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/admins");

        andThen(function () {
            assert.equal(find(".admins li").length, 1, "There should be one admin");
            visit("/group/test-group-1/edit-admins");
        });

        andThen(function () {
            assert.notEqual(find(".edit-admins ul li:nth-of-type(1) > div.is-selected").length, 0, "Clicked member should be selected");
            click(".edit-admins ul li:nth-of-type(1) > div");
        });
        andThen(function () {
            assert.notEqual(find(".edit-admins ul li:nth-of-type(1) > div.is-selected").length, 0, "Clicked member should still be selected");
        });
    });

});