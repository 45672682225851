define('sportly/models/football-game', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		homeTeam: DS['default'].belongsTo("football-team"),
		awayTeam: DS['default'].belongsTo("football-team"),
		group: DS['default'].belongsTo("group", { async: true, inverse: "games" }),
		creator: DS['default'].belongsTo("user", { async: true }),
		gameTypeId: DS['default'].attr("number"),
		url: DS['default'].attr("string"),
		timestamp: DS['default'].attr("number"),
		localTime: DS['default'].attr("string"),
		localDate: DS['default'].attr("string"),
		cancellationMessage: DS['default'].attr("string"),
		sportId: DS['default'].attr("number"),
		venue: DS['default'].belongsTo("venue"),
		gameLength: DS['default'].attr("number"),
		playerLimit: DS['default'].attr("number"),
		isCancelled: DS['default'].attr("number"),
		isFinished: DS['default'].attr("number"),
		members: DS['default'].hasMany("game-member", { async: true, inverse: "footballGame" }),
		pendingInvites: DS['default'].hasMany("football-game-invite", { async: true }),
		stories: DS['default'].hasMany("story", { async: true, polymorphic: true })
	});

});