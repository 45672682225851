define('sportly/routes/guest/sign-in', ['exports', 'simple-auth/mixins/unauthenticated-route-mixin', 'ember'], function (exports, UnauthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(UnauthenticatedRouteMixin['default'], {

		setupController: function setupController(controller) {
			controller.get("errors").clear();
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("guest").set("navIsHidden", true);
				return true;
			},

			willTransition: function willTransition() {
				this.controllerFor("guest").set("navIsHidden", false);
				return true;
			}
		}

	});

});