define('sportly/components/x-profiles/friends', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-profiles--friends", "hasFriends"],
        user: null,
        hasFriends: Ember['default'].computed.notEmpty("friendUsers"),

        friendUsers: (function () {
            var followerUsers = this.get("user.followers").mapBy("user"),
                followingUsers = this.get("user.following").mapBy("followed");
            return followingUsers.filter(function (followingUser) {
                return followerUsers.findBy("id", followingUser.get("id"));
            });
        }).property("user.followers.@each.user", "user.following.@each.followed")

    });

});