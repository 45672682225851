define('sportly/routes/group/edit-admins', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			this._super(transition);
			return this.modelFor("group").get("members").then(function (members) {
				return Ember['default'].RSVP.all(members.mapBy("group"));
			});
		},

		model: function model() {
			return this.modelFor("group").get("members");
		},

		afterModel: function afterModel() {
			if (this.modelFor("group").get("status") !== GroupStatus['default'].ACTIVE) {
				return this.transitionTo("group.index");
			}
		},

		setupController: function setupController(controller, members) {
			controller.set("model", members);
			if (!this.controllerFor("group").get("isAdmin")) {
				this.transitionTo("group.index");
			}
		},

		actions: {
			save: function save() {
				var route = this;
				Ember['default'].RSVP.all(this.get("controller.content").filterBy("isDirty").invoke("save")).then(function () {
					route.transitionTo("group.index");
				});
			},

			cancel: function cancel() {
				this.transitionTo("group.admins");
			},

			willTransition: function willTransition() {
				this.get("controller.content").filterBy("isDirty").invoke("rollback");
				return true;
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Edit admins");
				return true;
			}
		}

	});

});