define('sportly/tests/unit/helpers/time-ago-test', ['sportly/helpers/time-ago', 'qunit'], function (time_ago, qunit) {

  'use strict';

  qunit.module("TimeAgoHelper");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = time_ago.timeAgo(42);
    assert.ok(result);
  });

});