define('sportly/routes/welcome/profile', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        model: function model() {
            return this.get("session.user").then(function (user) {
                return user.get("profile");
            });
        },

        actions: {
            "continue": function _continue() {
                var _this = this,
                    profile = this.get("currentModel");

                this.geolocationService.geolocate(profile.get("location")).then(function (latlng) {
                    if (Ember['default'].isEmpty(latlng)) {
                        profile.get("errors").add("location", "errors.failed-to-gelocate");
                        return Ember['default'].RSVP.reject();
                    }
                    profile.set("latitude", latlng[0]);
                    profile.set("longitude", latlng[1]);
                    return profile.save();
                }).then(function () {
                    _this.transitionTo("welcome.groups");
                }, function () {});
            },

            didUploadPhoto: function didUploadPhoto(filename) {
                var _this = this;
                this.store.createRecord("photo", {
                    filename: filename
                }).save().then(function (photo) {
                    _this.set("controller.model.photo", photo);
                });
            }
        },

        deactivate: function deactivate() {
            if (this.get("currentModel.isDirty")) {
                this.get("currentModel").rollback();
            }
        }
    });

});