define('sportly/views/slider', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].View.extend({

		value: null,
		min: 0,
		max: 1,

		didInsertElement: function didInsertElement() {
			var self = this;
			this.$().slider({
				min: parseInt(this.get("min")),
				max: parseInt(this.get("max")),
				values: [this.get("value")],
				slide: function slide(event, ui) {
					self.set("value", ui.value);
				}
			});
		},

		minChanged: (function () {
			this.$().slider("option", "min", this.get("min"));
		}).observes("min")

	});

});