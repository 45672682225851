define('sportly/helpers/str-t', ['exports', 'ember'], function (exports, Em) {

    'use strict';

    exports.strT = strT;

    function strT(i18nKey, options) {
        var params = options.hash;

        i18nKey = Em['default'].I18n.locale + "." + i18nKey;

        if (params.count === 0 && Em['default'].I18n.locale === "en") {
            i18nKey += ".zero";
        }

        return Em['default'].I18n.t(i18nKey, params);
    }

    exports['default'] = Em['default'].Handlebars.makeBoundHelper(strT);

});