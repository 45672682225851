define('sportly/routes/profile/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

        actions: {

            didPost: function didPost(body) {
                var _this = this;

                return this.store.createRecord("post/profile", {
                    user: _this.get("session.user"),
                    profile: _this.get("currentModel"),
                    body: body
                }).save().then(function (post) {
                    return _this.store.createRecord("story/profile-post", {
                        user: _this.get("session.user"),
                        targetUser: _this.get("currentModel.user"),
                        post: post
                    }).save();
                }).then(function (story) {
                    _this.get("currentModel.stories").pushObject(story);
                });
            },

            didTransition: function didTransition() {
                this.controllerFor("application").set("pageTitle", "Profile");
                return true;
            }
        }
    });

});