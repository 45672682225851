define('sportly/tests/acceptance/football-game/playing-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Football game - playing", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Playing member shown", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/2/playing");

        andThen(function () {
            assert.equal(find(".playing ul > li").length, 1, "1 playing member shown");
            assert.equal(find(".playing ul > li:first-child .name").text().trim(), "Zinedine Zidane", "Playing member's name shown");
        });
    });

});