define('sportly/components/x-stories/app-join-story', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-stories--app-join-story", "isLoading"],

        isLoading: Ember['default'].computed.not("isLoaded"),
        isLoaded: Ember['default'].computed.and("story.user.profile.isLoaded", "story.user.profile.photo.isLoaded"),

        isSessionUser: (function () {
            return this.get("session.user.id") === this.get("story.user.id");
        }).property("session.user.id", "story.user.id")
    });

});