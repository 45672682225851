define('sportly/views/spinner', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].View.extend({

		left: "auto",
		top: "auto",
		color: "#000",
		classNames: ["spinner-wrapper"],
		radius: 4,
		spokeLength: 3,
		lines: 11,

		didInsertElement: function didInsertElement() {
			new Spinner({
				lines: 12, //parseInt(this.get('lines')), // The number of lines to draw
				length: 3, //parseInt(this.get('spokeLength')), // The length of each line
				width: 2, // The line thickness
				radius: 4, //parseInt(this.get('radius')), // The radius of the inner circle
				corners: 1, // Corner roundness (0..1)
				rotate: 0, // The rotation offset
				color: this.get("color"), // #rgb or #rrggbb
				speed: 2.2, // Rounds per second
				trail: 60, // Afterglow percentage
				shadow: false, // Whether to render a shadow
				hwaccel: true, // Whether to use hardware acceleration
				className: "spinner", // The CSS class to assign to the spinner
				zIndex: 2000000000, // The z-index (defaults to 2000000000)
				top: this.get("top"), // Top position relative to parent in px
				left: this.get("left") // Left position relative to parent in px
			}).spin(this.$().get(0));
		}
	});

});