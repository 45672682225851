define('sportly/tests/acceptance/invite-friends-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Invite friends", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can invite email addresses", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/invite-friends");

        andThen(function () {
            fillIn("#email-addresses-input", "jack.johnson@sportly.com, john.jackson@sportly.com");
            assert.equal(find(".form-group.preview .email").length, 2, "Two email addresses found");
            click(".invite-friends .actions .btn-submit");
        });

        andThen(function () {
            assert.notEqual(find(".invited:visible").length, 0, "'Invited' message is showing");
            assert.equal(find("#email-addresses-input").val(), "", "Textarea is now empty");
        });
    });

});