define('sportly/tests/unit/components/x-profiles/friends-test', ['ember-qunit', 'ember'], function (ember_qunit, Ember) {

    'use strict';

    ember_qunit.moduleForComponent("x-profiles/friends", {
        needs: ["component:x-profiles/profile-thumb"]
    });

    ember_qunit.test("it renders", function (assert) {
        assert.expect(2);

        // creates the component instance
        var component = this.subject();
        assert.equal(component._state, "preRender");

        component.set("user", {
            profile: 1,
            followers: [{
                id: 1,
                user: Ember['default'].Object.create({
                    id: 2
                }),
                followed: Ember['default'].Object.create({
                    id: 1
                })
            }],
            following: [{
                id: 2,
                user: Ember['default'].Object.create({
                    id: 1
                }),
                followed: Ember['default'].Object.create({
                    id: 2
                })
            }]
        });

        // appends the component to the page
        this.render();
        assert.equal(component._state, "inDOM");
    });

});