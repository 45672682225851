define('sportly/routes/football-game/cancel', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.modelFor("footballGame");
		},

		setupController: function setupController(controller, footballGame) {
			controller.set("model", footballGame);
			if (!this.controllerFor("footballGame").get("canEditGame")) {
				this.transitionTo("footballGame.index");
			}
		},

		actions: {
			cancelGame: function cancelGame() {
				var _this = this,
				    footballGame = this.get("currentModel");
				footballGame.set("isCancelled", true);
				footballGame.save().then(function () {
					return _this.store.createRecord("story/football-game-cancel", {
						user: _this.get("session.user"),
						footballGame: footballGame
					}).save();
				}).then(function (story) {
					footballGame.get("stories").pushObject(story);
					_this.transitionTo("footballGame.index");
				});
			},

			cancel: function cancel() {
				this.transitionTo("footballGame.settings");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Cancel game");
				return true;
			}

		},

		deactivate: function deactivate() {
			if (this.get("currentModel.isDirty")) {
				this.get("currentModel").rollback();
			}
		}

	});

});