define('sportly/routes/profile/groups', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			this._super(transition);
			return this.modelFor("profile").get("user.groupMembers");
		},

		model: function model() {
			return this.modelFor("profile").get("user.groupMembers").mapBy("group").filter(function (group) {
				return group.get("status") !== GroupStatus['default'].DELETED;
			});
		},

		afterModel: function afterModel(groups) {
			return Ember['default'].RSVP.all(groups.mapBy("games")).then(function () {
				return Ember['default'].RSVP.all(groups.mapBy("members"));
			}).then(function () {
				return Ember['default'].RSVP.all(groups.mapBy("venue"));
			});
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Groups");
				return true;
			}
		}

	});

});