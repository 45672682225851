define('sportly/sessions/custom', ['exports', 'simple-auth/session', 'ember'], function (exports, Session, Ember) {

    'use strict';

    exports['default'] = Session['default'].extend({
        user: (function () {
            var userId = this.get("userId");
            if (!Ember['default'].isEmpty(userId)) {
                return this.container.lookup("store:main").find("user", userId);
            }
        }).property("userId")
    });

});