define('sportly/models/follower', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		user: DS['default'].belongsTo("user", { async: true, inverse: "following" }),
		followed: DS['default'].belongsTo("user", { async: true, inverse: "followers" }),
		timestamp: DS['default'].attr("number")
	});

});