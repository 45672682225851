define('sportly/components/x-guest/footer', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-guest--footer"],
        tagName: "footer"

    });

});