define('sportly/helpers/str-truncate', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  exports.strTruncate = strTruncate;

  function strTruncate(str, len) {
    if (str.length > len) {
      return str.substring(0, len - 3) + "...";
    }
    return str;
  }

  exports['default'] = Em['default'].Handlebars.makeBoundHelper(strTruncate);

});