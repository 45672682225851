define('sportly/tests/unit/components/x-guest/for-players-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleForComponent("x-guest/for-players", {
    needs: ["component:x-utils/scroll-visibility", "component:x-guest/for-section"]
  });

  ember_qunit.test("it renders", function (assert) {
    assert.expect(2);

    // creates the component instance
    var component = this.subject();
    assert.equal(component._state, "preRender");

    // appends the component to the page
    this.render();
    assert.equal(component._state, "inDOM");
  });

});