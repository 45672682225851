define('sportly/routes/group/leave', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		afterModel: function afterModel(group) {
			if (group.get("status") !== GroupStatus['default'].ACTIVE) {
				return this.transitionTo("group.index");
			}
		},

		actions: {
			leave: function leave() {

				var user = this.get("session.user"),
				    now = moment().format("X"),
				    group = this.get("currentModel"),
				    records = [],
				    _this = this;

				group.get("games").filter(function (game) {
					return game.get("timestamp") > now;
				}).forEach(function (game) {
					game.get("members").filterProperty("user.content.id", user.get("id")).forEach(function (member) {
						member.deleteRecord();
						records.pushObject(member);
					});
				});
				group.get("members").filterProperty("user.content.id", user.get("id")).forEach(function (member) {
					member.deleteRecord();
					records.pushObject(member);
				});

				Ember['default'].RSVP.all(records.invoke("save")).then(function () {
					return _this.store.createRecord("story/group-leave", {
						user: user,
						group: group
					}).save();
				}).then(function (story) {
					group.get("stories").pushObject(story);
					_this.transitionTo("group");
				});
			},

			cancel: function cancel() {
				this.transitionTo("group.index");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Leave group");
				return true;
			}
		}

	});

});