define('sportly/controllers/groups', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({

		sortProperties: ["name"],
		sortAscending: true,

		itemCount: 20,

		pagedContent: (function () {
			return this.get("arrangedContent").slice(0, this.get("itemCount"));
		}).property("arrangedContent.@each")

	});

});