define('sportly/controllers/welcome/finished', ['exports', 'ember'], function (exports, Em) {

    'use strict';

    exports['default'] = Em['default'].ArrayController.extend({

        hasInvites: Em['default'].computed.notEmpty("model")

    });

});