define('sportly/tests/acceptance/profile/friends-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Profile - friends", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Two friends shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/zidane/friends");

        andThen(function () {
            assert.equal(find(".friends li").length, 2, "Two friends found");
        });
    });

    qunit.test("Friends name shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/zidane/friends");

        andThen(function () {
            assert.equal(find(".friends li:first-child .name").text(), "Lionel Messi", "Friend's name is shown");
        });
    });

});