define('sportly/routes/football-game/member/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, AuthenticatedRouteMixin, Ember, RSVP) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		actions: {
			unban: function unban() {
				this.set("currentModel.rsvpId", RSVP['default'].PENDING);
				this.get("currentModel").save();
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Game member");
				return true;
			}

		}
	});

});