define('sportly/controllers/group/invite-friends-by-email', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({
		needs: ["group"],
		invited: [],
		emailAddressesInput: null,
		group: Em['default'].computed.alias("controllers.group.model"),
		isNoob: Em['default'].computed.alias("controllers.group.isNoob"),

		invitedMultiple: (function () {
			return this.get("invited.length") > 1;
		}).property("invited.length"),

		multipleEmailAddresses: (function () {
			return this.get("emailAddresses.length") > 1;
		}).property("emailAddresses"),

		emailAddressesEmpty: (function () {
			return this.get("emailAddresses.length") === 0;
		}).property("emailAddresses"),

		emailAddresses: (function () {
			var emailAddresses = [];
			if (Em['default'].isEmpty(this.get("emailAddressesInput"))) {
				return [];
			}
			emailAddresses = this.get("emailAddressesInput").match(/([\+a-zA-Z0-9'._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
			if (Em['default'].isEmpty(emailAddresses)) {
				return [];
			}
			return emailAddresses.uniq();
		}).property("emailAddressesInput")
	});

});