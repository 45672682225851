define('sportly/views/map', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].View.extend({

		didInsertElement: function didInsertElement() {
			var controller = this.get("controller"),
			    map,
			    markerClusterGroup,
			    geocoder;

			markerClusterGroup = new L.MarkerClusterGroup({
				animateAddingMarkers: true
			});
			L.mapbox.accessToken = "pk.eyJ1Ijoic3BvcnRseSIsImEiOiJWN3JGMERnIn0.PsLYtNfd0U2VBQ1Zwvfqeg";
			map = L.mapbox.map("map", "sportly.map-v8jdk228", {
				scrollWheelZoom: false,
				minZoom: 13,
				closePopupOnClick: false,
				center: [controller.get("latitude"), controller.get("longitude")],
				zoom: controller.get("zoom"),
				attributionControl: false
			});
			geocoder = L.mapbox.geocoder("mapbox.places-v1");

			map.addLayer(markerClusterGroup);

			map.on("locationfound", function (e) {
				map.fitBounds(e.bounds);
				map.setZoom(14);
			});

			controller.mapLoaded(map, markerClusterGroup, geocoder);
		},

		willDestroyElement: function willDestroyElement() {
			this.get("controller.map").remove();
		}

	});

});