define('sportly/controllers/account-settings/edit-general', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].ObjectController.extend({
        genders: (function () {
            return [{ label: "Male", value: "m" }, { label: "Female", value: "f" }];
        }).property(),

        dobDays: (function () {
            var daysInMonth = null,
                days = [];

            daysInMonth = moment("YYYY-01").daysInMonth();
            if (!Ember['default'].isEmpty(this.get("dobYear")) && !Ember['default'].isEmpty(this.get("dobMonth"))) {
                daysInMonth = moment(this.get("dobYear") + "-" + this.get("dobMonth")).daysInMonth();
            }

            for (var day = 1; day <= daysInMonth; day++) {
                days.pushObject(day);
            }
            return days;
        }).property("dobMonth", "dobYear"),

        dobMonths: (function () {
            return [{ label: "January", value: 1 }, { label: "February", value: 2 }, { label: "March", value: 3 }, { label: "April", value: 4 }, { label: "May", value: 5 }, { label: "June", value: 6 }, { label: "July", value: 7 }, { label: "August", value: 8 }, { label: "September", value: 9 }, { label: "October", value: 10 }, { label: "November", value: 11 }, { label: "December", value: 12 }];
        }).property(),

        dobYears: (function () {
            var years = [],
                lastYear = moment().format("YYYY") - 1;
            for (var year = lastYear; year > 1900; year--) {
                years.pushObject({
                    label: year, value: year
                });
            }
            return years;
        }).property(),

        hasDobError: (function () {
            return !Ember['default'].isEmpty(this.get("errors.dobDay")) || !Ember['default'].isEmpty(this.get("errors.dobMonth")) || !Ember['default'].isEmpty(this.get("errors.dobyear"));
        }).property("errors.dobDay", "errors.dobMonth", "errors.dobYear") });

});