define('sportly/components/x-football-games/properties', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-football-games--properties"],
        footballGame: null,

        hasGroup: Ember['default'].computed.notEmpty("footballGame.group.id"),
        hasPlayerLimit: Ember['default'].computed.gt("footballGame.playerLimit", 0),

        gameLengthMinutes: (function () {
            return this.get("footballGame.gameLength") / 60;
        }).property("footballGame.gameLength"),

        gameType: (function () {
            return this.get("footballGame.gameTypeId") + 1;
        }).property("footballGame.gameTypeId") });

});