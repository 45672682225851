define('sportly/components/x-utils/scroll-visibility', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-utils--scroll-visibility", "inViewport"],
        inViewport: false,
        visibilityHandler: null,
        topOffset: 0,

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.visibilityHandler = function () {
                Ember['default'].run.debounce(_this, function () {
                    var scrollTop = Ember['default'].$(window).scrollTop(),
                        windowHeight = Ember['default'].$(window).height();
                    if (_this.$().offset().top + _this.get("topOffset") < scrollTop + windowHeight) {
                        _this.set("inViewport", true);
                    }
                }, 150);
            };

            Ember['default'].$(window).on("DOMContentLoaded load resize scroll touchmove", this.visibilityHandler);
        },

        willDestroyElement: function willDestroyElement() {
            Ember['default'].$(window).off("DOMContentLoaded load resize scroll touchmove", this.visibilityHandler);
        }
    });

});