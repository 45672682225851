define('sportly/helpers/time-ago', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.timeAgo = timeAgo;

    function timeAgo(timestamp) {
        return moment(timestamp, "X").fromNow();
    }

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(timeAgo);

});