define('sportly/tests/acceptance/group/settings-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - settings", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can't access 'settings' if group is deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group/settings");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".edit-admins article").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Admin options are correctly shown to an admin on group settings page", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/settings");

        andThen(function () {
            assert.notEqual(find(".settings .edit-admins").length, 0, "Edit admins option is shown");
            assert.notEqual(find(".settings .edit-group").length, 0, "Edit group option is shown");
            assert.notEqual(find(".settings .delete-group").length, 0, "Delete group option is shown");
        });
    });

    qunit.test("Admin options are not shown to a member on group settings page", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-1/settings");

        andThen(function () {
            assert.equal(find(".settings .edit-admins").length, 0, "Edit admins option is not shown");
            assert.equal(find(".settings .edit-group").length, 0, "Edit group option is not shown");
            assert.equal(find(".settings .delete-group").length, 0, "Delete group option is not shown");
        });
    });

});