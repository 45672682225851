define('sportly/components/x-utils/menu', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-utils--menu", "showMore", "hasMoreItems"],

        hasMoreItems: false,
        showMore: false,

        didInsertElement: function didInsertElement() {
            if (this.$(".secondary").length > 0) {
                this.set("hasMoreItems", true);
            }
        },

        click: function click(e) {

            if (!this.get("hasMoreItems") || Ember['default'].$(e.target).is(this.$(".more"))) {
                return;
            }

            if (this.get("showMore")) {
                this.set("showMore", false);
            }
        },

        actions: {
            toggleMore: function toggleMore() {
                this.toggleProperty("showMore");
            }
        }
    });

});