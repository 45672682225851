define('sportly/controllers/group/member', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		ROLE: {
			ADMIN: 1,
			MEMBER: 2
		},

		isAdmin: (function () {
			return this.get("roleId") === this.ROLE.ADMIN;
		}).property("roleId"),

		actions: {
			toggleAdmin: function toggleAdmin() {

				if (this.get("roleId") === this.ROLE.ADMIN) {
					if (this.get("group.members").filterBy("roleId", this.ROLE.ADMIN).length > 1) {
						this.set("roleId", this.ROLE.MEMBER);
					}
				} else {
					this.set("roleId", this.ROLE.ADMIN);
				}
			}
		}
	});

});