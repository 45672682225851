define('sportly/tests/helpers/reset-store', ['ember'], function (Ember) {

    'use strict';

    Ember['default'].Test.registerAsyncHelper("resetStore", function (app, selector, context) {

        Ember['default'].$.ajax("testApi/resetStore");
        return wait();
    });

});