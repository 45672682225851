define('sportly/controllers/football-game/menu', ['exports', 'ember'], function (exports, Em) {

    'use strict';

    exports['default'] = Em['default'].ObjectController.extend({

        needs: ["footballGame"],

        showMenu: Em['default'].computed.and("iAmAMember", "isActive"),
        iAmAMember: Em['default'].computed.alias("controllers.footballGame.iAmAMember"),
        isActive: Em['default'].computed.alias("controllers.footballGame.isActive"),
        canInviteFriends: Em['default'].computed.alias("controllers.footballGame.canInviteFriends"),
        canEditGame: Em['default'].computed.alias("controllers.footballGame.canEditGame"),

        isSettingsVisible: (function () {
            if (!this.get("isActive")) {
                return false;
            }

            if (!this.get("canInviteFriends") && !this.get("canEditGame")) {
                return false;
            }

            return true;
        }).property("isActive", "canInviteFriends", "canEditGame")

    });

});