define('sportly/components/x-guest/join-form', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-guest--join-form"],
        errors: null,
        actions: {
            join: function join() {
                this.sendAction("join");
            }
        }
    });

});