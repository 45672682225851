define('sportly/views/dropdown', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].View.extend({

		isOpen: false,

		didInsertElement: function didInsertElement() {
			var view = this;

			this.$("> .trigger").click(function () {
				view.set("isOpen", true);
			});

			Em['default'].$(".ember-application .app").click(function (e) {
				if (e.target !== view.$("> .trigger").get(0)) {
					view.set("isOpen", false);
				}
			});
		}

	});

});