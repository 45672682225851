define('sportly/tests/acceptance/create-football-game-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Create a football game", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can create a football game", function (assert) {
        assert.expect(12);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/create");

        andThen(function () {
            assert.notEqual(find(".create form").length, 0, "Create football game form is shown");

            click(".form-group.venue .choose");
        });

        andThen(function () {
            assert.equal(currentPath(), "footballGame.create.chooseVenue", "Shows choose venue page");
            assert.notEqual(find(".choose-venue .results ul li.venue").length, 0, "Venue results shown");

            click(".choose-venue .results ul li.venue");
        });

        andThen(function () {
            assert.equal(currentPath(), "footballGame.create.index", "Shows create football game page after choosing venue");
            assert.notEqual(find(".create form").length, 0, "Create football game form is shown after venue");
            assert.equal(find(".create form .venue-name").text().trim(), "Local venue", "Chosen venue name is shown in venue field");

            fillIn("#home-team-input", "Barcelona");
            fillIn("#away-team-input", "Real Madrid");
            fillIn("#player-limit-input", 0);

            click(".create form .btn-submit");
        });

        andThen(function () {
            assert.equal(currentPath(), "footballGame.index", "Shows created football game page");
            assert.equal(find(".x-football-games--header .home-team").text().trim(), "Barcelona", "Home team name is shown");
            assert.equal(find(".x-football-games--header .away-team").text().trim(), "Real Madrid", "Away team name is shown");
            assert.equal(find(".x-football-games--properties .location .value").text().trim(), "Local venue", "Venue name is shown");
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
            assert.notEqual(find(".x-football-games--settings").length, 0, "Settings is shown");
        });
    });

    qunit.test("Can choose a different date", function (assert) {

        var defaultDate = null;

        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/create");

        andThen(function () {
            defaultDate = find("#date-input").val();
            click("#date-input");
        });

        andThen(function () {
            assert.notEqual(find(".picker--opened").length, 0, "Calendar is shown when date field is clicked");
            click(".picker__nav--next");
        });
        andThen(function () {
            click(".picker__day:not(.picker__day--disabled):last");
        });
        andThen(function () {
            assert.notEqual(find("#date-input").val(), "", "The selected date should not be empty");
            assert.notEqual(find("#date-input").val(), defaultDate, "The selected date should be different to the default date");
        });
    });

});