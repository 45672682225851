define('sportly/routes/group/create/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/member-role'], function (exports, AuthenticatedRouteMixin, Ember, GroupMemberRole) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.modelFor("group.create");
		},

		actions: {

			create: function create() {

				var group = this.get("currentModel"),
				    _this = this,
				    user = this.get("session.user");

				//As venue doesnt seem to clear by itself
				group.get("errors").clear();

				if (Ember['default'].isEmpty(group.get("name"))) {
					group.get("errors").add("name", "Name is required");
				} else if (group.get("name") < 5) {
					group.get("errors").add("name", "Name must be at least 5 characters");
				}
				if (Ember['default'].isEmpty(group.get("description"))) {
					group.get("errors").add("description", "Description is required");
				}
				if (Ember['default'].isEmpty(group.get("venue"))) {
					group.get("errors").add("venue", "Location is required");
				}

				if (!group.get("isValid")) {
					return;
				}
				group.save().then(function () {
					return Ember['default'].RSVP.all([user.get("groupMembers"), group.get("members")]);
				}).then(function (groupMemberRelationships) {
					var groupMember = _this.store.createRecord("groupMember", {
						user: user,
						group: group,
						roleId: GroupMemberRole['default'].ADMIN
					});
					groupMemberRelationships[0].pushObject(groupMember);
					groupMemberRelationships[1].pushObject(groupMember);
					return groupMember.save();
				}).then(function () {
					return _this.store.createRecord("story/group-create", {
						user: user,
						group: group
					}).save();
				}).then(function () {
					_this.transitionTo("group.createFootballGame", group);
				}, function (e) {
					var errors = e.responseJSON.errors;
					for (var attribute in e.responseJSON.errors) {
						if (errors.hasOwnProperty(attribute)) {
							group.get("errors").add(attribute, errors[attribute]);
						}
					}
				});
			},

			chooseVenue: function chooseVenue() {
				this.transitionTo("group.create.chooseVenue");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Create group");
				return true;
			}

		}
	});

});