define('sportly/routes/profile/fixtures', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			this._super(transition);
			return this.modelFor("profile").get("user.gameMembers").then(function (gameMembers) {
				return gameMembers.mapBy("user");
			});
		},

		model: function model() {
			var now = moment().format("X");

			return this.modelFor("profile").get("user.gameMembers").mapBy("footballGame").filter(function (footballGame) {
				return footballGame.get("timestamp") > now;
			});
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Fixtures");
				return true;
			}
		}

	});

});