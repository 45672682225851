define('sportly/controllers/group/fixtures', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({

		needs: ["group"],

		sortProperties: ["timestamp"],
		sortAscending: true,
		itemController: "game",

		group: Ember['default'].computed.alias("controllers.group"),

		hasFixtures: Ember['default'].computed.notEmpty("model"),

		isPlural: Ember['default'].computed.gt("model.length", 1),

		showCreateGame: Ember['default'].computed.and("group.isAdmin", "group.isActive")

	});

});