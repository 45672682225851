define('sportly/tests/unit/components/x-football-games/rsvps-test', ['ember-qunit', 'ember'], function (ember_qunit, Ember) {

    'use strict';

    ember_qunit.moduleForComponent("x-football-games/rsvps", {});

    ember_qunit.test("it renders", function (assert) {
        assert.expect(2);

        // creates the component instance
        var component = this.subject();
        assert.equal(component._state, "preRender");

        component.set("footballGame", Ember['default'].Object.create({
            id: 1,
            members: [Ember['default'].Object.create({
                id: 1,
                user: Ember['default'].Object.create({
                    id: 1,
                    firstName: "Zinedine",
                    lastName: "Zidane"
                })
            })]
        }));

        // appends the component to the page
        this.render();
        assert.equal(component._state, "inDOM");
    });

    // specify the other units that are required for this test
    // needs: ['component:foo', 'helper:bar']

});