define('sportly/components/x-stories/stories', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-stories--stories", "sessionCanPost"],
        source: null,
        timer: null,
        count: 20,
        perPage: 20,
        interval: 30000,
        sessionCanPost: true,

        sourceUnwrapped: (function () {
            if (!Ember['default'].isEmpty(this.get("source.content"))) {
                return this.get("source.content");
            }
            return this.get("source");
        }).property("source", "source.content"),

        stories: (function () {
            return this.get("sourceUnwrapped.stories").toArray().sort(function (a, b) {
                return b.get("updated") - a.get("updated");
            }).slice(0, this.get("count"));
        }).property("sourceUnwrapped.stories.@each", "sourceUnwrapped.stories.@each.updated", "count"),

        hasMore: (function () {
            return this.get("count") < this.get("sourceUnwrapped.stories.length");
        }).property("sourceUnwrapped.stories.length", "count"),

        didInsertElement: function didInsertElement() {
            var _this = this;
            this.set("timer", setInterval(function () {
                _this.get("sourceUnwrapped").reload();
            }, this.get("interval")));
            this.get("sourceUnwrapped").reload();
        },

        willDestroyElement: function willDestroyElement() {
            clearInterval(this.get("timer"));
        },

        actions: {
            showMore: function showMore() {
                this.incrementProperty("count", this.get("perPage"));
            }
        }
    });

});