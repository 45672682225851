define('sportly/controllers/invite-friends', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({

		invited: [],
		emailAddressesInput: null,

		multipleEmailAddresses: (function () {
			return this.get("emailAddresses.length") > 1;
		}).property("emailAddresses"),

		emailAddressesEmpty: (function () {
			return this.get("emailAddresses.length") === 0;
		}).property("emailAddresses"),

		emailAddresses: (function () {
			var emailAddresses = [];
			if (Ember['default'].isEmpty(this.get("emailAddressesInput"))) {
				return [];
			}
			emailAddresses = this.get("emailAddressesInput").match(/([\+a-zA-Z0-9'._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
			if (Ember['default'].isEmpty(emailAddresses)) {
				return [];
			}
			return emailAddresses.uniq();
		}).property("emailAddressesInput")

	});

});