define('sportly/helpers/str-nl2br', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.strNl2br = strNl2br;

    function strNl2br(input) {
        var breakTag = "<br />";

        return new Ember['default'].Handlebars.SafeString((input + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2"));
    }

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(strNl2br);

});