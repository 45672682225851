define('sportly/controllers/find/groups/results', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({

		needs: ["infobox/venue-groups"],

		venueGroupsInfobox: Em['default'].computed.alias("controllers.infobox/venue-groups"),
		marker: Em['default'].computed.alias("venueGroupsInfobox.marker"),

		venue: null,

		groups: (function () {
			var marker = this.get("marker");

			if (marker == null) {
				return this.get("content");
			}

			return this.get("content").filterBy("venue", marker.model);
		}).property("content.@each", "marker")

	});

});