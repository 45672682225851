define('sportly/components/x-notification', ['exports', 'ember', 'sportly/models/notifications/group-invite', 'sportly/models/notifications/football-game-invite'], function (exports, Ember, GroupInviteNotification, FootballGameInviteNotification) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-notification", "notification.unread:is-unread"],
        notification: null,

        componentType: (function () {
            var notification = this.get("notification");
            if (notification instanceof GroupInviteNotification['default']) {
                return "x-notifications/group-invite";
            }
            if (notification instanceof FootballGameInviteNotification['default']) {
                return "x-notifications/football-game-invite";
            }
        }).property("notification")

    });

});