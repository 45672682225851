define('sportly/routes/guest/join', ['exports', 'simple-auth/mixins/unauthenticated-route-mixin', 'ember'], function (exports, UnauthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(UnauthenticatedRouteMixin['default'], {

		actions: {

			join: function join() {
				var _this = this,
				    errors = this.get("controller.errors"),
				    firstName = this.get("controller.firstName"),
				    lastName = this.get("controller.lastName"),
				    email = this.get("controller.email"),
				    password = this.get("controller.password"),
				    appInvite = this.get("controller.appInvite"),
				    profile = null,
				    user = null;

				errors.clear();

				if (Ember['default'].isEmpty(firstName)) {
					errors.add("firstName", "First name is required");
				}
				if (Ember['default'].isEmpty(lastName)) {
					errors.add("lastName", "Last name is required");
				}
				if (Ember['default'].isEmpty(email) || email.length < 5) {
					errors.add("email", "Email is required");
				}
				if (Ember['default'].isEmpty(password) || password.length < 7) {
					errors.add("password", "Password is required and must be a minimum of 7 characters");
				}

				if (!errors.get("isEmpty")) {
					return;
				}

				profile = this.store.createRecord("profile", {
					firstName: firstName,
					lastName: lastName
				});

				user = this.store.createRecord("user", {
					email: email,
					password: password,
					profile: profile
				});

				if (!Ember['default'].isEmpty(appInvite)) {
					user.set("appInvite", appInvite);
				}

				user.save().then(function () {
					profile.set("user", user);
					return profile.save();
				}).then(function () {
					if (!Ember['default'].isEmpty(appInvite)) {
						return _this.store.createRecord("follower", {
							user: appInvite.get("sender"),
							followed: user
						}).save();
					}
				}).then(function () {
					return _this.get("session").authenticate("simple-auth-authenticator:token", {
						identification: email,
						password: password
					});
				}, function (e) {
					for (var attribute in e.responseJSON.errors) {
						if (e.responseJSON.errors.hasOwnProperty(attribute)) {
							errors.add(attribute, e.responseJSON.errors[attribute]);
						}
					}
				}).then(function () {
					return _this.store.createRecord("story/app-join", {
						user: user
					}).save();
				});
			},

			didTransition: function didTransition() {
				if (!this.get("controller.appInviteIsUsed")) {
					this.controllerFor("guest").set("navIsHidden", true);
				}
				return true;
			},

			willTransition: function willTransition() {
				this.controllerFor("guest").set("navIsHidden", false);
				return true;
			}
		}

	});

});