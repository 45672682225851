define('sportly/models/app-invite', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		sender: DS['default'].belongsTo("user", { async: true }),
		emailAddress: DS['default'].attr("string"),
		receiver: DS['default'].belongsTo("user", { async: true, inverse: "appInvite" }),
		timestamp: DS['default'].attr("number"),
		status: DS['default'].attr("number")
	});

});