define('sportly/tests/acceptance/football-game/football-game-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Football game", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Upcoming fixtures shown on dashboard", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/");

        andThen(function () {
            assert.equal(find(".x-profiles--upcoming-fixtures .x-football-games--fixture-summary").length, 3, "3 upcoming fixtures are shown");
        });
    });

    qunit.test("A football game can be shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/1");

        andThen(function () {
            assert.notEqual(find(".page.football-game").length, 0, "Football page container is shown");
        });
    });

    qunit.test("Football game header is correctly shown", function (assert) {
        assert.expect(6);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/1");

        andThen(function () {
            assert.notEqual(find(".x-football-games--header").length, 0, "Game header is shown");
            assert.equal(find(".x-football-games--header .home-team").text().trim(), "Barcelona", "Home team name is shown");
            assert.equal(find(".x-football-games--header .away-team").text().trim(), "Real Madrid", "Away team name is shown");
            assert.equal(find(".x-football-games--header .time").text().trim(), "18:00", "Time is shown");
            assert.equal(find(".x-football-games--header .day-of-week").text().trim(), "Monday", "Day of week is shown");
            assert.equal(find(".x-football-games--header .date").text().trim(), "3 Oct", "Date is shown");
        });
    });

    qunit.test("Can change RSVP from 'pending' to 'yes' for a game", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/1");

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
            click(".x-football-games--rsvp .btn-attend");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-playing").length, 0, "RSVP is playing");
            assert.equal(find(".x-football-games--rsvp .title").text().trim(), "You are playing", "'Playing' message is shown");
        });
    });

    qunit.test("Can change RSVP from 'pending' to 'no' for a game", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/1");

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
            click(".x-football-games--rsvp .btn-decline");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .has-declined").length, 0, "RSVP is not playing");
            assert.equal(find(".x-football-games--rsvp .title").text().trim(), "You have declined", "'Declined' message is shown");
        });
    });

    qunit.test("Can change RSVP from 'yes' to 'no' for a game", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/2");

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-playing").length, 0, "RSVP is playing");
            click(".x-football-games--rsvp .btn-change");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
            click(".x-football-games--rsvp .btn-decline");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .has-declined").length, 0, "RSVP is declined");
            assert.equal(find(".x-football-games--rsvp .title").text().trim(), "You have declined", "'Declined' message is shown");
        });
    });

    qunit.test("Can change RSVP from 'no' to 'yes' for a game", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/2");

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .has-declined").length, 0, "RSVP is declined");
            click(".x-football-games--rsvp .btn-change");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
            click(".x-football-games--rsvp .btn-attend");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-playing").length, 0, "RSVP is playing");
            assert.equal(find(".x-football-games--rsvp .title").text().trim(), "You are playing", "'Playing' message is shown");
        });
    });

    qunit.test("Can RSVP 'yes' for a game that is full and become a reserve", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/3");

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
            click(".x-football-games--rsvp .btn-attend");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-reserve").length, 0, "RSVP is reserve");
            assert.equal(find(".x-football-games--rsvp .title").text().trim(), "You are a reserve", "'Reserve' message is shown");
        });
    });

    qunit.test("Cannot RSVP for game in the past", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/4");

        andThen(function () {
            assert.equal(find(".x-football-games--rsvp").text(), "", "RSVP summary not shown");
            assert.equal(find(".x-football-games--settings .settings").length, 0, "Settings menu not shown");
        });
    });

    qunit.test("Cant join a football game that has a group", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 3);
        visit("/football/game/2");

        andThen(function () {
            assert.equal(find(".x-football-games--rsvp").text(), "", "RSVP summary not shown");
            assert.equal(find(".x-football-games--settings .settings").length, 0, "Settings menu not shown");
            assert.equal(find(".x-football-games--header .btn-join").length, 0, "Join button not shown");
        });
    });

    qunit.test("Can join a one-off football game", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/5");

        andThen(function () {
            assert.equal(find(".x-football-games--rsvp").text(), "", "RSVP summary not shown");
            assert.equal(find(".x-football-games--settings .settings").length, 0, "Settings menu not shown");
            assert.notEqual(find(".x-football-games--header .btn-join").length, 0, "Join button is shown");
            click(".x-football-games--header .btn-join");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
        });
    });

    qunit.test("Creator name shown on football game info page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/3/about");

        andThen(function () {
            assert.equal(find(".x-football-games--properties .creator .value").text(), "Lionel Messi", "Creator name is shown");
            click(".x-football-games--properties .creator");
        });

        andThen(function () {
            assert.equal(currentPath(), "profile.index", "Should be on profile page");
        });
    });

    qunit.test("Invitation with one sender shown correctly", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 7);
        visit("/football/game/5");

        andThen(function () {
            assert.notEqual(find(".invite").length, 0, "Invite is shown");
            assert.equal(find(".invite .primary-sender").text().trim(), "Zinedine Zidane", "Sender's name shown");
        });
    });

    qunit.test("Invitation with multiple senders shown correctly", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 8);
        visit("/football/game/5");

        andThen(function () {
            assert.notEqual(find(".invite").length, 0, "Invite is shown");
            assert.equal(find(".invite .primary-sender").text().trim(), "Lionel Messi", "Sender's name shown");
        });
    });

    qunit.test("Can join game using invitation", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 7);
        visit("/football/game/5");

        andThen(function () {
            click(".invite .btn-join");
        });

        andThen(function () {
            assert.notEqual(find(".x-football-games--rsvp .is-pending").length, 0, "RSVP is pending");
        });
    });

});