define('sportly/helpers/dynamic-component', ['exports', 'ember', 'ember-dynamic-component'], function (exports, Ember, ember_dynamic_component) {

  'use strict';

  var isHTMLBars = !!Ember['default'].HTMLBars;

  function htmlbarsHelper(properties, hash, options, env) {
    Ember['default'].assert("You can only pass attributes (such as name=value) not bare " + "values to {{dynamic-component}} '", properties.length === 0);

    hash._dynamicOptions = hash;

    return env.helpers.view.helperFunction.call(this, [ember_dynamic_component.DynamicComponentView], hash, options, env);
  }

  function handlebarsHelper(options) {
    Ember['default'].assert("You can only pass attributes (such as name=value) not bare " + "values to {{dynamic-component}} '", arguments.length < 2);

    // pass the options through to the resulting view
    // is there a valid type to use here?
    // this works but...
    options.hashTypes._dynamicOptions = "OBJECT";
    options.hash._dynamicOptions = options.hash;

    return Ember['default'].Handlebars.helpers.view.call(this, ember_dynamic_component.DynamicComponentView, options);
  }

  function makeHelper() {
    if (isHTMLBars) {
      return {
        isHTMLBars: true,
        helperFunction: htmlbarsHelper,
        preprocessArguments: function preprocessArguments() {}
      };
    } else {
      return handlebarsHelper;
    }
  }

  exports['default'] = makeHelper();

});