define('sportly/components/x-groups/group-summary', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-groups--group-summary"],
        group: null,

        fixtureCount: (function () {
            var now = moment().format("X");
            return this.get("group.games").filter(function (game) {
                if (Ember['default'].isEmpty(game) || game.get("isCancelled")) {
                    return false;
                }
                return game.get("timestamp") > now;
            }).get("length");
        }).property("group.games.@each.timestamp", "group.games.@each.isCancelled")

    });

});