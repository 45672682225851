define('sportly/components/x-stories/football-game-cancel-story', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-stories--football-game-cancel-story", "isLoading"],

        isLoading: Ember['default'].computed.not("isLoaded"),
        isLoaded: Ember['default'].computed.and("story.user.profile.isLoaded", "story.user.profile.photo.isLoaded", "story.footballGame.isLoaded"),

        contextIsFootballGame: (function () {
            return this.get("story.footballGame.content") === this.get("source");
        }).property("story.footballGame", "source")
    });

});