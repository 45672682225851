define('sportly/routes/football-game/member/ban', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp', 'sportly/enums/football-game/member-role'], function (exports, AuthenticatedRouteMixin, Ember, RSVP, MEMBER_ROLE) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		afterModel: function afterModel(gameMember) {
			var _this = this;

			if (gameMember.get("rsvpId") === RSVP['default'].BANNED) {
				return this.transitionTo("footballGame.member.index", gameMember);
			}

			return this.modelFor("footballGame").get("members").then(function (gameMembers) {
				return gameMembers.findBy("user.id", _this.get("session.user.id"));
			}).then(function (sessionGameMember) {
				if (sessionGameMember.get("roleId") !== MEMBER_ROLE['default'].ADMIN) {
					return _this.transitionTo("footballGame.member.index", gameMember);
				}
			});
		},

		setupController: function setupController(controller, footballGameMember) {
			controller.set("model", footballGameMember);
			footballGameMember.set("banReason", null);
		},

		actions: {
			ban: function ban() {
				var _this = this;
				this.set("currentModel.rsvpId", RSVP['default'].BANNED);
				this.get("currentModel").save().then(function () {
					_this.transitionTo("footballGame.member");
				});
			},

			cancel: function cancel() {
				this.transitionTo("footballGame.member");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Ban player");
				return true;
			}

		},

		deactivate: function deactivate() {
			if (this.get("currentModel.isDirty")) {
				this.get("currentModel").rollback();
			}
		}

	});

});