define('sportly/services/geolocation', ['exports', 'ember', 'sportly/config/environment'], function (exports, Ember, Config) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({

        geocoder: null,

        init: function init() {
            L.mapbox.accessToken = Config['default'].APP["mapbox-access-token"];
            this.set("geocoder", L.mapbox.geocoder("mapbox.places-v1"));
        },

        geolocate: function geolocate(query) {
            var _this = this;
            return new Ember['default'].RSVP.Promise(function (resolve) {
                _this.get("geocoder").query(query, function (err, data) {
                    resolve(data.latlng);
                });
            });
        }

    });

});