define('sportly/controllers/football-game/edit/index', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		prettyDate: (function (key, value) {
			if (arguments.length > 1) {
				this.set("localDate", moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"));
			}
			return moment(this.get("localDate")).format("DD/MM/YYYY");
		}).property("localDate"),

		playerLimitMax: 40,

		playerLimitMin: (function () {
			return (this.get("gameTypeId") + 1) * 2;
		}).property("gameTypeId"),

		timeOptions: (function () {
			var options = [];
			for (var idx = 0; idx <= 85500; idx += 900) {
				options[idx / 900] = {
					name: ("0" + Math.floor(idx / 3600)).slice(-2) + ":" + ("0" + idx % 3600 / 60).slice(-2),
					value: ("0" + Math.floor(idx / 3600)).slice(-2) + ":" + ("0" + idx % 3600 / 60).slice(-2)
				};
			}
			return options;
		}).property(),

		gameLengthOptions: (function () {
			var gameLengthOptions = [];
			for (var i = 2; i <= 90; i++) {
				gameLengthOptions.pushObject({
					name: i + " minutes",
					value: i * 60
				});
			}
			gameLengthOptions.pushObjects([{ name: "2 hours", value: 7200 }, { name: "3 hours", value: 10800 }, { name: "4 hours", value: 14400 }]);
			return gameLengthOptions;
		}).property(),

		gameTypeOptions: (function () {
			var gameTypeOptions = [];
			for (var i = 1; i < 11; i++) {
				gameTypeOptions.pushObject({
					name: i + 1 + " a-side",
					value: i
				});
			}
			return gameTypeOptions;
		}).property(),

		playerLimitOptions: (function () {
			var playerLimits = [{ name: "Unlimited", value: 0 }];
			for (var i = this.get("playerLimitMin"); i <= this.get("playerLimitMax"); i++) {
				playerLimits.pushObject({
					name: i + " players",
					value: i
				});
			}
			return playerLimits;
		}).property("playerLimitMin", "playerLimitMax")

	});

});