define('sportly/models/game-member', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		user: DS['default'].belongsTo("user", { async: true }),
		footballGame: DS['default'].belongsTo("football-game", { async: true }),
		roleId: DS['default'].attr("number"),
		rsvpId: DS['default'].attr("number"),
		banReason: DS['default'].attr("string"),
		timestamp: DS['default'].attr("number")
	});

});