define('sportly/routes/group/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		actions: {

			didPost: function didPost(body) {

				var _this = this,
				    user = this.get("session.user"),
				    group = this.get("currentModel");

				return this.store.createRecord("post/group", {
					user: user,
					group: group,
					body: body
				}).save().then(function (post) {
					return _this.store.createRecord("story/group-post", {
						user: user,
						group: group,
						post: post
					}).save();
				}).then(function (story) {
					group.get("stories").pushObject(story);
				});
			}
		}
	});

});