define('sportly/routes/profile', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model(params) {
			return this.store.find("profile", {
				url: params.profileUrl
			}).then(function (profiles) {
				return profiles.get("firstObject");
			});
		},

		afterModel: function afterModel(profile) {
			return profile.get("photo").then(function () {
				return profile.get("user");
			}).then(function () {
				return profile.get("user.groupMembers");
			}).then(function (groupMembers) {
				return Ember['default'].RSVP.all(groupMembers.mapBy("group"));
			}).then(function () {
				return profile.get("user.gameMembers");
			}).then(function (gameMembers) {
				return Ember['default'].RSVP.all(gameMembers.mapBy("footballGame"));
			}).then(function () {
				return profile.get("user.followers");
			}).then(function (followers) {
				return Ember['default'].RSVP.all(followers.mapBy("user"));
			}).then(function () {
				return profile.get("user.following");
			}).then(function (following) {
				return Ember['default'].RSVP.all(following.mapBy("user"));
			}).then(function () {
				return Ember['default'].RSVP.all(profile.get("user.following").mapBy("followed"));
			}).then(function () {
				return Ember['default'].RSVP.all(profile.get("user.followers").mapBy("followed"));
			});
		},

		serialize: function serialize(profile) {
			return { profileUrl: Ember['default'].get(profile, "url") };
		},

		actions: {
			follow: function follow() {
				var _this = this;
				return this.store.createRecord("follower", {
					user: this.get("session.user"),
					followed: this.get("currentModel.user")
				}).save().then(function (follower) {
					return _this.store.createRecord("story/user-follow", {
						user: follower.get("user"),
						followed: follower.get("followed")
					}).save();
				}).then(function (story) {
					_this.get("currentModel.stories").pushObject(story);
				});
			}
		}

	});

});