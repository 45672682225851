define('sportly/components/x-guest/invitation', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-guest--invitation"],
        invitation: null,
        senderProfile: Ember['default'].computed.alias("invitation.sender.profile")
    });

});