define('sportly/controllers/group/members', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].ArrayController.extend({
        needs: ["group"],

        canInviteFriends: Ember['default'].computed.alias("controllers.group.isActive"),

        isPlural: Ember['default'].computed.gt("model.length", 1)
    });

});