define('sportly/controllers/profile-card', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		name: (function () {
			return this.get("firstName") + " " + this.get("lastName");
		}).property("firstName", "lastName")

	});

});