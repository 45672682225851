define('sportly/routes/group/fixtures', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {

			return this.modelFor("group").get("games").then(function (games) {
				var now = moment().format("X");
				return games.filter(function (game) {
					return game.get("timestamp") > now;
				});
			});
		},

		afterModel: function afterModel(fixtures) {
			return Ember['default'].RSVP.all([fixtures.mapBy("homeTeam"), fixtures.mapBy("awayTeam"), fixtures.mapBy("members"), fixtures.mapBy("creator"), fixtures.mapBy("group")]);
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Fixtures");
				return true;
			}
		}

	});

});