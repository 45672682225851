define('sportly/tests/unit/helpers/profile-name-test', ['sportly/helpers/profile-name', 'qunit', 'ember'], function (profile_name, qunit, Ember) {

  'use strict';

  qunit.module("ProfileNameHelper");

  qunit.test("it works", function (assert) {
    var result = profile_name.profileName(Ember['default'].Object.create({
      firstName: "Lionel",
      lastName: "Messi"
    }));
    assert.ok(result === "Lionel Messi");
  });

});