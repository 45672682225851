define('sportly/components/x-profiles/followers', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-profiles--followers", "hasFollowers"],
        user: null,
        hasFollowers: Ember['default'].computed.notEmpty("followerUsers"),

        followerUsers: (function () {
            var followerUsers = this.get("user.followers").mapBy("user"),
                followingUsers = this.get("user.following").mapBy("followed");
            return followerUsers.filter(function (followerUser) {
                return !followingUsers.anyBy("id", followerUser.get("id"));
            });
        }).property("user.followers.@each.user", "user.following.@each.followed")

    });

});