define('sportly/components/x-stories/profile-post-story', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-stories--profile-post-story", "isLoading"],

        isLoading: Ember['default'].computed.not("isLoaded"),
        isLoaded: Ember['default'].computed.and("story.user.profile.isLoaded", "story.user.profile.photo.isLoaded", "story.post.isLoaded"),

        contextIsProfile: (function () {
            return this.get("story.targetUser.profile.content") === this.get("source");
        }).property("story.targetUser.profile.content", "source")
    });

});