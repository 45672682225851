define('sportly/controllers/group/admins', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].ArrayController.extend({
        needs: ["group"],

        isAdmin: Ember['default'].computed.alias("controllers.group.isAdmin"),
        isActive: Ember['default'].computed.alias("controllers.group.isActive"),

        canEditAdmins: Ember['default'].computed.and("isAdmin", "isActive"),

        isPlural: Ember['default'].computed.gt("model.length", 1)
    });

});