define('sportly/models/venue', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		name: DS['default'].attr("string"),
		description: DS['default'].attr("string"),
		latitude: DS['default'].attr("string"),
		longitude: DS['default'].attr("string"),
		timeZone: DS['default'].attr("string"),
		url: DS['default'].attr("string"),
		groups: DS['default'].hasMany("group", { async: true, inverse: "venue" }),
		footballGames: DS['default'].hasMany("football-game", { async: true })
	});

});