define('sportly/components/x-utils/selectable', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-utils--selectable", "isSelected"],
        target: null,
        isSelected: false,

        click: function click() {
            this.toggleProperty("isSelected");
            if (this.get("isSelected")) {
                this.sendAction("select", this.get("target"));
            } else {
                this.sendAction("deselect", this.get("target"));
            }
        }
    });

});