define('sportly/components/x-stories/user-follow-story', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-stories--user-follow-story", "isLoading"],

        isLoading: Ember['default'].computed.not("isLoaded"),
        isLoaded: Ember['default'].computed.and("story.user.profile.isLoaded", "story.user.profile.photo.isLoaded", "story.followed.profile.isLoaded", "story.followed.profile.photo.isLoaded") });

});