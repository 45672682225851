define('sportly/controllers/notification-football-game-invite', ['exports', 'ember', 'sportly/controllers/notification'], function (exports, Em, Notification) {

	'use strict';

	exports['default'] = Notification['default'].extend({

		footballGameTime: (function () {
			if (Em['default'].isEmpty(this.get("footballGameInvite.footballGame.localTime"))) {
				return "";
			}
			return this.get("footballGameInvite.footballGame.localTime").substr(0, 5);
		}).property("footballGameInvite.footballGame.localTime"),

		footballGameDayOfWeek: (function () {
			if (Em['default'].isEmpty(this.get("footballGameInvite.footballGame.localDate"))) {
				return "";
			}
			return moment(this.get("footballGameInvite.footballGame.localDate")).format("dddd");
		}).property("footballGameInvite.footballGame.localDate"),

		footballGameDay: (function () {
			if (Em['default'].isEmpty(this.get("footballGameInvite.footballGame.localDate"))) {
				return "";
			}
			return moment(this.get("footballGameInvite.footballGame.localDate")).format("d");
		}).property("footballGameInvite.footballGame.localDate"),

		footballGameDayOrdinal: (function () {
			if (Em['default'].isEmpty(this.get("footballGameInvite.footballGame.localDate"))) {
				return "";
			}
			return moment(this.get("footballGameInvite.footballGame.localDate")).format("do");
		}).property("footballGameInvite.footballGame.localDate"),

		footballGameMonth: (function () {
			if (Em['default'].isEmpty(this.get("footballGameInvite.footballGame.localDate"))) {
				return "";
			}
			return moment(this.get("footballGameInvite.footballGame.localDate")).format("MMMM");
		}).property("footballGameInvite.footballGame.localDate"),

		footballGameYear: (function () {
			if (Em['default'].isEmpty(this.get("footballGameInvite.footballGame.localDate"))) {
				return "";
			}
			return moment(this.get("footballGameInvite.footballGame.localDate")).format("YYYY");
		}).property("footballGameInvite.footballGame.localDate")

	});

});