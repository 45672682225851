define('sportly/controllers/group/edit-admins', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({

		needs: ["group"],
		itemController: "group/member"

	});

});