define('sportly/tests/acceptance/welcome/finished-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Welcome - finished", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Pending group invite is shown", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 6);
        visit("/welcome/finished");

        andThen(function () {
            assert.equal(find(".invite").length, 1, "One invite is shown");
            assert.equal(find(".invite .name").text().trim(), "Zinedine Zidane", "Sender's name is shown");
            assert.equal(find(".invite .btn-view").length, 1, "View group button is shown");
        });
    });

    qunit.test("Accepted group invite is not shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 9);
        visit("/welcome/finished");

        andThen(function () {
            assert.equal(find(".invite").length, 0, "No invite is shown");
        });
    });

});