define('sportly/views/date', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].TextField.extend({
		tagName: "input",
		didInsertElement: function didInsertElement() {
			this.$().pickadate({
				format: "dd/mm/yyyy",
				//formatSubmit: 'yyyy-mm-dd',
				min: moment()
			});
		}
	});

});