define('sportly/controllers/game', ['exports', 'ember', 'sportly/enums/football-game/rsvp', 'sportly/enums/football-game/member-role', 'sportly/enums/group/member-role'], function (exports, Em, RSVP, GameMemberRole, GroupMemberRole) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		needs: ["group"],

		date: (function (key, value) {
			if (arguments.length > 1) {
				this.set("localDate", moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"));
			}
			return moment(this.get("localDate")).format("DD/MM/YYYY");
		}).property("content.localDate"),

		time: (function (key, value) {
			if (arguments.length === 1) {
				if (Em['default'].isEmpty(this.get("content.localTime"))) {
					return "";
				}
				return this.get("content.localTime").substr(0, 5);
			} else {
				this.set("content.localTime", value + ":00");
				return value;
			}
		}).property("content.localTime"),

		dayOfWeek: (function () {
			if (Em['default'].isEmpty(this.get("localDate"))) {
				return "";
			}
			return moment(this.get("localDate")).format("dddd");
		}).property("localDate"),

		day: (function () {
			if (Em['default'].isEmpty(this.get("localDate"))) {
				return "";
			}
			return moment(this.get("localDate")).format("DD");
		}).property("localDate"),

		dayOrdinal: (function () {
			if (Em['default'].isEmpty(this.get("localDate"))) {
				return "";
			}
			return moment(this.get("localDate")).format("Do");
		}).property("localDate"),

		month: (function () {
			if (Em['default'].isEmpty(this.get("content.localDate"))) {
				return "";
			}
			return moment(this.get("localDate")).format("MMMM");
		}).property("content.localDate"),

		year: (function () {
			if (Em['default'].isEmpty(this.get("content.localDate"))) {
				return "";
			}
			return moment(this.get("localDate")).format("YYYY");
		}).property("content.localDate"),

		spaces: (function () {
			return Math.max(0, this.get("playerLimit") - this.get("attending.length"));
		}).property("playerLimit", "attending.length"),

		allowsUnlimitedPlayers: (function () {
			return this.get("playerLimit") === 0;
		}).property("playerLimit"),

		isFull: (function () {
			if (this.get("playerLimit") === 0) {
				return false;
			}
			return this.get("attending.length") >= this.get("playerLimit");
		}).property("attending.length", "playerLimit"),

		isOverHalfFull: (function () {
			if (this.get("playerLimit") === 0) {
				return false;
			}
			return this.get("attending.length") >= this.get("playerLimit") / 2;
		}).property("playerLimit", "members.@each.attending"),

		gameLengthMinutes: (function () {
			return this.get("gameLength") / 60;
		}).property("gameLength"),

		gameType: (function () {
			return this.get("gameTypeId") + 1;
		}).property("gameTypeId"),

		notCancelled: Em['default'].computed.not("isCancelled"),
		notFinished: Em['default'].computed.not("isFinished"),
		isActive: Em['default'].computed.and("notCancelled", "notFinished"),

		playing: Em['default'].computed.filterBy("members", "rsvpId", RSVP['default'].PLAYING),
		reserves: Em['default'].computed.filterBy("members", "rsvpId", RSVP['default'].RESERVE),
		pending: Em['default'].computed.filterBy("members", "rsvpId", RSVP['default'].PENDING),
		declined: Em['default'].computed.filterBy("members", "rsvpId", RSVP['default'].DECLINED),
		banned: Em['default'].computed.filterBy("members", "rsvpId", RSVP['default'].BANNED),

		notAttending: Em['default'].computed.filter("members", function (member) {
			return member.get("rsvpId") === RSVP['default'].DECLINED || member.get("rsvpId") === RSVP['default'].BANNED;
		}),

		attending: (function () {
			return this.get("members").filter(function (member) {
				return member.get("rsvpId") === RSVP['default'].PLAYING || member.get("rsvpId") === RSVP['default'].RESERVE;
			});
		}).property("members.@each.rsvpId"),

		memberUsers: Em['default'].computed.mapBy("members", "user"),

		sessionMember: (function () {
			var userId = this.get("session.user.id");
			return this.get("members").find(function (member) {
				return member.get("user.id") === userId;
			});
		}).property("memberUsers.@each.user.id", "members.@each.user.id", "session.user.id"),

		myMember: (function () {
			var userId = this.get("session.user.id");
			return this.get("members").find(function (member) {
				return member.get("user.id") === userId;
			});
		}).property("memberUsers.@each.user.id", "members.@each.user.id", "session.user.id"),

		iHaveAdminRole: Em['default'].computed.equal("myMember.roleId", GameMemberRole['default'].ADMIN),
		iAmAdmin: Em['default'].computed.and("iAmAMember", "iHaveAdminRole"),
		iAmAMember: Em['default'].computed.notEmpty("myMember"),
		iAmNotAMember: Em['default'].computed.not("iAmAMember"),

		iCanRSVP: Em['default'].computed.and("isActive", "iAmAMember", "iAmNotBanned"),

		iAmAttending: Em['default'].computed.or("iAmPlaying", "iAmAReserve"),
		iAmPlaying: Em['default'].computed.equal("myMember.rsvpId", RSVP['default'].PLAYING),
		iAmAReserve: Em['default'].computed.equal("myMember.rsvpId", RSVP['default'].RESERVE),
		iAmPending: Em['default'].computed.equal("myMember.rsvpId", RSVP['default'].PENDING),
		iHaveDeclined: Em['default'].computed.equal("myMember.rsvpId", RSVP['default'].DECLINED),
		iAmBanned: Em['default'].computed.equal("myMember.rsvpId", RSVP['default'].BANNED),
		iAmNotBanned: Em['default'].computed.not("iAmBanned"),

		isGroupGame: Em['default'].computed.notEmpty("group.content"),
		isOneOffGame: Em['default'].computed.not("isGroupGame"),

		isCreator: (function () {
			return this.get("session.user.id") === this.get("creator.id");
		}).property("session.user.id", "creator.id"),

		iCanJoin: Em['default'].computed.and("isOneOffGame", "isActive", "iAmNotAMember"),

		canInviteFriends: Em['default'].computed.and("iAmAMember", "isOneOffGame", "isActive"),

		isAdmin: (function () {
			if (this.get("isGroupGame")) {
				var userId = this.get("session.user.id");
				return this.get("group.members").any(function (groupMember) {
					return groupMember.get("user.id") === userId && groupMember.get("roleId") === GroupMemberRole['default'].ADMIN;
				});
			} else {
				return this.get("isCreator");
			}
		}).property("session.user.id", "group.members.@each.user.id", "group.members.@each.roleId"),

		canEditGame: (function () {
			return this.get("isActive") && this.get("isAdmin");
		}).property("isActive", "isAdmin"),

		myPendingInvites: (function () {
			if (this.get("iAmAMember")) {
				return [];
			}

			return this.get("pendingInvites").filter(function (invite) {
				return invite.get("status") === 1 && invite.get("receiver.id") === this.get("session.user.id");
			}, this);
		}).property("session.user.id", "pendingInvites.@each.receiver", "pendingInvites.@each.status", "iAmAMember"),

		canShowInvites: Em['default'].computed.and("iAmNotAMember", "isActive", "hasInvites"),
		hasInvites: Em['default'].computed.gt("myPendingInvites.length", 0),
		hasMultipleInvites: Em['default'].computed.gt("myPendingInvites.length", 1),

		inviteCountMinusOne: (function () {
			return this.get("myPendingInvites.length") - 1;
		}).property("myPendingInvites.length")

	});

});