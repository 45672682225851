define('sportly/routes/group/settings', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

        afterModel: function afterModel(group) {
            if (group.get("status") !== GroupStatus['default'].ACTIVE) {
                return this.transitionTo("group.index");
            }
        },

        actions: {
            didTransition: function didTransition() {
                this.controllerFor("application").set("pageTitle", "Settings");
                return true;
            }
        }
    });

});