define('sportly/controllers/group/index', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ObjectController.extend({
		needs: ["group"],
		newPost: null,

		isAdmin: Ember['default'].computed.alias("controllers.group.isAdmin"),
		isDeleted: Ember['default'].computed.alias("controllers.group.isDeleted"),
		canShowInvites: Ember['default'].computed.alias("controllers.group.canShowInvites"),

		sessionIsMember: Ember['default'].computed.notEmpty("sessionMember"),
		notDeleted: Ember['default'].computed.not("isDeleted"),

		sessionMember: (function () {
			var userId = this.get("session.user.id");
			return this.get("members").find(function (member) {
				return member.get("user.id") === userId;
			});
		}).property("members.@each.user.id", "session.user.id"),

		sessionCanPost: Ember['default'].computed.and("sessionIsMember", "notDeleted")

	});

});