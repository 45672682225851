define('sportly/controllers/notifications', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({
		sortProperties: ["timestamp"],
		sortAscending: false,
		itemController: "notification"
	});

});