define('sportly/routes/welcome/finished', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group-invite/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupInviteStatus) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.get("session.user.groupInvites").then(function (invites) {
                return invites.filterBy("status", GroupInviteStatus['default'].PENDING);
            });
        },

        afterModel: function afterModel(invites) {
            return Ember['default'].RSVP.all(invites.toArray()).then(function (invites) {
                return Ember['default'].RSVP.all(invites.mapBy("group"));
            }).then(function () {
                return Ember['default'].RSVP.all(invites.mapBy("sender"));
            }).then(function (senders) {
                return Ember['default'].RSVP.all(senders.mapBy("profile"));
            }).then(function (profiles) {
                return Ember['default'].RSVP.all(profiles.mapBy("photo"));
            });
        }

    });

});