define('sportly/components/x-notifications/football-game-invite', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-notifications-football-game-invite"],
        notification: null,
        footballGame: Ember['default'].computed.alias("notification.footballGameInvite.footballGame"),
        sender: Ember['default'].computed.alias("notification.footballGameInvite.sender")
    });

});