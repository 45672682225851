define('sportly/routes/football-game/edit', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		//TODO:
		//Is user an admin
		//Is the group valid for editing (in the future)

		model: function model() {
			return this.modelFor("footballGame");
		},

		actions: {
			save: function save() {

				var _this = this,
				    footballGame = this.get("currentModel");

				Ember['default'].RSVP.all([footballGame.get("homeTeam").save(), footballGame.get("awayTeam").save(), footballGame.save()]).then(function () {
					_this.transitionTo("footballGame.index");
				});
			},

			cancel: function cancel() {
				this.transitionTo("footballGame.settings");
			}

		},

		deactivate: function deactivate() {

			var footballGame = this.get("currentModel");
			if (footballGame.get("isDirty")) {
				footballGame.rollback();
			}
			if (footballGame.get("homeTeam.isDirty")) {
				footballGame.get("homeTeam").rollback();
			}
			if (footballGame.get("awayTeam.isDirty")) {
				footballGame.get("awayTeam").rollback();
			}
		}

	});

});