define('sportly/components/x-groups/groups-summary', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-groups--groups-summary"],
        user: null,
        showActions: false,

        hasGroups: Ember['default'].computed.notEmpty("groups"),

        groups: (function () {
            return this.get("user.groupMembers").mapBy("group").slice(0, 5);
        }).property("user.groupMembers.@each.group")

    });

});