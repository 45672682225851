define('sportly/routes/football-game/create/add-venue', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.store.createRecord("venue");
		},

		setupController: function setupController(controller, venue) {
			controller.set("content", venue);
			Ember['default'].addListener(this.controllerFor("map"), "didLoadMap", this, function () {
				this.addMarker(venue);
				this.controllerFor("map").get("map").addEventListener("click", function (e) {
					this.get("controller.marker").setLatLng(e.latlng);
				}, this);
			}, true);
		},

		addMarker: function addMarker() {
			var map = this.controllerFor("map").get("map"),
			    marker = window.L.marker(map.getCenter(), {
				icon: window.L.mapbox.marker.icon({
					"marker-size": "large",
					"marker-symbol": "hospital",
					"marker-color": "51a351"
				}),
				draggable: true,
				zIndexOffset: 9999
			});
			marker.addTo(map);
			this.set("controller.marker", marker);
		},

		actions: {

			save: function save() {

				var _this = this,
				    venue = this.get("currentModel");

				if (Ember['default'].isEmpty(venue.get("name"))) {
					venue.get("errors").add("name", "Name is required");
				}
				if (Ember['default'].isEmpty(venue.get("description"))) {
					venue.get("errors").add("description", "Description is required");
				}
				if (!venue.get("isValid")) {
					return;
				}

				venue.set("latitude", this.get("controller.marker").getLatLng().lat);
				venue.set("longitude", this.get("controller.marker").getLatLng().lng);
				venue.save().then(function (venue) {
					_this.controllerFor("footballGame.create").set("venue", venue);
					_this.transitionTo("footballGame.create.index");
				}, function (e) {
					var errors = e.responseJSON.errors;
					for (var attribute in e.responseJSON.errors) {
						if (errors.hasOwnProperty(attribute)) {
							venue.get("errors").add(attribute, errors[attribute]);
						}
					}
				});
			},

			cancel: function cancel() {
				this.transitionTo("footballGame.create.chooseVenue");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Add venue");
				return true;
			}

		},

		deactivate: function deactivate() {
			if (this.get("currentModel").get("isNew")) {
				this.get("currentModel").deleteRecord();
			}
		}
	});

});