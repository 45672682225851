define('sportly/tests/acceptance/group/invite-friends-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - invite friends", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can't access 'invite friends' if group is deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group/invite-friends");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".edit-admins article").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Selectable friend is shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/invite-friends");

        andThen(function () {
            assert.equal(find(".x-utils--selectable.friend").length, 1, "One friend is selectable");
        });
    });

    qunit.test("Selectable friends' names are shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/invite-friends");

        andThen(function () {
            assert.equal(find(".x-utils--selectable.friend .name").text().trim(), "Ronnie Ronaldo", "Friends name is shown");
        });
    });

    qunit.test("Can invite friends to group", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/invite-friends");

        andThen(function () {
            click(".invite-friends li:first-child .x-utils--selectable.friend");
            click(".invite-friends .btn-submit");
        });

        andThen(function () {
            assert.notEqual(find(".invited-friends.is-showing").length, 0, "Invited friends message is showing");
            assert.equal(find(".invited-friends.is-showing").text().trim(), "Invited 1 friend", "Invited friends message is correct");
            assert.equal(find(".invite-friends .x-utils--selectable.friend").length, 0, "Friends list now empty");
        });
    });

});