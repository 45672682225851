define('sportly/routes/group/delete', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		afterModel: function afterModel(group) {
			if (group.get("status") !== GroupStatus['default'].ACTIVE) {
				return this.transitionTo("group.index");
			}

			return group.get("games").then(function (games) {
				return games.mapProperty("members");
			});
		},

		setupController: function setupController(controller, model) {
			controller.set("model", model);
			if (!this.controllerFor("group").get("isAdmin")) {
				this.transitionTo("group.index", this.modelFor("group"));
			}
		},

		actions: {
			deleteGroup: function deleteGroup() {

				var route = this,
				    group = this.get("currentModel"),
				    games = this.controllerFor("group").get("fixtures");

				if (games.get("length") > 0) {
					games.forEach(function (game) {
						game.set("isCancelled", true);
					});
					games.invoke("save");
					Ember['default'].RSVP.all(games).then(function () {
						group.set("status", 2);
						return group.save();
					}).then(function () {
						route.transitionTo("group", group);
					});
					return;
				}

				group.set("status", 2);
				group.save().then(function () {
					route.transitionTo("group", group);
				});
			},

			cancel: function cancel() {
				this.transitionTo("group.index");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Delete group");
				return true;
			}
		}

	});

});