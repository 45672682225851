define('sportly/controllers/application', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		hideGlobalNav: false,
		hasTransitionedAtLeastOnce: false,
		isTransitioningBack: false,

		isSideMenuVisible: false,
		isLoading: false,
		pageTitle: null,
		globalNavTheme: null,

		currentPathChanged: (function () {

			// window.location gets updated later in the current run loop, so we will
			// wait until the next run loop to inspect its value and make the call
			// to track the page view
			Em['default'].run.next(function () {
				var page;
				if (typeof window.ga !== "undefined") {
					// Assume that if there is a hash component to the url then we are using
					// the hash location strategy. Otherwise, we'll assume the history
					// strategy.
					page = window.location.hash.length > 0 ? window.location.hash.substring(1) : window.location.pathname;

					window.ga("send", "pageview", page);
				}
			});
		}).observes("currentPath")
	});

});