define('sportly/enums/app-invite/status', ['exports'], function (exports) {

    'use strict';

    exports['default'] = Object({
        PENDING: 1,
        REJECTED: 2,
        ACCEPTED: 3
    });

});