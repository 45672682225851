define('sportly/tests/helpers/start-app', ['exports', 'ember', 'sportly/app', 'sportly/router', 'sportly/config/environment', 'simple-auth-testing/test-helpers', 'sportly/tests/helpers/reset-store'], function (exports, Ember, Application, Router, config) {

  'use strict';



  exports['default'] = startApp;
  function startApp(attrs) {
    var application;

    var attributes = Ember['default'].merge({}, config['default'].APP);
    attributes = Ember['default'].merge(attributes, attrs); // use defaults, but you can override;

    Ember['default'].run(function () {
      application = Application['default'].create(attributes);
      application.setupForTesting();
      application.injectTestHelpers();
    });

    return application;
  }

});