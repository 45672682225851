define('sportly/routes/invite-friends', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		setupController: function setupController(controller) {
			controller.set("invited", []);
		},

		actions: {

			inviteFriends: function inviteFriends() {

				var route = this,
				    promises = [],
				    emailAddresses = this.get("controller.emailAddresses");

				if (Ember['default'].isEmpty(emailAddresses)) {
					return false;
				}

				emailAddresses.forEach(function (emailAddress) {
					promises.pushObject(route.store.createRecord("appInvite", {
						sender: route.get("session.user"),
						emailAddress: emailAddress
					}));
				});

				Ember['default'].RSVP.all(promises.invoke("save")).then(function () {
					route.set("controller.emailAddressesInput", null);
					route.set("controller.invited", emailAddresses);
				}, function () {
					route.set("controller.emailAddressesInput", null);
					route.set("controller.invited", emailAddresses);
				});
			},

			cancel: function cancel() {
				this.set("controller.emailAddressesInput", null);
				this.set("controller.invited", []);
				this.transitionTo("dashboard");
			},
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Invite friends");
				return true;
			}
		}
	});

});