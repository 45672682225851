define('sportly/initializers/geolocation-service', ['exports'], function (exports) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    application.inject("route", "geolocationService", "service:geolocation");
  }

  exports['default'] = {
    name: "geolocation-service",
    initialize: initialize
  };

});