define('sportly/tests/acceptance/profile/followers-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Profile - followers", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("One follower shown on profile/followers page", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/zidane/followers");

        andThen(function () {
            assert.equal(find(".followers li").length, 1, "One follower found");
        });
    });

    qunit.test("Followers name shown on profile/followers page", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/zidane/followers");

        andThen(function () {
            assert.equal(find(".followers li:first-child .name").text(), "Wayne Rooney", "Follower name is shown");
        });
    });

});