define('sportly/components/x-global/nav', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-global--nav", "theme", "isShiftedOut"],
        user: null,
        profile: Ember['default'].computed.alias("user.profile"),
        isLoading: false,
        isShiftedOut: false,
        title: null,
        theme: null,

        hasPageTitle: Ember['default'].computed.notEmpty("title"),

        unreadNotificationsCount: Ember['default'].computed.alias("unreadNotifications.length"),
        hasUnreadNotifications: Ember['default'].computed.gt("unreadNotifications.length", 0),
        unreadNotifications: Ember['default'].computed.filterBy("user.notifications", "unread", 1),

        actions: {
            toggleSideMenu: function toggleSideMenu() {
                this.sendAction("toggleSideMenu");
            },

            invalidateSession: function invalidateSession() {
                this.sendAction("invalidateSession");
            }
        }
    });

});