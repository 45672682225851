define('sportly/components/x-posts/composer', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-posts--composer", "isActive"],
        isActive: false,
        body: null,

        isPostDisabled: Ember['default'].computed.empty("body"),

        actions: {
            post: function post() {
                this.sendAction("didPost", this.get("body"));
                this.set("body", null);
                this.set("isActive", false);
            },

            cancel: function cancel() {
                this.set("body", null);
                this.set("isActive", false);
            }
        },

        keyUp: function keyUp(e) {
            if (e.keyCode === 27) {
                this.$(".body-input").blur();
            }
        },

        click: function click(e) {
            if (this.$(e.target).is(".body-input")) {
                this.set("isActive", true);
            }
        },

        didInsertElement: function didInsertElement() {

            Ember['default'].run.scheduleOnce("afterRender", this, function () {
                this.$(".body-input").autosize();
            });
        },

        activeChanged: (function () {
            Ember['default'].run.scheduleOnce("afterRender", this, function () {
                this.$(".body-input").trigger("autosize.resize");
            });
        }).observes("isActive")

    });

});