define('sportly/tests/acceptance/group/group-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Groups summary shown on dashboard", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/");

        andThen(function () {
            assert.equal(find(".x-groups--groups-summary .x-groups--group-summary").length, 5, "5 random games are shown");
        });
    });

    qunit.test("A group can be shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1");

        andThen(function () {
            assert.notEqual(find(".page.group").length, 0, "Group page container is shown");
        });
    });

    qunit.test("Group header is correctly shown", function (assert) {
        assert.expect(2);
        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1");

        andThen(function () {
            assert.notEqual(find(".x-groups--header").length, 0, "Group header is shown");
            assert.equal(find(".x-groups--header .group-name").text().trim(), "Test group 1", "Group name is shown");
        });
    });

    qunit.test("Admin options are correctly shown to an admin on group summary page", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1");

        andThen(function () {
            assert.notEqual(find(".x-groups--header .is-admin").length, 0, "Header admin label is shown");
            assert.notEqual(find(".x-groups--upcoming-fixtures .btn-create").length, 0, "Fixtures summary contains create game button when no fixtures");
            visit("/group/test-group-1/settings");
        });
        andThen(function () {
            assert.notEqual(find(".settings .edit-admins").length, 0, "'Edit admins' menu option is shown");
        });
    });

    qunit.test("Admin options are not shown to a member on group summary page", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-1");

        andThen(function () {
            assert.equal(find(".x-groups--header .is-admin").length, 0, "Header admin label is not shown");
            assert.notEqual(find(".x-groups--header .is-member").length, 0, "Header member label is shown");
            assert.equal(find(".fixtures-summary .btn-create-game").length, 0, "Fixtures summary contains no create game button when no fixtures");
            visit("/group/test-group-1/settings");
        });

        andThen(function () {
            assert.equal(find(".settings .edit-admins").length, 0, "'Edit admins' menu option is not shown");
        });
    });

    qunit.test("Can join group with no games", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 3);
        visit("/group/test-group-1");

        andThen(function () {
            assert.notEqual(find(".x-groups--header .btn-join").length, 0, "Join button is shown");
            click(".x-groups--header .btn-join");
        });

        andThen(function () {
            assert.notEqual(find(".x-groups--header .is-member").length, 0, "Header is member label is shown");
            assert.equal(find(".x-groups--header .btn-join").length, 0, "Join button is no longer shown");
        });
    });

    qunit.test("Can join group with games", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 7);
        visit("/group/lion");

        andThen(function () {
            assert.notEqual(find(".x-groups--header .btn-join").length, 0, "Join button is shown");
            click(".x-groups--header .btn-join");
        });

        andThen(function () {
            assert.notEqual(find(".x-groups--header .is-member").length, 0, "Header is member label is shown");
            assert.equal(find(".x-groups--header .btn-join").length, 0, "Join button is no longer shown");
        });
    });

    qunit.test("Deleted group is shown correctly", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group");

        andThen(function () {
            assert.notEqual(find(".x-groups--header .is-deleted").length, 0, "'Is deleted' label is shown on header");
            //assert.equal(find('.group-menu .settings').length, 0, "Group menu settings option is not shown");
        });
    });

    qunit.test("Group invitation shown on group page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 6);
        visit("/group/lion");

        andThen(function () {
            assert.notEqual(find(".invite").length, 0, "Invite is shown");
            assert.equal(find(".invite .primary-sender").text().trim(), "Zinedine Zidane", "Sender's name shown");
        });
    });

    qunit.test("Can accept group invitation", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 6);
        visit("/group/lion");

        andThen(function () {
            click(".invite .btn-join");
        });

        andThen(function () {
            assert.notEqual(find(".x-groups--header .is-member").length, 0, "Header is member label is shown");
            assert.equal(find(".x-groups--header .btn-join").length, 0, "Join button is no longer shown");
        });
    });

});