define('sportly/initializers/mixpanel', ['exports', 'sportly/mixin/tracking_mixin'], function (exports, MixpanelMixin) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container) {
    var router = container.lookup("router:main");
    router.on("didTransition", function () {
      this.trackPageView(this.get("url"));
    });
  }

  exports['default'] = {
    name: "mixpanel",
    initialize: initialize
  };

});