define('sportly/routes/group/invite-friends-by-email', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		afterModel: function afterModel() {
			if (this.modelFor("group").get("status") === GroupStatus['default'].DELETED) {
				return this.transitionTo("group.index");
			}
		},

		setupController: function setupController(controller) {
			controller.set("invited", []);
		},

		actions: {

			sendInvitations: function sendInvitations() {

				var _this = this,
				    group = this.modelFor("group"),
				    sender = this.get("session.user"),
				    emailAddresses = this.get("controller.emailAddresses");

				if (Ember['default'].isEmpty(emailAddresses)) {
					return false;
				}

				this.store.find("user", { emailAddresses: emailAddresses }).then(function (users) {
					var userEmailAddresses = users.mapBy("email");
					return emailAddresses.filter(function (emailAddress) {
						return !userEmailAddresses.contains(emailAddress);
					});
				}).then(function (newEmailAddresses) {
					var appInvites = [];
					newEmailAddresses.forEach(function (emailAddress) {
						appInvites.pushObject(_this.store.createRecord("appInvite", {
							sender: sender,
							emailAddress: emailAddress
						}));
					});
					return Ember['default'].RSVP.all(appInvites.invoke("save"));
				}).then(function () {
					return _this.store.find("user", { emailAddresses: emailAddresses });
				}).then(function (receivers) {
					return Ember['default'].RSVP.all(receivers.map(function (receiver) {
						return _this.store.createRecord("groupInvite", {
							group: group,
							sender: sender,
							receiver: receiver,
							status: 1
						}).save();
					}));
				}).then(function () {
					_this.set("controller.emailAddressesInput", null);
					_this.set("controller.invited", emailAddresses);
				});
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Invite by email");
				return true;
			}
		}
	});

});