define('sportly/tests/unit/helpers/str-nl2br-test', ['sportly/helpers/str-nl2br', 'qunit'], function (str_nl2br, qunit) {

  'use strict';

  qunit.module("StrNl2brHelper");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    var result = str_nl2br.strNl2br(42);
    assert.ok(result);
  });

});