define('sportly/components/x-football-games/rsvp', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, Ember, RSVP) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-football-games--rsvp"],
        footballGame: null,

        isPending: Ember['default'].computed.equal("sessionMember.rsvpId", RSVP['default'].PENDING),
        isPlaying: Ember['default'].computed.equal("sessionMember.rsvpId", RSVP['default'].PLAYING),
        hasDeclined: Ember['default'].computed.equal("sessionMember.rsvpId", RSVP['default'].DECLINED),
        isReserve: Ember['default'].computed.equal("sessionMember.rsvpId", RSVP['default'].RESERVE),
        isBanned: Ember['default'].computed.equal("sessionMember.rsvpId", RSVP['default'].BANNED),

        notCancelled: Ember['default'].computed.not("footballGame.isCancelled"),
        notFinished: Ember['default'].computed.not("footballGame.isFinished"),
        isActive: Ember['default'].computed.and("notCancelled", "notFinished"),
        showRSVP: Ember['default'].computed.and("sessionIsMember", "isActive"),

        sessionIsMember: Ember['default'].computed.notEmpty("sessionMember"),

        sessionMember: (function () {
            var userId = this.get("session.user.id");
            return this.get("footballGame.members").find(function (member) {
                return member.get("user.id") === userId;
            });
        }).property("footballGame.members.@each.user.id", "session.user.id"),

        actions: {
            attend: function attend() {
                this.sendAction("attend");
            },

            decline: function decline() {
                this.sendAction("decline");
            },

            changeRSVP: function changeRSVP() {
                this.sendAction("changeRSVP");
            }
        }
    });

});