define('sportly/controllers/profile', ['exports', 'ember', 'sportly/controllers/group'], function (exports, Em, Group) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		me: (function () {
			return this.get("session.user.profile");
		}).property("session.user.profile"),

		name: (function () {
			return this.get("firstName") + " " + this.get("lastName");
		}).property("firstName", "lastName"),

		isMe: (function () {
			return this.get("model.id") === this.get("me.id");
		}).property("model.id", "me.id"),

		isFriend: (function () {
			return this.get("friends").anyBy("id", this.get("session.user.id"));
		}).property("friends.@each", "session.user.id"),

		isFollowingMe: (function () {
			return this.get("allFollowingUsers").anyBy("id", this.get("session.user.id"));
		}).property("allFollowingUsers.@each.id", "session.user.id"),

		iAmFollowing: (function () {
			return this.get("allFollowerUsers").anyBy("id", this.get("session.user.id"));
		}).property("allFollowerUsers.@each.id", "session.user.id"),

		friends: (function () {
			var controller = this;
			return this.get("allFollowingUsers").filter(function (followingUser) {
				return controller.get("allFollowerUsers").findBy("id", followingUser.get("id"));
			});
		}).property("allFollowingUsers.@each.isLoaded", "allFollowerUsers.@each.isLoaded"),

		isFemale: (function () {
			return this.get("gender") === "f";
		}).property("gender"),

		followers: (function () {
			var controller = this;
			return this.get("allFollowerUsers").filter(function (followerUser) {
				return !controller.get("allFollowingUsers").anyBy("id", followerUser.get("id"));
			});
		}).property("allFollowingUsers.@each.isLoaded", "allFollowerUsers.@each.isLoaded"),

		following: (function () {
			var controller = this;
			return this.get("allFollowingUsers").filter(function (followingUser) {
				return !controller.get("allFollowerUsers").anyBy("id", followingUser.get("id"));
			});
		}).property("allFollowingUsers.@each.isLoaded", "allFollowerUsers.@each.isLoaded"),

		allFollowingUsers: (function () {
			return this.get("user.following").mapBy("followed");
		}).property("user.following.@each.followed"),

		allFollowerUsers: (function () {
			return this.get("user.followers").mapBy("user");
		}).property("user.followers.@each.user"),

		relationshipsLoaded: (function () {
			return this.get("model.isLoaded") && this.get("allFollowerUsers").everyBy("isLoaded") && this.get("allFollowingUsers").everyBy("isLoaded") && this.get("following").everyBy("isLoaded") && this.get("followers").everyBy("isLoaded");
		}).property("model.isLoaded", "user.following.@each.isLoaded", "user.followers.@each.isLoaded", "allFollowingUsers.@each.isLoaded", "allFollowerUsers.@each.isLoaded"),

		hasFriends: (function () {
			return this.get("friends.length") > 0;
		}).property("friends.length"),

		hasFollowers: (function () {
			return this.get("followers.length") > 0;
		}).property("followers.length"),

		hasFollowing: (function () {
			return this.get("following.length") > 0;
		}).property("following.length"),

		hasGroups: (function () {
			return this.get("groups.length") > 0;
		}).property("groups.length"),

		groups: Em['default'].computed.mapBy("groupMembers", "group"),
		activeGroups: Em['default'].computed.filter("groups", function (group) {
			return group.get("status") !== Group['default'].STATUS.DELETED;
		}),

		aFewRandomGroups: (function () {
			return this.get("groups").sort(function () {
				return 0.5 - Math.random();
			}).slice(0, 5);
		}).property("groups.@each"),

		games: Em['default'].computed.mapBy("gameMembers", "footballGame"),

		iCanFollow: (function () {
			return !this.get("iAmFollowing") && !this.get("isFriend") && !this.get("isMe");
		}).property("iAmFollowing", "isFriend", "isMe"),

		iCanPost: (function () {
			return this.get("isFollowingMe") || this.get("isFriend") || this.get("isMe");
		}).property("isFollowingMe", "isFriend", "isMe"),

		fixtures: (function () {
			var now = moment().format("X");
			return this.get("games").filter(function (game) {
				if (Em['default'].isEmpty(game) || game.get("isCancelled")) {
					return false;
				}
				return game.get("timestamp") > now;
			}).sort(function (a, b) {
				return a.get("timestamp") - b.get("timestamp");
			});
		}).property("games.@each.timestamp", "games.@each.isCancelled"),

		nextGame: (function () {
			return this.get("fixtures.firstObject");
		}).property("fixtures.firstObject"),

		nextFewFixtures: (function () {
			return this.get("fixtures").slice(0, 3);
		}).property("fixtures.@each"),

		hasFixtures: (function () {
			return this.get("fixtures.length") > 0;
		}).property("fixtures.length"),

		//unreadNotifications: Em.computed.filterBy('notifications', 'unread', 1),
		unreadNotifications: (function () {
			return this.get("notifications").filterBy("unread", 1);
		}).property("notifications.@each.unread"),
		unreadNotificationsCount: Em['default'].computed.alias("unreadNotifications.length"),
		hasUnreadNotifications: Em['default'].computed.notEmpty("unreadNotifications")

	});

});