define('sportly/controllers/football-game/index', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].ObjectController.extend({

        sessionIsMember: Ember['default'].computed.notEmpty("sessionMember"),
        notCancelled: Ember['default'].computed.not("isCancelled"),

        sessionMember: (function () {
            var userId = this.get("session.user.id");
            return this.get("members").find(function (member) {
                return member.get("user.id") === userId;
            });
        }).property("members.@each.user.id", "session.user.id"),

        sessionCanPost: Ember['default'].computed.and("sessionIsMember", "notCancelled")
    });

});