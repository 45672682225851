define('sportly/controllers/football-game/invite-friends', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({

		needs: ["footballGame"],
		invited: [],
		invitedMultiple: (function () {
			return this.get("invited.length") > 1;
		}).property("invited.length"),
		selected: []

	});

});