define('sportly/routes/group/members', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.modelFor("group").get("members");
        },

        afterModel: function afterModel(members) {
            return Ember['default'].RSVP.all(members.mapBy("user")).then(function (users) {
                return Ember['default'].RSVP.all(users.mapBy("profile"));
            });
        },

        actions: {
            didTransition: function didTransition() {
                this.controllerFor("application").set("pageTitle", "Members");
                return true;
            }
        }
    });

});