define('sportly/tests/unit/models/user-test', ['ember-qunit'], function (ember_qunit) {

  'use strict';

  ember_qunit.moduleForModel("user", "User", {
    needs: ["model:profile", "model:app-invite", "model:notification", "model:follower", "model:group-member", "model:game-member", "model:photo", "model:story", "model:group", "model:football-game", "model:group-invite"]
  });

  ember_qunit.test("it exists", function (assert) {
    var model = this.subject();
    // var store = this.store();
    assert.ok(!!model);
  });

});