define('sportly/tests/acceptance/profile/profile-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Profile", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Followers shown on dashboard", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/");

        andThen(function () {
            assert.equal(find(".x-profiles--followers .follower").length, 1, "One follower found");
            assert.equal(find(".x-profiles--followers .follower:first-child .name").text(), "Wayne Rooney", "Follower name is in tooltip");
        });
    });

    qunit.test("Followees shown on dashboard", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/");

        andThen(function () {
            assert.equal(find(".x-profiles--following .followee").length, 1, "One followee found");
            assert.equal(find(".x-profiles--following .followee:first-child .name").text(), "Eden Hazard", "Followee name is in tooltip");
        });
    });

    qunit.test("Friends shown on dashboard", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/");

        andThen(function () {
            assert.equal(find(".x-profiles--friends .friend").length, 2, "Two friends found");
            assert.equal(find(".x-profiles--friends .friend:first-child .name").text(), "Lionel Messi", "First friends name is in tooltip");
        });
    });

    qunit.test("Followers shown on profile page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/zidane");

        andThen(function () {
            assert.equal(find(".x-profiles--followers .follower").length, 1, "One follower found");
            assert.equal(find(".x-profiles--followers .follower:first-child .name").text(), "Wayne Rooney", "Follower name is in tooltip");
        });
    });

    qunit.test("Followees shown on profile page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/");

        andThen(function () {
            assert.equal(find(".x-profiles--following .followee").length, 1, "One followee found");
            assert.equal(find(".x-profiles--following .followee:first-child .name").text(), "Eden Hazard", "Followee name is in tooltip");
        });
    });

    qunit.test("Friends shown on profile page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/");

        andThen(function () {
            assert.equal(find(".x-profiles--friends .friend").length, 2, "Two friends found");
            assert.equal(find(".x-profiles--friends .friend:first-child .name").text(), "Lionel Messi", "First friends name is in tooltip");
        });
    });

    qunit.test("Can follow an unrelated profile", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/johnterry");

        andThen(function () {
            assert.notEqual(find(".btn-follow").length, 0, "Follow button is visible");
            click(".btn-follow");
        });

        andThen(function () {
            assert.equal(find(".btn-follow").length, 0, "Follow button no longer visible");
            assert.equal(find(".is-followee").text(), "Following John", "Following message is shown");
        });
    });

    qunit.test("Can follow a follower profile", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/wayne.rooney");

        andThen(function () {
            assert.notEqual(find(".btn-follow").length, 0, "Follow button is visible");
            click(".btn-follow");
        });

        andThen(function () {
            assert.equal(find(".btn-follow").length, 0, "Follow button no longer visible");
            assert.equal(find(".is-friend").text(), "Friend", "Friend message is shown");
        });
    });

    qunit.test("Cant follow a friend", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/messi");

        andThen(function () {
            assert.equal(find(".btn-follow").length, 0, "Follow button is not shown");
        });
    });

    qunit.test("Cant follow if already following", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/edenhazard");

        andThen(function () {
            assert.equal(find(".btn-follow").length, 0, "Follow button is not shown");
        });
    });

    qunit.test("Follower relationship message shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/wayne.rooney");

        andThen(function () {
            assert.notEqual(find(".summary .is-follower"), 0, "'Is a follower' message shown ");
        });
    });

    qunit.test("Following relationship message shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/edenhazard");

        andThen(function () {
            assert.equal(find(".is-followee").text(), "Following Eden", "Following message is shown");
        });
    });

    qunit.test("Friend relationship message shown", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/messi");

        andThen(function () {
            assert.equal(find(".is-friend").text(), "Friend", "Friend message is shown");
        });
    });

    qunit.test("Upcoming fixtures shown on profile index", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/zidane");

        andThen(function () {
            assert.equal(find(".x-profiles--upcoming-fixtures .x-football-games--fixture-summary").length, 3, "3 upcoming fixtures are shown");
        });
    });

    qunit.test("No fixtures message shown on profile index", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/wayne.rooney");

        andThen(function () {
            assert.notEqual(find(".x-profiles--upcoming-fixtures .no-fixtures").length, 0, "No fixtures message is shown");
        });
    });

    qunit.test("Groups summary shown on profile index", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/zidane");

        andThen(function () {
            assert.equal(find(".x-groups--groups-summary .x-groups--group-summary").length, 5, "5 random games are shown");
        });
    });

});