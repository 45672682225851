define('sportly/tests/acceptance/football-game/member-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Football game - member", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Member shown", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/3/member/8");

        andThen(function () {
            assert.equal(find(".member-header h4").text().trim(), "John Terry", "Player's name is shown");
            assert.equal(find(".member article > nav a:first-child").text().trim(), "John's profile", "Link to player's profile is shown");
        });
    });

    qunit.test("Ban link not shown to member", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/3/member/8");

        andThen(function () {
            assert.equal(find(".member article > nav .ban").length, 0, "Ban link not shown to admin");
        });
    });

    qunit.test("Ban link shown to admin", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/3/member/8");

        andThen(function () {
            assert.notEqual(find(".member article > nav .ban").length, 0, "Ban link shown to admin");
        });
    });

    qunit.test("Member can't ban another member", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/3/member/8/ban");

        andThen(function () {
            assert.equal(currentURL(), "/football/game/3/member/8", "Should be redirect to member route");
        });
    });

    qunit.test("Admin can ban a member", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/3/member/8/ban");

        andThen(function () {
            assert.equal(currentURL(), "/football/game/3/member/8/ban", "Should be allowed to access ban route");

            fillIn("#ban-reason-input", "Too many players");
            click(".btn-ban");
        });

        andThen(function () {
            assert.notEqual(find(".is-banned").length, 0, "'Is banned' message is shown");
            assert.notEqual(find(".btn-unban").length, 0, "Ban button is shown");
            assert.equal(find(".member article > nav .ban").length, 0, "Ban link is not shown");
        });
    });

    qunit.test("Admin cant ban a member if already banned", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/3/member/15");

        andThen(function () {
            assert.notEqual(find(".is-banned").length, 0, "'Is banned' message is shown");
            assert.notEqual(find(".btn-unban").length, 0, "Ban button is shown");
            assert.equal(find(".member article > nav .ban").length, 0, "Ban link is not shown");
            visit("/football/game/3/member/15/ban");
        });

        andThen(function () {
            assert.equal(currentURL(), "/football/game/3/member/15", "Shouldnt be allowed to access ban route");
        });
    });

    qunit.test("Member cant unban a member", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/football/game/3/member/15");

        andThen(function () {
            assert.notEqual(find(".is-banned").length, 0, "'Is banned' message is shown");
            assert.equal(find(".btn-unban").length, 0, "Unban button is not shown");
        });
    });

    qunit.test("Admin can unban a member", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/3/member/15");

        andThen(function () {
            assert.notEqual(find(".btn-unban"), 0, "Unban button is shown");
            click(".btn-unban");
        });

        andThen(function () {
            assert.equal(find(".is-banned").length, 0, "'Is banned' message is not shown");
            assert.equal(find(".btn-unban").length, 0, "Unban button is not shown");
            assert.notEqual(find(".member article > nav .ban").length, 0, "Ban link is shown");
        });
    });

    qunit.test("Admin cant unban a member if not banned", function (assert) {
        assert.expect(1);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/football/game/3/member/8");

        andThen(function () {
            assert.equal(find(".btn-unban").length, 0, "Unban button is not shown");
        });
    });

});