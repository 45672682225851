define('sportly/routes/group/edit/choose-venue', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			var group = this.modelFor("group.edit"),
			    deltaLatitude = 0.01335559098671,
			    deltaLongitude = 0.08308410644532;

			return this.store.find("venue", {
				where: {
					latitude: {
						">": parseFloat(group.get("venue.latitude")) - deltaLatitude,
						"<": parseFloat(group.get("venue.latitude")) + deltaLatitude },
					longitude: {
						">": parseFloat(group.get("venue.longitude")) - deltaLongitude,
						"<": parseFloat(group.get("venue.longitude")) + deltaLongitude
					}
				}
			});
		},

		setupController: function setupController(controller, venues) {

			var mapController = this.controllerFor("map"),
			    group = this.modelFor("group.edit");

			controller.set("model", venues);
			controller.set("markers", []);
			mapController.set("zoom", 13);
			mapController.set("latitude", group.get("venue.latitude"));
			mapController.set("longitude", group.get("venue.longitude"));
			Ember['default'].addListener(mapController, "didLoadMap", this, function () {
				mapController.addMapMoveEndListener(this.mapMoved, this);
				this.mapMoved();
			}, true);
		},

		mapMoved: function mapMoved() {
			var route = this;
			this.store.find("venue", {
				where: {
					latitude: {
						">": this.controllerFor("map").get("latitudeStart"),
						"<": this.controllerFor("map").get("latitudeFinish")
					},
					longitude: {
						">": this.controllerFor("map").get("longitudeStart"),
						"<": this.controllerFor("map").get("longitudeFinish")
					}
				}
			}).then(function (venues) {
				route.set("controller.content", venues);
			});
		},

		actions: {

			chooseVenue: function chooseVenue(venue) {
				this.controllerFor("group.edit").set("venue", venue);
				this.transitionTo("group.edit.index");
			},

			addVenue: function addVenue() {
				this.transitionTo("group.edit.addVenue");
			},

			cancel: function cancel() {
				this.transitionTo("group.edit.index");
			},

			willTransition: function willTransition() {
				this.get("controller").set("markers", []);
				return true;
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Choose venue");
				return true;
			}

		}

	});

});