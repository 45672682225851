define('sportly/controllers/group', ['exports', 'ember', 'sportly/enums/group/member-role', 'sportly/enums/group/status'], function (exports, Em, GroupMemberRole, GroupStatus) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		needs: ["group/member"],

		groupMemberController: Em['default'].computed.alias("controllers.group/member"),

		admins: Em['default'].computed.filterBy("members", "roleId", GroupMemberRole['default'].ADMIN),

		memberUsers: Em['default'].computed.mapBy("members", "user"),
		adminUsers: Em['default'].computed.mapBy("admins", "user"),

		isAdmin: (function () {
			return this.get("adminUsers").any(function (user) {
				return user.get("id") === this.get("session.user.id");
			}, this);
		}).property("adminUsers.@each.id", "session.user.id"),

		isMember: (function () {
			return this.get("memberUsers").any(function (user) {
				return user.get("id") === this.get("session.user.id");
			}, this);
		}).property("memberUsers.@each.id", "session.user.id"),

		isNotAMember: Em['default'].computed.not("isMember"),

		descriptionStr: Em['default'].computed.alias("description"),

		hasNextGame: Em['default'].computed.notEmpty("nextGame"),

		showSetupMode: Em['default'].computed.and("isNoob", "isAdmin"),

		isActive: Em['default'].computed.equal("status", GroupStatus['default'].ACTIVE),
		isNotActive: Em['default'].computed.not("isActive"),
		isDeleted: Em['default'].computed.equal("status", GroupStatus['default'].DELETED),
		isNoob: Em['default'].computed.equal("status", GroupStatus['default'].NOOB),

		myPendingInvites: (function () {
			if (this.get("isMember") || !this.get("session.isAuthenticated")) {
				return [];
			}
			return this.get("pendingInvites").filter(function (invite) {
				return invite.get("status") === 1 && invite.get("receiver.id") === this.get("session.user.id");
			}, this);
		}).property("isMember", "session.isAuthenticated", "session.user.id", "pendingInvites.@each.receiver", "pendingInvites.@each.status"),

		canShowInvites: Em['default'].computed.and("isNotAMember", "isActive", "hasInvites"),
		hasInvites: Em['default'].computed.bool("myPendingInvites.length"),
		hasMultipleInvites: Em['default'].computed.gt("myPendingInvites.length", 1),

		inviteCountMinusOne: (function () {
			return this.get("myPendingInvites.length") - 1;
		}).property("myPendingInvites.length"),

		hasFixtures: Em['default'].computed.bool("fixtures.length"),

		fixtures: (function () {
			var now = moment().format("X");
			if (Em['default'].isEmpty(this.get("games"))) {
				return Em['default'].A();
			}

			return this.get("games").filter(function (game) {
				if (Em['default'].isEmpty(game)) {
					return null;
				}
				if (Em['default'].isEmpty(game.get("timestamp"))) {
					return game;
				}
				return game.get("timestamp") > now;
			});
		}).property("games.@each.timestamp"),

		nextFewFixtures: (function () {
			return this.get("fixtures").slice(0, 3);
		}).property("fixtures.@each"),

		nextGame: (function () {
			var now = moment().format("X");
			return this.get("games").filter(function (game) {
				return game.get("timestamp") > now && !game.get("isCancelled");
			}).sort(function (a, b) {

				return a.get("timestamp") - b.get("timestamp");
			}).get("firstObject");
		}).property("games.@each.timestamp")

	});

});