define('sportly/routes/group/invite-friends', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel(transition) {
			var user = this.get("session.user"),
			    group = this.modelFor("group");

			this._super(transition);

			return user.get("followers").then(function (followers) {
				return Ember['default'].RSVP.all(followers.mapBy("user"));
			}).then(function () {
				return user.get("following");
			}).then(function (following) {
				return Ember['default'].RSVP.all(following.mapBy("followed"));
			}).then(function () {
				return group.get("members");
			}).then(function (members) {
				return Ember['default'].RSVP.all(members.mapBy("user"));
			}).then(function () {
				return group.get("pendingInvites");
			}).then(function (invites) {
				return Ember['default'].RSVP.all(invites.mapBy("receiver"));
			});
		},

		model: function model() {
			var user = this.get("session.user"),
			    followers = user.get("followers").mapBy("user"),
			    following = user.get("following").mapBy("followed"),
			    groupMembers = this.modelFor("group").get("members"),
			    pendingInvites = this.modelFor("group").get("pendingInvites");

			return following.filter(function (followingUser) {
				return followers.findBy("id", followingUser.get("id"));
			}).filter(function (friend) {
				if (groupMembers.findBy("user.id", friend.get("id"))) {
					return false;
				}
				if (pendingInvites.findBy("receiver.id", friend.get("id"))) {
					return false;
				}
				return true;
			});
		},

		afterModel: function afterModel() {
			if (this.modelFor("group").get("status") === GroupStatus['default'].DELETED) {
				return this.transitionTo("group.index");
			}
		},

		setupController: function setupController(controller, friends) {
			controller.set("content", friends);
			controller.set("invited", []);
			if (!this.controllerFor("group").get("isMember")) {
				this.transitionTo("group.index", this.modelFor("group"));
			}
		},

		actions: {

			selectUser: function selectUser(user) {
				this.get("controller.selected").pushObject(user);
			},

			deselectUser: function deselectUser(user) {
				this.get("controller.selected").removeObject(user);
			},

			inviteFriends: function inviteFriends() {
				var _this = this,
				    sender = this.get("session.user"),
				    group = this.modelFor("group"),
				    friends = this.get("controller.selected");

				this.set("controller.invited", []);

				if (friends.get("length")) {

					return Ember['default'].RSVP.all(friends.map(function (user) {
						return _this.store.createRecord("groupInvite", {
							group: group,
							sender: sender,
							receiver: user,
							status: 1
						});
					}).invoke("save")).then(function (groupInvites) {
						return Ember['default'].RSVP.all(groupInvites.map(function (groupInvite) {
							return _this.store.createRecord("notifications/groupInvite", {
								groupInvite: groupInvite
							});
						}).invoke("save"));
					}).then(function () {
						_this.get("controller").removeObjects(friends);
						_this.set("controller.invited", friends);
					});
				}
			},

			skip: function skip() {
				var route = this,
				    group = this.modelFor("group");
				if (this.controllerFor("group").get("isNoob")) {
					group.set("status", 1);
					group.save().then(function (group) {
						route.transitionTo("group.index", group);
					});
				} else {
					this.transitionTo("group.index", group);
				}
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Invite friends");
				return true;
			}
		}
	});

});