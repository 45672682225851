define('sportly/controllers/profile/index', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ObjectController.extend({
		needs: ["profile"],

		games: Ember['default'].computed.mapBy("user.gameMembers", "footballGame"),

		fixtures: (function () {
			var now = moment().format("X");
			return this.get("games").filter(function (game) {
				if (Ember['default'].isEmpty(game) || game.get("isCancelled")) {
					return false;
				}
				return game.get("timestamp") > now;
			}).sort(function (a, b) {
				return a.get("timestamp") - b.get("timestamp");
			}).slice(0, 3);
		}).property("games.@each.timestamp", "games.@each.isCancelled"),

		isSessionProfile: (function () {
			return this.get("session.user.profile.id") === this.get("model.id");
		}).property("session.user.profile.id", "model.id"),

		followedUsers: Ember['default'].computed.mapBy("user.following", "followed"),

		isFollower: (function () {
			return this.get("followedUsers").findBy("id", this.get("session.user.id"));
		}).property("followedUsers.@each.id", "session.user.id"),

		sessionCanPost: Ember['default'].computed.or("isSessionProfile", "isFollower")
	});

});