define('sportly/tests/acceptance/group/invite-friends-by-email-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - invite friends by email", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can't access 'invite by email' if group is deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group/invite-friends-by-email");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".edit-admins article").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Can invite email addresses to group", function (assert) {
        assert.expect(3);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/invite-friends-by-email");

        andThen(function () {
            fillIn("#email-addresses-input", "jack.johnson@sportly.com, john.jackson@sportly.com");
            assert.equal(find(".form-group.preview .email").length, 2, "Two email addresses found");
            click(".invite-friends .actions .btn-submit");
        });

        andThen(function () {
            assert.notEqual(find(".invited-friends.is-showing").length, 0, "'Invited friend' message is showing");
            assert.equal(find("#email-addresses-input").val(), "", "Textarea is now empty");
        });
    });

});