define('sportly/routes/group/create-football-game/choose-venue', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			var footballGame = this.modelFor("group.createFootballGame"),
			    deltaLatitude = 0.01335559098671,
			    deltaLongitude = 0.08308410644532;

			return this.store.find("venue", {
				where: {
					latitude: {
						">": parseFloat(footballGame.get("venue.latitude")) - deltaLatitude,
						"<": parseFloat(footballGame.get("venue.latitude")) + deltaLatitude },
					longitude: {
						">": parseFloat(footballGame.get("venue.longitude")) - deltaLongitude,
						"<": parseFloat(footballGame.get("venue.longitude")) + deltaLongitude
					}
				}
			});
		},

		setupController: function setupController(controller, venues) {

			var mapController = this.controllerFor("map"),
			    footballGame = this.modelFor("group.createFootballGame");

			controller.set("model", venues);
			controller.set("markers", []);
			mapController.set("zoom", 13);
			mapController.set("latitude", footballGame.get("venue.latitude"));
			mapController.set("longitude", footballGame.get("venue.longitude"));
			Ember['default'].addListener(mapController, "didLoadMap", this, function () {
				mapController.addMapMoveEndListener(this.mapMoved, this);
				this.mapMoved();
			}, true);
		},

		mapMoved: function mapMoved() {
			var route = this;
			this.store.find("venue", {
				where: {
					latitude: {
						">": this.controllerFor("map").get("latitudeStart"),
						"<": this.controllerFor("map").get("latitudeFinish")
					},
					longitude: {
						">": this.controllerFor("map").get("longitudeStart"),
						"<": this.controllerFor("map").get("longitudeFinish")
					}
				}
			}).then(function (venues) {
				route.set("controller.content", venues);
			});
		},

		actions: {

			chooseVenue: function chooseVenue(venue) {
				//this.controllerFor('group.create').set('venue', this.controllerFor('infoboxVenue').get('marker.model'));
				this.controllerFor("group.createFootballGame").set("venue", venue);
				this.transitionTo("group.createFootballGame.index");
			},

			addVenue: function addVenue() {
				this.transitionTo("group.createFootballGame.addVenue");
			},

			cancel: function cancel() {
				this.transitionTo("group.createFootballGame.index");
			},

			willTransition: function willTransition() {
				this.get("controller").set("markers", []);
				return true;
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Choose venue");
				return true;
			}
		}

	});

});