define('sportly/components/x-profiles/profile-thumb', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-profiles--profile-thumb"],
        user: null,
        profile: Ember['default'].computed.alias("user.profile"),
        photo: Ember['default'].computed.alias("profile.photo.square"),

        name: (function () {
            return this.get("profile.firstName") + " " + this.get("profile.lastName");
        }).property("profile.firstName", "profile.lastName")

    });

});