define('sportly/models/group-invite', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({

		group: DS['default'].belongsTo("group", { async: true }),
		sender: DS['default'].belongsTo("user", { async: true }),
		receiver: DS['default'].belongsTo("user", { async: true }),
		timestamp: DS['default'].attr("number"),
		status: DS['default'].attr("number")

	});

});