define('sportly/routes/group/create-football-game/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status', 'sportly/enums/football-game/rsvp'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus, RSVP) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		actions: {

			chooseVenue: function chooseVenue() {
				this.transitionTo("group.createFootballGame.chooseVenue");
			},

			chooseDate: function chooseDate() {
				this.transitionTo("group.createFootballGame.chooseDate");
			},

			addFootballGame: function addFootballGame() {

				var controller = this.get("controller"),
				    footballGame = this.get("currentModel"),
				    group = this.modelFor("group"),
				    user = this.get("session.user"),
				    route = this,
				    date = null;

				footballGame.get("errors").clear();

				if (controller.get("isWeekly")) {
					if (Ember['default'].isEmpty(controller.get("startDate")) && Ember['default'].isEmpty(controller.get("finishDate"))) {
						footballGame.get("errors").add("localDate", "Start date and finish date are required");
					} else if (Ember['default'].isEmpty(controller.get("startDate"))) {
						footballGame.get("errors").add("localDate", "Start date is required");
					} else if (Ember['default'].isEmpty(controller.get("finishDate"))) {
						footballGame.get("errors").add("localDate", "Finish date is required");
					}
				} else if (Ember['default'].isEmpty(controller.get("startDate"))) {
					footballGame.get("errors").add("localDate", "Date is required");
				}

				if (Ember['default'].isEmpty(footballGame.get("venue"))) {
					footballGame.get("errors").add("venue", "Venue is required");
				}

				if (Ember['default'].isEmpty(footballGame.get("homeTeam.name"))) {
					footballGame.get("homeTeam.errors").add("name", "Home team name is required");
				}

				if (Ember['default'].isEmpty(footballGame.get("awayTeam.name"))) {
					footballGame.get("awayTeam.errors").add("name", "Away team name is required");
				}

				if (!footballGame.get("isValid") || !footballGame.get("homeTeam.isValid") || !footballGame.get("awayTeam.isValid")) {
					return;
				}

				date = moment(controller.get("startDate"), "DD/MM/YYYY");
				footballGame.set("localDate", date.format("YYYY-MM-DD"));

				this.saveFootballGame(footballGame, user, group).then(function () {
					if (!controller.get("isWeekly")) {
						if (controller.get("isNoob")) {
							route.transitionTo("group.inviteFriends");
						} else {
							route.transitionTo("footballGame.index", footballGame);
						}
					}
				}).then(function () {

					if (controller.get("isWeekly")) {
						var finishDate = moment(controller.get("finishDate"), "DD/MM/YYYY"),
						    footballGames = [];

						date.add(1, "w");

						while (date.isBefore(finishDate)) {

							footballGames.pushObject(route.saveFootballGame(route.store.createRecord("footballGame", {
								homeTeam: route.store.createRecord("footballTeam", {
									name: footballGame.get("homeTeam.name")
								}),
								awayTeam: route.store.createRecord("footballTeam", {
									name: footballGame.get("awayTeam.name")
								}),
								venue: footballGame.get("venue"),
								group: group,
								gameTypeId: footballGame.get("gameTypeId"),
								url: "",
								timestamp: 0,
								localTime: footballGame.get("localTime"),
								localDate: date.format("YYYY-MM-DD"),
								cancellationMessage: "",
								gameLength: footballGame.get("gameLength"),
								playerLimit: footballGame.get("playerLimit"),
								isCancelled: false,
								isFinished: false
							}), user, group));

							date.add(1, "w");
						}

						return Ember['default'].RSVP.all(footballGames).then(function () {
							if (group.get("status") === GroupStatus['default'].NOOB) {
								route.transitionTo("groupInviteFriends", group);
							} else {
								route.transitionTo("group.fixtures", group);
							}
						});
					}
				});
			},

			skip: function skip() {
				var route = this,
				    group = this.modelFor("group");

				group.set("status", 1);
				group.save().then(function (group) {
					route.transitionTo("group.index", group);
				});
			},

			cancel: function cancel() {
				this.transitionTo("group");
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Create game");
				return true;
			}

		},

		saveFootballGame: function saveFootballGame(footballGame, user, group) {

			var route = this;

			footballGame.set("creator", user);

			return Ember['default'].RSVP.all([footballGame.get("homeTeam").save(), footballGame.get("awayTeam").save()]).then(function () {
				return footballGame.save();
			}, function (e) {
				var errors = e.responseJSON.errors;
				for (var attribute in e.responseJSON.errors) {
					if (errors.hasOwnProperty(attribute)) {
						footballGame.get("errors").add(attribute, errors[attribute]);
					}
				}
			}).then(function () {
				return Ember['default'].RSVP.all([user.get("gameMembers"), footballGame.get("members"), group.get("games"), group.get("members")]);
			}).then(function () {
				return group.get("members").mapBy("user");
			}).then(function () {
				var gameMembers = [];

				group.get("games").pushObject(footballGame);

				group.get("members").forEach(function (groupMember) {
					var gameMember = route.store.createRecord("gameMember", {
						user: groupMember.get("user.content"),
						footballGame: footballGame,
						roleId: groupMember.get("roleId"),
						rsvpId: RSVP['default'].PENDING
					});
					footballGame.get("members").pushObject(gameMember);
					if (user.get("id") === groupMember.get("user.id")) {
						user.get("gameMembers").pushObject(gameMember);
					}
					gameMembers.pushObject(gameMember);
				});
				return gameMembers.invoke("save");
			}).then(function () {
				return footballGame;
			});
		}
	});

});