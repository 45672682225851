define('sportly/routes/application', ['exports', 'simple-auth/mixins/application-route-mixin', 'ember'], function (exports, ApplicationRouteMixin, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(ApplicationRouteMixin['default'], {

        model: function model() {
            if (this.get("session.isAuthenticated")) {
                return this.get("session.user");
            }
        },

        afterModel: function afterModel(user, transition) {
            var _this = this;
            if (!Ember['default'].isEmpty(user)) {
                return user.get("profile").then(function (profile) {
                    if (profile.get("onboarding") === 1) {
                        if (transition.targetName.split(".")[0] !== "welcome") {
                            _this.transitionTo("welcome.index");
                        }
                    }
                });
            }
        },

        setupController: function setupController(controller) {
            var route = this;
            this.router.one("didTransition", function () {
                route.router.one("didTransition", function () {
                    controller.set("hasTransitionedAtLeastOnce", true);
                });
            });

            this.router.on("didTransition", function () {
                if (!controller.get("isTransitioningBack")) {
                    window.scrollTo(0, 0);
                } else {
                    controller.set("isTransitioningBack", false);
                }
            });
        },

        actions: {
            toggleSideMenu: function toggleSideMenu() {
                this.toggleProperty("controller.isSideMenuVisible");
            },
            willTransition: function willTransition() {
                this.set("controller.pageTitle", null);
            }
        }

    });

});