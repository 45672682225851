define('sportly/helpers/format-time', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.formatTime = formatTime;

    function formatTime(time, options) {
        if (options.hash.parse) {
            return moment(time, options.hash.parse).format(options.hash.format);
        }
        return moment(time).format(options.hash.format);
    }

    exports['default'] = Ember['default'].Handlebars.makeBoundHelper(formatTime);

});