define('sportly/routes/football-game/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, AuthenticatedRouteMixin, Ember, RSVP) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		setupController: function setupController(controller, footballGame) {
			controller.set("content", footballGame);
			this.controllerFor("footballGame.member").set("model", this.controllerFor("footballGame").get("myMember"));
		},

		actions: {

			didPost: function didPost(body) {
				var _this = this;

				return this.store.createRecord("post/football-game", {
					user: _this.get("session.user"),
					footballGame: _this.get("currentModel"),
					body: body
				}).save().then(function (post) {
					return _this.store.createRecord("story/football-game-post", {
						user: _this.get("session.user"),
						footballGame: _this.get("currentModel"),
						post: post
					}).save();
				}).then(function (story) {
					_this.get("currentModel.stories").pushObject(story);
				});
			},

			unbanMyself: function unbanMyself() {
				this.set("controller.myMember.rsvpId", RSVP['default'].PENDING);
				return this.get("controller.myMember").save();
			}

		},

		deactivate: function deactivate() {
			var controller = this.get("controller");

			if (controller.get("myMember.isDirty")) {
				controller.get("myMember").rollback();
			}
		}

	});

});