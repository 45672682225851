define('sportly/components/x-forms/textfield-inset', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-forms--textfield-inset", "showBigLabel", "isValid", "isError", "isActive", "canClear"],
        value: null,
        inputId: null,
        labelText: null,
        isValid: true,
        isActive: false,
        hasFocus: false,
        focusOnInsert: false,

        isError: Ember['default'].computed.not("isValid"),
        isNotEmpty: Ember['default'].computed.notEmpty("value"),

        canClear: Ember['default'].computed.and("hasFocus", "isNotEmpty"),

        showBigLabel: (function () {
            return Ember['default'].isEmpty(this.get("value"));
        }).property("value"),

        focusOut: function focusOut() {
            this.set("isActive", true);
            this.set("hasFocus", false);
        },

        focusIn: function focusIn() {
            this.set("hasFocus", true);
        },

        didInsertElement: function didInsertElement() {

            if (this.get("focusOnInsert")) {
                this.$("input").focus();
            }
        },

        actions: {
            clear: function clear() {
                this.set("value", null);
            }
        }

    });

});