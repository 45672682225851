define('sportly/models/user', ['exports', 'ember-data'], function (exports, DS) {

    'use strict';

    exports['default'] = DS['default'].Model.extend({
        profile: DS['default'].belongsTo("profile", { async: true }),
        password: DS['default'].attr("string"),
        newPassword: DS['default'].attr("string"),
        email: DS['default'].attr("string"),
        appInvite: DS['default'].belongsTo("app-invite", { inverse: "receiver" }),
        notifications: DS['default'].hasMany("notification", { async: true, polymorphic: true, inverse: "user" }),
        followers: DS['default'].hasMany("follower", { async: true, inverse: "followed" }),
        following: DS['default'].hasMany("follower", { async: true, inverse: "user" }),
        groupMembers: DS['default'].hasMany("group-member", { async: true, inverse: "user" }),
        gameMembers: DS['default'].hasMany("game-member", { async: true, inverse: "user" }),
        groupInvites: DS['default'].hasMany("group-invite", { async: true, inverse: "receiver" }),
        stories: DS['default'].hasMany("story", { async: true, polymorphic: true })
    });

});