define('sportly/routes/welcome', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.get("session.user.profile");
        },

        actions: {

            willTransition: function willTransition(transition) {

                if (transition.targetName.split(".")[0] !== "welcome") {
                    var profile = this.get("currentModel");
                    profile.set("onboarding", false);
                    return profile.save();
                }
            }
        }

    });

});