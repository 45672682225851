define('sportly/routes/help', ['exports', 'ember'], function (exports, Em) {

    'use strict';

    exports['default'] = Em['default'].Route.extend({
        actions: {
            didTransition: function didTransition() {
                this.controllerFor("application").set("pageTitle", "Help");
                return true;
            }
        }
    });

});