define('sportly/routes/find/football-games', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		setupController: function setupController(controller) {
			var route = this;
			Ember['default'].addListener(route.controllerFor("map"), "didLoadMap", route, function () {
				route.controllerFor("map").addMapMoveEndListener(route.mapMoved, route);
				if (route.controllerFor("map").get("zoom") === 100) {
					route.controllerFor("map").get("map").locate();
				} else {
					route.mapMoved();
				}
			}, true);

			controller.set("markers", []);
		},

		mapMoved: function mapMoved() {
			var route = this;
			this.store.find("venue", {
				where: {
					latitude: {
						">": this.controllerFor("map").get("latitudeStart"),
						"<": this.controllerFor("map").get("latitudeFinish")
					},
					longitude: {
						">": this.controllerFor("map").get("longitudeStart"),
						"<": this.controllerFor("map").get("longitudeFinish")
					}
				}
			}).then(function (venues) {
				route.set("controller.content", venues);
				route.replaceWith("find.footballGames.results", {
					latitude: route.controllerFor("map").get("map").getCenter().lat,
					longitude: route.controllerFor("map").get("map").getCenter().lng,
					zoom: route.controllerFor("map").get("map").getZoom()
				});
			});
		},

		deactivate: function deactivate() {
			this.controllerFor("map").set("searchQuery", "");
		}
	});

});