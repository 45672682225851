define('sportly/controllers/group-results', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({
		needs: ["group"],
		sortProperties: ["timestamp"],
		sortAscending: false,
		itemController: "game",

		arrangedContent: (function () {

			if (Em['default'].isEmpty(this.get("content"))) {
				return this.get("content");
			}

			if (this.get("content.isLoaded") === false) {
				return this.get("content");
			}

			var now = moment().format("X");
			return this.get("content").filter(function (game) {
				return game.get("timestamp") < now;
			});
		}).property("content.@each.timestamp", "content.isLoaded")

	});

});