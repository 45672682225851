define('sportly/models/post', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		user: DS['default'].belongsTo("user", { async: true }),
		body: DS['default'].attr("string"),
		published: DS['default'].attr("number")
	});

});