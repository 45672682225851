define('sportly/components/x-football-games/settings', ['exports', 'ember', 'sportly/enums/football-game/member-role'], function (exports, Ember, GameMemberRole) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-football-games--settings"],
        footballGame: null,

        notCancelled: Ember['default'].computed.not("footballGame.isCancelled"),
        notFinished: Ember['default'].computed.not("footballGame.isFinished"),
        isActive: Ember['default'].computed.and("notCancelled", "notFinished"),
        showSettings: Ember['default'].computed.and("isActive", "sessionIsAdmin"),

        sessionIsAdmin: (function () {
            var userId = this.get("session.user.id");
            return this.get("footballGame.members").filterBy("roleId", GameMemberRole['default'].ADMIN).any(function (member) {
                return member.get("user.id") === userId;
            });
        }).property("footballGame.members.@each.roleId", "footballGame.members.@each.user.id", "session.user.id") });

});