define('sportly/controllers/football-game/create/choose-venue', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({

		needs: ["map", "infobox/venue"],

		map: Em['default'].computed.alias("controllers.map"),
		venueInfobox: Em['default'].computed.alias("controllers.infobox/venue"),
		marker: Em['default'].computed.alias("venueInfobox.marker"),

		markers: [],

		updateMarkers: (function () {
			var markerClusterGroup = this.get("map.markerClusterGroup"),
			    markers = this.get("markers"),
			    removedMarkers = [],
			    addedMarkers = [],
			    marker = null,
			    controller = this,
			    selectedVenue = null,
			    selectedMarker = null;

			if (this.get("content.isLoaded") === false || Em['default'].isEmpty(markerClusterGroup)) {
				return;
			}

			if (!Em['default'].isEmpty(this.get("marker"))) {
				selectedVenue = this.get("marker.model");
			}

			//First pass - remove expired markers
			markers.forEach(function (marker) {
				if (!controller.isMarkerInVenues(marker)) {
					removedMarkers.pushObject(marker);
				}
			});
			markerClusterGroup.removeLayers(removedMarkers);
			markers.removeObjects(removedMarkers);

			//Second pass - add new markers
			this.get("content").forEach(function (venue) {

				marker = controller.getMarkerByVenue(venue);
				if (!marker) {
					marker = L.marker(new L.LatLng(venue.get("latitude"), venue.get("longitude")), {
						icon: L.mapbox.marker.icon({
							"marker-size": "large",
							"marker-color": "009FDA",
							"marker-symbol": "marker"
						}),
						title: venue.get("name")
					});

					marker.model = venue;
					marker.on("popupopen", function () {
						controller.popupOpened(this);
					});
					marker.on("popupclose", function () {
						controller.popupClosed(this);
					});
					marker.bindPopup();
					addedMarkers.pushObject(marker);
				}

				//Swap out selected marker
				if (selectedVenue === venue) {
					selectedMarker = marker;
				}
			});

			markers.pushObjects(addedMarkers);
			markerClusterGroup.addLayers(addedMarkers);

			if (!Em['default'].isEmpty(selectedMarker) && this.get("marker") !== selectedMarker) {
				selectedMarker.openPopup();
			}
		}).observes("content.isLoaded"),

		getMarkerByVenue: function getMarkerByVenue(venue) {
			return this.get("markers").find(function (marker) {
				return marker.model === venue;
			});
		},

		isMarkerInVenues: function isMarkerInVenues(marker) {
			return this.get("content").find(function (venue) {
				return marker.model === venue;
			});
		},

		popupOpened: function popupOpened(marker) {
			this.set("marker", marker);
		},

		popupClosed: function popupClosed() {
			this.set("marker", null);
		}

	});

});