define('sportly/routes/find/groups/index', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
        actions: {
            didTransition: function didTransition() {
                this.controllerFor("application").set("pageTitle", "Find groups");
                return true;
            }
        }
    });

});