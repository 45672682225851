define('sportly/components/x-guest/sign-in-form', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ["x-guest--sign-in-form"],
        errors: null,

        actions: {
            authenticate: function authenticate() {
                this.sendAction("authenticate");
            }
        }
    });

});