define('sportly/controllers/account-settings/general', ['exports', 'ember'], function (exports, Em) {

    'use strict';

    exports['default'] = Em['default'].ObjectController.extend({
        isMale: (function () {
            return this.get("gender") === "m";
        }).property("gender"),

        dobMonthPretty: (function () {
            return moment(this.get("dobYear") + "-" + this.get("dobMonth")).format("MMMM");
        }).property("dobMonth", "dobYear")
    });

});