define('sportly/controllers/quick-search/results', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({

		needs: ["quick-search/form"],

		quickSearchForm: Em['default'].computed.alias("controllers.quick-search/form"),

		isSearching: false,
		isVisible: Em['default'].computed.alias("quickSearchForm.isVisible"),
		hasResults: Em['default'].computed.gt("model.length", 0),

		model: (function () {
			var controller = this,
			    results = null;
			this.set("isSearching", true);
			results = this.store.find("profile", {
				query: this.get("quickSearchForm.query")
			});
			results.then(function () {
				controller.set("isSearching", false);
			});
			return results;
		}).property("quickSearchForm.query")
	});

});