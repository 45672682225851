define('sportly/controllers/find/football-games/results', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ArrayController.extend({

		needs: ["infobox/venue-football-games"],

		venueFootballGamesInfobox: Em['default'].computed.alias("controllers.infobox/venue-football-games"),
		marker: Em['default'].computed.alias("venueFootballGamesInfobox.marker"),

		venue: null,

		footballGames: (function () {
			var marker = this.get("marker");

			if (marker == null) {
				return this.get("content");
			}

			return this.get("content").filterBy("venue", marker.model);
		}).property("content.@each", "marker")

	});

});