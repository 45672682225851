define('sportly/models/profile', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		user: DS['default'].belongsTo("user", { async: true }),
		firstName: DS['default'].attr("string"),
		lastName: DS['default'].attr("string"),
		url: DS['default'].attr("string"),
		photo: DS['default'].belongsTo("photo", { async: true }),
		gender: DS['default'].attr("string"),
		dobDay: DS['default'].attr("number"),
		dobMonth: DS['default'].attr("number"),
		dobYear: DS['default'].attr("number"),
		location: DS['default'].attr("string"),
		latitude: DS['default'].attr("string"),
		longitude: DS['default'].attr("string"),
		about: DS['default'].attr("string"),
		onboarding: DS['default'].attr("number"),
		stories: DS['default'].hasMany("story", { async: true, polymorphic: true })
	});

});