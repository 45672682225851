define('sportly/enums/group/status', ['exports'], function (exports) {

    'use strict';

    exports['default'] = Object({
        ACTIVE: 1,
        DELETED: 2,
        NOOB: 3
    });

});