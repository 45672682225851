define('sportly/tests/acceptance/group/delete-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Group - delete", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Can't access 'delete group' if group is deleted", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/deleted-group/delete");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index");
            assert.equal(find(".edit-admins article").length, 0, "Edit admins container is not shown");
        });
    });

    qunit.test("Member can't access 'delete group' page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 2);
        visit("/group/test-group-1/delete");

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be redirected to group index page");
            assert.equal(find(".delete-group article").length, 0, "Delete group container is not shown");
        });
    });

    qunit.test("Admin can access 'delete group' page", function (assert) {
        assert.expect(2);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/delete");

        andThen(function () {
            assert.equal(currentPath(), "group.delete", "Should be on delete group page");
            assert.notEqual(find(".delete-group").length, 0, "Delete group container is shown");
        });
    });

    qunit.test("Admin can delete group with no fixtures", function (assert) {
        assert.expect(4);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-1/delete");

        andThen(function () {
            assert.notEqual(find(".page .delete-group").length, 0, "Delete group container is shown");
            click(".delete-group .actions .btn-delete");
        });

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be on the group index page");
            assert.notEqual(find(".x-groups--header .is-deleted").length, 0, "'Is deleted' label is shown on header");
            assert.equal(find(".x-groups--header .settings").length, 0, "Group menu settings option is not shown");
        });
    });

    qunit.test("Admin can delete group with fixtures", function (assert) {
        assert.expect(5);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 1);
        visit("/group/test-group-with-fixtures/delete");

        andThen(function () {
            assert.notEqual(find(".page .delete-group").length, 0, "Delete group container is shown");
            click(".delete-group .actions .btn-delete");
        });

        andThen(function () {
            assert.equal(currentPath(), "group.index", "Should be on the group index page");
            assert.notEqual(find(".x-groups--header .is-deleted").length, 0, "'Is deleted' label is shown on header");
            assert.equal(find(".x-groups--header .settings").length, 0, "Group menu settings option is not shown");
            visit("/football/game/6");
        });

        andThen(function () {
            assert.notEqual(find(".football-game .is-cancelled-alert").length, 0, "'Is cancelled' alert shown on football game");
        });
    });

});