define('sportly/routes/group/edit', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, AuthenticatedRouteMixin, Ember, GroupStatus) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		model: function model() {
			return this.modelFor("group");
		},

		setupController: function setupController(controller, group) {
			controller.set("model", group);
			if (!this.controllerFor("group").get("isAdmin")) {
				this.transitionTo("group.index");
			}
		},

		afterModel: function afterModel() {
			if (this.modelFor("group").get("status") !== GroupStatus['default'].ACTIVE) {
				return this.transitionTo("group.index");
			}
		},

		deactivate: function deactivate() {
			if (this.get("currentModel").get("isDirty")) {
				this.get("currentModel").rollback();
			}
		}
	});

});