define('sportly/tests/unit/helpers/str-truncate-test', ['sportly/helpers/str-truncate', 'qunit'], function (str_truncate, qunit) {

  'use strict';

  qunit.module("StrTruncateHelper");

  // Replace this with your real tests.
  qunit.test("it works", function (assert) {
    //var result = strTruncate(42);
    //ok(result);
    assert.expect(0);
  });

});