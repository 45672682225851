define('sportly/tests/unit/components/x-groups/upcoming-fixtures-test', ['ember-qunit', 'ember'], function (ember_qunit, Ember) {

    'use strict';

    ember_qunit.moduleForComponent("x-groups/upcoming-fixtures", {
        needs: ["component:x-groups/upcoming-fixtures"]
    });

    ember_qunit.test("it renders", function (assert) {
        assert.expect(2);

        // creates the component instance
        var component = this.subject();
        assert.equal(component._state, "preRender");

        component.set("group", Ember['default'].Object.create({
            id: 1,
            name: "Test group",
            games: [Ember['default'].Object.create({
                id: 1,
                isCancelled: false,
                timestamp: 0
            })],
            members: [Ember['default'].Object.create({
                id: 1,
                roleId: 1,
                user: Ember['default'].Object.create({
                    id: 1
                })
            })]
        }));

        // appends the component to the page
        this.render();
        assert.equal(component._state, "inDOM");
    });

});