define('sportly/routes/account-settings/general', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
		model: function model() {
			return this.get("session.user.profile");
		},

		actions: {
			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "General settings");
				return true;
			}
		}
	});

});