define('sportly/tests/unit/components/x-groups/groups-summary-test', ['ember-qunit', 'ember'], function (ember_qunit, Ember) {

    'use strict';

    ember_qunit.moduleForComponent("x-groups/groups-summary", {
        needs: ["component:x-groups/group-summary"]
    });

    ember_qunit.test("it renders", function (assert) {
        assert.expect(2);

        // creates the component instance
        var component = this.subject();
        assert.equal(component._state, "preRender");

        component.set("user", {
            id: 1,
            groupMembers: [Ember['default'].Object.create({
                id: 1,
                user: Ember['default'].Object.create({
                    id: 1
                }),
                group: Ember['default'].Object.create({
                    id: 1
                })
            })]
        });

        // appends the component to the page
        this.render();
        assert.equal(component._state, "inDOM");
    });

});