define('sportly/tests/unit/components/x-welcome/profile-form-test', ['ember-qunit', 'ember'], function (ember_qunit, Ember) {

  'use strict';

  ember_qunit.moduleForComponent("x-welcome/profile-form", {
    needs: ["component:x-utils/uploader"],
    setup: function setup() {
      this.container.register("view:select", Ember['default'].Select);
    }
  });

  ember_qunit.test("it renders", function (assert) {
    assert.expect(2);

    // creates the component instance
    var component = this.subject();
    assert.equal(component._state, "preRender");

    // appends the component to the page
    this.render();
    assert.equal(component._state, "inDOM");
  });

});