define('sportly/controllers/map', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		map: null,
		searchQuery: "",
		geocoder: null,
		latitude: 0,
		longitude: 0,
		zoom: 100,
		markerClusterGroup: null,
		locatingMe: false,

		addMapMoveEndListener: function addMapMoveEndListener(callback, context) {
			this.get("map").addEventListener("moveend", callback, context);
		},

		mapLoaded: function mapLoaded(map, markerClusterGroup, geocoder) {
			this.set("map", map);
			this.set("markerClusterGroup", markerClusterGroup);
			this.set("geocoder", geocoder);
			this.get("map").addEventListener("moveend", this.mapMoved, this);
			this.get("map").addEventListener("locationfound", function () {
				this.set("locatingMe", false);
			}, this);
			Em['default'].sendEvent(this, "didLoadMap");
		},

		mapMoved: function mapMoved() {
			this.setProperties({
				latitude: this.get("map").getCenter().lat,
				longitude: this.get("map").getCenter().lng,
				zoom: this.get("map").getZoom()
			});
		},

		latitudeStart: (function () {
			return this.get("map").getBounds().getSouthWest().lat;
		}).property("map", "latitude", "zoom"),

		latitudeFinish: (function () {
			return this.get("map").getBounds().getNorthEast().lat;
		}).property("map", "latitude", "zoom"),

		longitudeStart: (function () {
			return this.get("map").getBounds().getSouthWest().lng;
		}).property("map", "longitude", "zoom"),

		longitudeFinish: (function () {
			return this.get("map").getBounds().getNorthEast().lng;
		}).property("map", "longitude", "zoom"),

		setCenter: function setCenter(latitude, longitude, zoom) {
			this.get("map").setView([latitude, longitude], zoom);
		},

		actions: {
			myLocation: function myLocation() {
				this.set("locatingMe", true);
				this.get("map").locate();
			},

			searchMap: function searchMap() {
				var controller = this;

				if (Em['default'].isEmpty(this.get("searchQuery"))) {
					return;
				}

				this.get("geocoder").query(this.get("searchQuery"), function (err, data) {
					if (data.lbounds) {
						controller.get("map").fitBounds(data.lbounds);
					} else if (data.latlng) {
						controller.get("map").setView([data.latlng[0], data.latlng[1]], 13);
					}
				});

				/*this.get('geocoder').geocode( { 'address': this.get('searchQuery'), 'region': 'uk'}, function(results, status) {
	   	if (status === window.google.maps.GeocoderStatus.OK) {
	   		controller.setCenter(results[0].geometry.location.lat(), results[0].geometry.location.lng(), 14);
	   	}
	   });
	   */
			}
		}

	});

});