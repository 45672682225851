define('sportly/mixin/tracking_mixin', ['exports', 'sportly/config/environment'], function (exports, config) {

    'use strict';

    // copied from https://raw.githubusercontent.com/rahim/ember-segmentio/master/lib/tracking_mixin.js
    // thanks to https://github.com/rahim
    var MixpanelMixin = Ember.Mixin.create({
        pageHasAnalytics: function pageHasAnalytics() {
            return window.mixpanel && typeof window.mixpanel === "object" && config['default'].mixpanel.enabled;
        },

        logTrackingEnabled: function logTrackingEnabled() {
            ;
            return !!config['default'] && !!config['default'].mixpanel.LOG_EVENT_TRACKING;
        },

        logTracking: function logTracking() {
            Ember.Logger.info("[Mixpanel] ", arguments);
        },

        trackPageView: function trackPageView(page) {
            if (this.pageHasAnalytics()) {
                if (!page) {
                    var loc = window.location;
                    page = loc.hash ? loc.hash.substring(1) : loc.pathname + loc.search;
                }

                mixpanel.track("visit", { pageName: page });
            }

            if (this.logTrackingEnabled()) {
                this.logTracking("page view", page);
            }
        },

        trackEvent: function trackEvent(event, properties, options, callback) {
            if (this.pageHasAnalytics()) {
                mixpanel.track(event, properties, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking(event, properties, options);
            }
        },

        identifyUser: function identifyUser(userId, traits, options, callback) {
            if (this.pageHasAnalytics()) {
                mixpanel.identify(userId, traits, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking("identify user", traits, options);
            }
        },

        aliasUser: function aliasUser(userId, previousId, options, callback) {
            if (this.pageHasAnalytics()) {
                mixpanel.alias(userId, previousId, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking("alias user", previousId, options);
            }
        }
    });

    exports['default'] = MixpanelMixin;

});