define('sportly/routes/football-game/edit/choose-venue', ['exports', 'simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, AuthenticatedRouteMixin, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {

		defaultLatitude: 0,
		defaultLongitude: 0,

		model: function model() {
			var profile = this.get("session.user.profile"),
			    footballGame = this.modelFor("footballGame.edit"),
			    deltaLatitude = 0.01335559098671,
			    deltaLongitude = 0.08308410644532;

			this.set("defaultLatitude", parseFloat(profile.get("latitude")));
			this.set("defaultLongitude", parseFloat(profile.get("longitude")));

			if (footballGame.get("venue") !== null) {
				this.set("defaultLatitude", parseFloat(footballGame.get("venue.latitude")));
				this.set("defaultLongitude", parseFloat(footballGame.get("venue.longitude")));
			}

			return this.store.find("venue", {
				where: {
					latitude: {
						">": this.get("defaultLatitude") - deltaLatitude,
						"<": this.get("defaultLatitude") + deltaLatitude },
					longitude: {
						">": this.get("defaultLongitude") - deltaLongitude,
						"<": this.get("defaultLongitude") + deltaLongitude
					}
				}
			});
		},

		setupController: function setupController(controller, venues) {

			var mapController = this.controllerFor("map");

			controller.set("model", venues);
			controller.set("markers", []);
			mapController.set("zoom", 13);
			mapController.set("latitude", this.get("defaultLatitude"));
			mapController.set("longitude", this.get("defaultLongitude"));
			Ember['default'].addListener(mapController, "didLoadMap", this, function () {
				mapController.addMapMoveEndListener(this.mapMoved, this);
				this.mapMoved();
			}, true);
		},

		mapMoved: function mapMoved() {
			var route = this;
			this.store.find("venue", {
				where: {
					latitude: {
						">": this.controllerFor("map").get("latitudeStart"),
						"<": this.controllerFor("map").get("latitudeFinish")
					},
					longitude: {
						">": this.controllerFor("map").get("longitudeStart"),
						"<": this.controllerFor("map").get("longitudeFinish")
					}
				}
			}).then(function (venues) {
				route.set("controller.content", venues);
			});
		},

		actions: {

			chooseVenue: function chooseVenue(venue) {
				this.controllerFor("footballGame.edit").set("venue", venue);
				this.transitionTo("footballGame.edit");
			},

			addVenue: function addVenue() {
				this.transitionTo("footballGame.edit.addVenue");
			},

			cancel: function cancel() {
				this.transitionTo("footballGame.edit");
			},

			willTransition: function willTransition() {
				this.get("controller").set("markers", []);
				return true;
			},

			didTransition: function didTransition() {
				this.controllerFor("application").set("pageTitle", "Choose venue");
				return true;
			}

		}

	});

});