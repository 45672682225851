define('sportly/controllers/profile/fixtures', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({

		needs: ["profile"],

		sortProperties: ["timestamp"],
		sortAscending: true,
		itemController: "game",

		profile: Ember['default'].computed.alias("controllers.profile.model"),

		hasFixtures: Ember['default'].computed.notEmpty("content")

	});

});