define('sportly/tests/acceptance/notifications-test', ['ember', 'qunit', 'sportly/tests/helpers/start-app', 'sportly/adapters/application'], function (Ember, qunit, startApp, applicationAdapter) {

    'use strict';

    var application;

    qunit.module("Acceptance: Notifications", {
        beforeEach: function beforeEach() {
            applicationAdapter['default'].reopen({
                host: "/testApi",
                namespace: ""
            });
            application = startApp['default']();
        },
        afterEach: function afterEach() {
            Ember['default'].run(application, "destroy");
        }
    });

    qunit.test("Group invite notification is shown", function (assert) {
        assert.expect(5);

        resetStore();
        authenticateSession();
        currentSession().set("userId", 6);
        visit("/notifications");

        andThen(function () {
            assert.equal(find(".x-notification").length, 1, "One notification is shown");
            assert.equal(find(".x-notification.is-unread").length, 1, "Notification is unread");
            assert.equal(find(".x-notifications-group-invite").length, 1, "One group invite notification is shown");
            assert.equal(find(".x-notifications-group-invite .sender-name").text(), "Zinedine Zidane", "Invitation sender name is shown");
            assert.equal(find(".x-notifications-group-invite .group-name").text(), "Lion Weekly Kickabout", "Invitation group name is shown");
        });
    });

    qunit.test("Football game invite notification is shown", function (assert) {
        assert.expect(6);
        resetStore();
        authenticateSession();
        currentSession().set("userId", 7);
        visit("/notifications");

        andThen(function () {
            assert.equal(find(".x-notification").length, 1, "One notification is shown");
            assert.equal(find(".x-notification.is-unread").length, 1, "Notification is unread");
            assert.equal(find(".x-notifications-football-game-invite").length, 1, "One football game invite notification is shown");
            assert.equal(find(".x-notifications-football-game-invite .sender-name").text().trim(), "Zinedine Zidane", "Invitation sender name is shown");
            assert.equal(find(".x-notifications-football-game-invite .time").text().trim(), "18:00", "Invitation game time is shown");
            assert.equal(find(".x-notifications-football-game-invite .date").text().trim(), "Monday, 3rd October", "Invitation game date is shown");
        });
    });

    qunit.test("Notification count shown on global nav", function (assert) {
        assert.expect(2);
        resetStore();
        authenticateSession();
        currentSession().set("userId", 7);
        visit("/dashboard");

        andThen(function () {
            assert.equal(find(".x-global--nav .notifications .notification-count:visible").length, 1, "Notification count is shown");
            assert.equal(find(".x-global--nav .notifications .notification-count").text(), "1", "Notification count should be 1");
        });
    });

    qunit.test("No notification count shown on global nav", function (assert) {
        assert.expect(2);
        resetStore();
        authenticateSession();
        currentSession().set("userId", 8);
        visit("/dashboard");

        andThen(function () {
            assert.equal(find(".x-global--nav .notifications .notification-count:visible").length, 0, "Notification count is not shown");
            assert.equal(find(".x-global--nav .notifications .notification-count").text(), "0", "Notification count should be 0");
        });
    });

});