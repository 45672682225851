define('sportly/controllers/quick-search/form', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		needs: ["globalMenu"],

		query: "",
		isVisible: false,

		actions: {
			showQuickSearch: function showQuickSearch() {
				this.set("isVisible", true);
				this.get("controllers.globalMenu").send("hide");
				Em['default'].run.scheduleOnce("afterRender", this, function () {
					Em['default'].$(".quick-search-form input").focus();
				});
			},

			hideQuickSearch: function hideQuickSearch() {
				this.set("isVisible", false);
				this.set("query", "");
			},

			clear: function clear() {
				this.set("query", "");
				Em['default'].$(".quick-search-form input").focus();
			}
		}
	});

});