define('sportly/tests/unit/components/x-global/nav-test', ['ember-qunit', 'ember'], function (ember_qunit, Ember) {

    'use strict';

    ember_qunit.moduleForComponent("x-global/nav", {
        needs: ["component:x-dropdown-menu", "helper:profile-name"]
    });

    ember_qunit.test("it renders", function (assert) {
        assert.expect(2);

        // creates the component instance
        var component = this.subject();
        assert.equal(component._state, "preRender");

        component.set("user", {
            profile: Ember['default'].Object.create({
                id: 1,
                firstName: "Zinedine",
                lastName: "Zidane",
                notifications: []
            }),
            followers: [],
            following: []

        });

        // appends the component to the page
        this.render();
        assert.equal(component._state, "inDOM");
    });

});