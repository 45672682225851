define('sportly/controllers/infobox/infobox', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({

		marker: null,

		noneSelected: (function () {
			return Em['default'].isEmpty(this.get("marker"));
		}).property("marker")

	});

});