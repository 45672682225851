define('sportly/controllers/profile/friends', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ArrayController.extend({

		needs: ["profile"],
		profile: Ember['default'].computed.alias("controllers.profile.model")

	});

});