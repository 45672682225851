define('sportly/controllers/group/about', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ObjectController.extend({

		descriptionStr: Ember['default'].computed.alias("description"),

		map: (function () {
			return "http://api.tiles.mapbox.com/v3/sportly.map-v8jdk228/pin-l+009FDA(" + this.get("venue.longitude") + "," + this.get("venue.latitude") + ")/" + this.get("venue.longitude") + "," + this.get("venue.latitude") + ",15/266x140.png";
		}).property("venue.latitude", "venue.longitude")

	});

});