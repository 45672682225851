define('sportly/controllers/guest', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        navIsHidden: false,
        pageTitle: "",
        isLoading: false
    });

});