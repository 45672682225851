define('sportly/models/photo', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		square: DS['default'].attr("string"),
		squareMedium: DS['default'].attr("string"),
		large: DS['default'].attr("string"),
		veryLarge: DS['default'].attr("string"),
		filename: DS['default'].attr("string")
	});

});