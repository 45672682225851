define('sportly/controllers/group/invite', ['exports', 'ember'], function (exports, Em) {

	'use strict';

	exports['default'] = Em['default'].ObjectController.extend({
		needs: ["group"],
		group: Em['default'].computed.alias("controllers.group.model"),
		primarySender: Em['default'].computed.alias("controllers.group.myPendingInvites.lastObject.sender"),
		hasMultipleInvites: Em['default'].computed.gt("controllers.group.myPendingInvites.length", 1),
		othersCount: (function () {
			return this.get("controllers.group.myPendingInvites.length" - 1);
		}).property("controllers.group.myPendingInvites.length")
	});

});