define('sportly/components/x-profiles/following', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNameBindings: [":x-profiles--following", "hasFollowees"],
        user: null,
        hasFollowees: Ember['default'].computed.notEmpty("followingUsers"),

        followingUsers: (function () {
            var followerUsers = this.get("user.followers").mapBy("user"),
                followingUsers = this.get("user.following").mapBy("followed");
            return followingUsers.filter(function (followingUser) {
                return !followerUsers.anyBy("id", followingUser.get("id"));
            });
        }).property("user.followers.@each.user", "user.following.@each.followed")

    });

});